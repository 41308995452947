import React, { useCallback } from 'react';
import { Platform, Share, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
	connectActionSheet,
	useActionSheet,
} from '@expo/react-native-action-sheet';
import i18n from 'i18n-js';
import { BaseModalContainer, StatusBar, useMessage } from '@newstart/ui';
import { useAuth, User } from '@newstart/auth';

import { TeamStackParamList } from '../../navigation';
import {
	StyledLinkLabel,
	StyledUserListContainer,
	StyledShareButton,
	StyledShareIcon,
} from './styles';
import { MemberItem } from './MemberItem';

type MembersModalProps = NativeStackScreenProps<
	TeamStackParamList,
	'Team_Members_Modal'
>;

export const MembersModal: React.FunctionComponent<MembersModalProps> =
	connectActionSheet(({ navigation }) => {
		const { team, user, changeTeamOwner, removeUserFromTeam } = useAuth();
		if (!team || !user) return null;

		const { showError, showSuccess } = useMessage();
		const { showActionSheetWithOptions } = useActionSheet();
		const isOwner = team.ownerId === user.id;

		const handleActionSheet = useCallback(
			(selectedUser: User) => {
				if (!isOwner) {
					return false;
				}

				const options = [
					i18n.t('remove-from-x', { team: team.name }), // 0
					i18n.t('make-team-owner'), // 1
					i18n.t('cancel'), // 2
				];
				const destructiveButtonIndex = 0;
				const cancelButtonIndex = 2;

				showActionSheetWithOptions(
					{
						options,
						cancelButtonIndex,
						destructiveButtonIndex,
					},
					(buttonIndex) => {
						if (buttonIndex === 1) {
							makeTeamOwner(selectedUser);
						} else if (buttonIndex === 0) {
							removeFromTeam(selectedUser);
						}
					}
				);
			},
			[isOwner, showActionSheetWithOptions]
		);

		const makeTeamOwner = useCallback((newOwner: User) => {
			let options = [
				i18n.t('confirm'), // 0
				i18n.t('cancel'), // 1
			];
			let destructiveButtonIndex = 0;
			let cancelButtonIndex = 1;

			showActionSheetWithOptions(
				{
					options,
					cancelButtonIndex,
					destructiveButtonIndex,
					title: i18n.t('transfer-ownership-title', {
						user: newOwner.username,
					}),
					message: i18n.t('transfer-ownership-message'),
				},
				async (buttonIndex) => {
					if (buttonIndex === 0) {
						// Confirm
						try {
							await changeTeamOwner(team.id, newOwner.id);
							navigation.goBack();
							showSuccess(
								i18n.t('transfer-ownership-success', {
									name: newOwner.username,
								})
							);
						} catch (error) {
							showError(error);
						}
					}
				}
			);
		}, []);

		const removeFromTeam = useCallback((selectedUser: User) => {
			let options = [
				i18n.t('confirm'), // 0
				i18n.t('cancel'), // 1
			];
			let destructiveButtonIndex = 0;
			let cancelButtonIndex = 1;

			showActionSheetWithOptions(
				{
					options,
					cancelButtonIndex,
					destructiveButtonIndex,
					title: i18n.t('remove-user-from-team', {
						user: selectedUser.username,
					}),
					message: `${i18n.t('are-you-sure')} ${i18n.t(
						'this-action-cannot-be-undone'
					)}`,
				},
				async (buttonIndex) => {
					if (buttonIndex === 0) {
						// Confirm
						try {
							await removeUserFromTeam(team.id, selectedUser.id);
							showSuccess(
								i18n.t('member-successed-removed', {
									name: selectedUser.username,
								})
							);
						} catch (error) {
							showError(error);
						} finally {
							navigation.goBack();
						}
					}
				}
			);
		}, []);

		const handleSharing = useCallback(async () => {
			try {
				await Share.share({
					message: i18n.t('invitate-message', { code: team.inviteCode }),
				});
			} catch (error) {
				showError(error);
			}
		}, [team.inviteCode, showError]);

		return (
			<BaseModalContainer
				backgroundColor={['#4E3980', '#2A1165']}
				onClose={() => navigation.goBack()}
				title={i18n.t('members')}
				dark
			>
				<StatusBar style="auto" />
				<StyledUserListContainer>
					{team.members.map((member, i) => (
						<MemberItem
							key={i}
							member={member as User}
							onSelectMember={handleActionSheet}
							isMemberOwner={member.id === team.ownerId}
							isAuthenticatedUserOwner={isOwner}
							isAuthenticatedUser={member.id === user.id}
						/>
					))}

					{team.members.length < 7 && Platform.OS !== 'web' ? (
						<StyledShareButton onPress={handleSharing}>
							<StyledLinkLabel>
								<StyledShareIcon /> {i18n.t('invite-my-friends')}
							</StyledLinkLabel>
						</StyledShareButton>
					) : (
						<View
							style={{
								padding: 18,
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<StyledLinkLabel>
								{i18n.t('invite-my-friends')}: {team.inviteCode}
							</StyledLinkLabel>
						</View>
					)}
				</StyledUserListContainer>
			</BaseModalContainer>
		);
	});
