import * as React from "react"
import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { AuthNavigator, useAuth } from "@newstart/auth"
import { OnboardingNavigator } from "@newstart/onboarding"

import { RootStackParamList } from "./types"
import { AppTabsNavigator } from "./AppTabsNavigator"
import { Screen } from "../Screen"
import { ActivityIndicator, View } from "react-native"
import { ui } from "@newstart/ui"

const Stack = createNativeStackNavigator<RootStackParamList>()

export const RootStackNavigator = () => {
  const { signed, joined, loading } = useAuth()

  if (loading) {
    return <LoadScreen />
  }
  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {signed ? (
          joined ? (
            <Stack.Screen name="App" component={AppTabsNavigator} />
          ) : (
            <Stack.Screen name="Onboarding" component={OnboardingNavigator} />
          )
        ) : (
          <Stack.Screen name="Auth" component={AuthNavigator} />
        )}
        <Stack.Screen name="NotFound" component={LoadScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

const LoadScreen = () => (
  <View
    style={{
      backgroundColor: ui.colors.light,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <ActivityIndicator color={ui.colors.dark} />
  </View>
)
