import styled from 'styled-components/native';
import { MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import { ui } from '@newstart/ui';

const PADDING = 18;

export const StyledHeader = styled.View`
	flex-direction: row;
	align-items: center;
	padding: ${PADDING}px ${PADDING}px 0 ${PADDING}px;
`;

export const StyledName = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 23px;
	color: #27252f;
	letter-spacing: -0.63px;
`;

export const StyledEmail = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 18px;
	color: #27252f;
	letter-spacing: -0.5px;
`;

export const StyledButtonsContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
	padding: 0 ${PADDING}px;
`;

export const StyledButton = styled.TouchableOpacity`
	background: rgba(133, 125, 152, 0.11);
	border-radius: 8px;
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: #261b42;
	letter-spacing: -0.44px;
	height: 50px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex: 1;
	margin: 0 4px;
`;

export const StyledButtonLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 15px;
	color: #261b42;
	letter-spacing: -0.44px;
`;

export const StyledButtonDangerLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 15px;
	color: red;
	letter-spacing: -0.44px;
`;

export const StyledButtonEmailIcon = styled(MaterialCommunityIcons).attrs({
	name: 'email-edit-outline',
	size: 24,
	color: ui.colors.primary,
})`
	margin-right: 3px;
`;

export const StyledButtonEmailLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 15px;
	color: ${ui.colors.primary};
	letter-spacing: -0.48px;
	text-align: center;
`;

export const StyledButtonDeleteLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 15px;
	color: ${ui.colors.dark};
	letter-spacing: -0.48px;
	text-align: center;
`;

export const StyledSubtitle = styled.Text`
	color: ${ui.colors.secondary};
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	text-align: left;
	margin-top: 10px;
	margin-left: ${PADDING}px;
	margin-bottom: 10px;
	border-bottom-width: 1px;
	border-bottom-color: #eae9f4;
`;

export const StyledListItemContainer = styled.View`
	flex-direction: row;
	align-items: center;
	padding: 12px ${PADDING}px;
	background-color: #fff;
	height: 60px;
`;

export const StyledIconContainer = styled.View`
	width: 30px;
	height: 30px;
	border-radius: 15px;
	background-color: rgba(133, 125, 152, 0.11);
	justify-content: center;
	align-items: center;
	margin-right: 14px;
`;

export const StyledListItemDate = styled.Text`
	font-size: 18px;
`;

export const StyledListItemPoints = styled.Text`
	font-size: 18px;
	color: #857d98;
	margin-left: auto;
`;

export const StyledArrow = styled(Entypo).attrs({
	name: 'chevron-small-right',
	size: 24,
	color: 'rgba(39,37,47,0.28)',
})``;

export const StyledInput = styled.TextInput`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 23px;
	color: #27252f;
	letter-spacing: -0.63px;
	padding: ${PADDING}px;
	height: 80px;
	border-width: 1;
	border-color: #857d98;
	border-radius: 4px;
	margin: ${PADDING}px;
`;
