import React, { Fragment, useMemo, useState } from 'react';
import {
	ActivityIndicator,
	FlatList,
	ListRenderItem,
	View,
} from 'react-native';
import i18n from 'i18n-js';
import { Loading, ScreenSection, ui } from '@newstart/ui';
import { useAuth } from '@newstart/auth';
import { GeneralRankingEntry, IndividualRankingEntry } from '@newstart/engine';

import { StatsScreenItem } from '../types';
import {
	MultipleLinesChart,
	RankingListItem,
	Title,
} from '../../../components';

type StatsScreenMeProps = {
	isFetching: boolean;
	userGender: string;
	userAgeGroup: string;
	general: GeneralRankingEntry[];
	byAge: IndividualRankingEntry[];
	byGender: IndividualRankingEntry[];
};

export const StatsScreenMe: React.FunctionComponent<StatsScreenMeProps> = ({
	isFetching,
	userGender,
	userAgeGroup,
	general,
	byAge,
	byGender,
}) => {
	const { user } = useAuth();
	if (!user) return null;

	const { data } = useMemo(() => {
		const items: StatsScreenItem[] = [];

		items.push({
			format: 'chart',
			key: 'chart',
			data: {},
		});

		if (!isFetching) {
			items.push({
				format: 'title',
				key: 'age_ranking',
				data: { label: `${userAgeGroup} ${i18n.t('age-ranking')}` },
			});

			const userPlaceByAge = byAge.findIndex(
				(entry) => entry.userId === user.id
			);
			byAge.map((entry, index) => {
				if (index < 5) {
					items.push({
						format: 'rankingEntry',
						key: `byage.${entry.userId}`,
						data: {
							id: entry.userId,
							place: index,
							points: entry._sum.points,
							isMe: userPlaceByAge === index,
							name:
								entry.user?.username ??
								`${entry.user?.firstName} ${entry.user?.lastName}`,
						},
					});
				}
			});
			if (userPlaceByAge > 4) {
				const userEntry = byAge[userPlaceByAge];
				items.push({
					format: 'rankingEntry',
					key: `byage.${userEntry.userId}`,
					data: {
						id: userEntry.userId,
						place: userPlaceByAge,
						points: userEntry._sum.points,
						isMe: true,
						name: user?.username ?? `${user?.firstName} ${user?.lastName}`,
					},
				});
			}

			items.push({
				format: 'title',
				key: 'gender_ranking',
				data: {
					label:
						userGender === 'M'
							? i18n.t('male-ranking')
							: i18n.t('female-ranking'),
				},
			});
			const userPlaceByGender = byGender.findIndex(
				(entry) => entry.userId === user.id
			);
			byGender.map((entry, index) => {
				if (index < 5) {
					items.push({
						format: 'rankingEntry',
						key: `bygender.${entry.userId}`,
						data: {
							id: entry.userId,
							place: index,
							points: entry._sum.points,
							isMe: userPlaceByGender === index,
							name:
								entry.user?.username ??
								`${entry.user?.firstName} ${entry.user?.lastName}`,
						},
					});
				}
			});
			if (userPlaceByGender > 4) {
				const userEntry = byGender[userPlaceByGender];
				items.push({
					format: 'rankingEntry',
					key: `bygender.${userEntry.userId}`,
					data: {
						id: userEntry.userId,
						place: userPlaceByGender,
						points: userEntry._sum.points,
						isMe: true,
						name: user?.username ?? `${user?.firstName} ${user?.lastName}`,
					},
				});
			}

			items.push({
				format: 'title',
				key: 'general_ranking',
				data: { label: i18n.t('general-ranking') },
			});

			const userGeneralPlace = general.findIndex(
				(entry) => entry.id === user.id
			);
			general.map((entry, index) => {
				items.push({
					format: 'rankingEntry',
					key: `general.${entry.id}`,
					data: {
						id: entry.id,
						place: index,
						points: entry.points,
						isMe: userGeneralPlace === index,
						name: entry.username ?? `${entry.firstName} ${entry.lastName}`,
					},
				});
			});
		} else {
			items.push({
				format: 'title',
				key: 'age_ranking',
				data: { label: i18n.t('age-ranking') },
			});

			items.push({
				format: 'loading',
				key: 'loading_1',
				data: {},
			});

			items.push({
				format: 'title',
				key: 'gender_ranking',
				data: {
					label: i18n.t('gender-ranking'),
				},
			});

			items.push({
				format: 'loading',
				key: 'loading_2',
				data: {},
			});

			items.push({
				format: 'title',
				key: 'general_ranking',
				data: { label: i18n.t('general-ranking') },
			});

			items.push({
				format: 'loading',
				key: 'loading_3',
				data: {},
			});
		}

		return {
			data: items,
		};
	}, [isFetching]);

	const renderItem: ListRenderItem<StatsScreenItem> = ({ item }) => {
		const { format } = item;
		if (format === 'chart')
			return (
				<ScreenSection title={i18n.t('activity')}>
					<MultipleLinesChart />
				</ScreenSection>
			);

		if (format === 'title') return <Title>{item.data.label}</Title>;

		if (format === 'rankingEntry')
			return (
				<RankingListItem
					key={item.data.id}
					place={item.data.place}
					name={item.data.name}
					points={item.data.points}
					isMe={item.data.isMe}
				/>
			);

		if (format === 'loading') {
			return <Loading dark />;
		}

		return <Fragment />;
	};

	// if (isFetching) {
	// 	return (
	// 		<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
	// 			<ActivityIndicator size="large" color={ui.colors.light} />
	// 		</View>
	// 	);
	// }

	return (
		<FlatList<StatsScreenItem>
			renderItem={renderItem}
			keyExtractor={(item) => item.key}
			style={{
				width: '100%',
				flex: 1,
			}}
			contentContainerStyle={{
				paddingBottom: 20,
			}}
			data={data}
		/>
	);
};
