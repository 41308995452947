import styled from 'styled-components/native';
import { LinearGradient, LinearGradientProps } from 'expo-linear-gradient';
import { PeopleLineChartProps } from './PeopleLineChart';
import { ViewProps } from 'react-native';
import { ui } from '@newstart/ui';

export const StyledChartContainer = styled.View`
	border-top-width: 1px;
	border-top-color: rgba(255, 255, 255, 0.2);
	border-bottom-width: 1px;
	border-bottom-color: rgba(255, 255, 255, 0.2);
`;

export const StyledLineContainer = styled.View<
	Pick<PeopleLineChartProps, 'height'> & { last: boolean }
>`
	border-bottom-width: ${({ last }) => (last ? 0 : 1)}px;
	border-bottom-color: rgba(255, 255, 255, 0.2);
	flex-direction: row;
	height: ${({ height }) => height ?? 80}px;
	margin-left: 10px;
`;

export const StyledHeadingContainer = styled.View`
	flex: 0.2;
	justify-content: center;
	align-items: center;
`;

export const StyledBarsContainer = styled.View`
	flex: 0.8;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 10px;
`;

type StyledBarProps = ViewProps & {
	first: boolean;
	last: boolean;
	full: boolean;
};

export const StyledBar = styled.View<StyledBarProps>`
	width: 24px;
	background-color: ${({ full }) =>
		full ? '#75FF25' : 'rgba(255, 255, 255, 0.2)'};
	justify-content: center;
	align-items: center;
	border-top-left-radius: ${({ first }) => (first ? 12 : 0)}px;
	border-top-right-radius: ${({ first }) => (first ? 12 : 0)}px;
	border-bottom-left-radius: ${({ last }) => (last ? 12 : 0)}px;
	border-bottom-right-radius: ${({ last }) => (last ? 12 : 0)}px;
`;

type StyledBarFillProps = LinearGradientProps & {
	first: boolean;
	last: boolean;
	full: boolean;
};

export const StyledBarFill = styled(LinearGradient)<StyledBarFillProps>`
	width: 100%;
	height: 0px;
	border-radius: 12px;
`;

export const StyleUsername = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 10px;
	color: ${ui.colors.light};
`;
