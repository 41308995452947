import React, { useState, useEffect } from 'react';
import i18n from 'i18n-js';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
	BaseModalContainer,
	Loading,
	StatusBar,
	useMessage,
} from '@newstart/ui';
import { getTeamService } from '@newstart/team';

import { StatsStackParamList } from '../../navigation';

import {
	StyledName,
	StyledPositionContainer,
	StyledPosition,
	StyledPoints,
	StyledMemberBubbleContainer,
	StyledContainer,
} from './styles';
import { MemberBubble } from './MemberBubble';
import { useAuth } from '@newstart/auth';

const teamService = getTeamService();

type StatsTeamDetailModalProps = NativeStackScreenProps<
	StatsStackParamList,
	'Stats_Team_Detail'
>;

type MemberItem = {
	name: string;
	points: string;
	photoUrl: string | null;
};

export const StatsTeamDetailModal: React.FunctionComponent<
	StatsTeamDetailModalProps
> = ({ navigation, route }) => {
	const { position, name, points, teamId } = route.params;
	const { showError } = useMessage();
	const { token } = useAuth();
	const [loading, setLoading] = useState(true);
	const [members, setMembers] = useState<MemberItem[]>([]);

	function renderName(
		firstName: string,
		lastName: string,
		username: string | null
	) {
		return username || `${firstName} ${lastName}`;
	}

	useEffect(() => {
		async function fetchData() {
			if (!token) return;
			try {
				const membersResult = await teamService.getMembersByTeamId(
					teamId,
					token
				);
				setMembers(
					membersResult.map((member) => ({
						id: member.id,
						name: renderName(
							member.firstName,
							member.lastName,
							member.username
						),
						points: i18n.toNumber(member.points, {
							precision: 0,
							delimiter: ' ',
						}),
						photoUrl: member.photoUrl,
					}))
				);
			} catch (error) {
				showError(error);
			} finally {
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	return (
		<BaseModalContainer
			backgroundColor={['#155474', '#0C0A24']}
			onClose={() => navigation.goBack()}
			dark
		>
			<StyledContainer>
				<StyledPositionContainer>
					<StyledPosition>{position + 1}º</StyledPosition>
				</StyledPositionContainer>
				<StyledName numberOfLines={1}>{name}</StyledName>
				<StyledPoints>{points}</StyledPoints>

				<StyledMemberBubbleContainer>
					{loading ? (
						<Loading dark />
					) : (
						members.map((member, i) => <MemberBubble key={i} {...member} />)
					)}
				</StyledMemberBubbleContainer>
			</StyledContainer>
			<StatusBar style="light" />
		</BaseModalContainer>
	);
};
