import React, { useCallback } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import i18n from 'i18n-js';
import { User } from '@newstart/auth';
import { Avatar } from '@newstart/ui';

import {
	StyledUserListItem,
	StyledName,
	StyledLeaderTag,
	StyledLeaderTagText,
	StyledArrow,
} from './styles';

export const MemberItem: React.FunctionComponent<MemberItemProps> = ({
	isAuthenticatedUser,
	isAuthenticatedUserOwner,
	isMemberOwner,
	member,
	onSelectMember,
}) => {
	const handlePress = useCallback(() => {
		if (isAuthenticatedUser) {
			return;
		}
		onSelectMember(member);
	}, []);

	const renderName = (user: {
		username: string;
		firstName: string;
		lastName: string;
	}) => {
		return user.username ? user.username : `${user.firstName} ${user.lastName}`;
	};

	return (
		<TouchableOpacity
			activeOpacity={
				Platform.OS === 'web' ||
				isAuthenticatedUser ||
				!isAuthenticatedUserOwner
					? 1
					: 0.5
			}
			onPress={handlePress}
		>
			<StyledUserListItem>
				<Avatar
					photoUrl={member.photoUrl}
					size={40}
					style={{ marginRight: 10 }}
				/>
				<StyledName numberOfLines={1}>{renderName(member)}</StyledName>
				{isMemberOwner && (
					<StyledLeaderTag>
						<StyledLeaderTagText>{i18n.t('owner')}</StyledLeaderTagText>
					</StyledLeaderTag>
				)}
				{isAuthenticatedUserOwner && <StyledArrow />}
			</StyledUserListItem>
		</TouchableOpacity>
	);
};

type MemberItemProps = {
	member: User;
	isMemberOwner: boolean;
	isAuthenticatedUserOwner: boolean;
	isAuthenticatedUser: boolean;
	onSelectMember: (selectedUser: User) => void;
};
