import * as React from "react"
import { Image } from "react-native"
import i18n from "i18n-js"
import { MaterialIcons } from "@expo/vector-icons"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"

import { useAuth } from "@newstart/auth"
import { EngineProvider } from "@newstart/engine"
import { HabitsNavigator } from "@newstart/habits"
import { StatsNavigator } from "@newstart/stats"
import { TeamNavigator } from "@newstart/team"
import { GuidesNavigator } from "@newstart/guides"
import { ProfileNavigator } from "@newstart/profile"
import { AdminNavigator } from "@newstart/admin"
import { ui } from "@newstart/ui"
import { usePushNotifications, useDonateAlert } from "@newstart/notifications"
import { AppTabsParamList } from "./types"

const Tab = createBottomTabNavigator<AppTabsParamList>()

export const AppTabsNavigator = () => {
  const { isAdmin } = useAuth()
  usePushNotifications()
  useDonateAlert()

  return (
    <EngineProvider>
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarStyle: {
            backgroundColor: ui.colors.light,
          },
          tabBarLabelStyle: {
            paddingBottom: 5,
          },
          tabBarActiveTintColor: ui.colors.dark,
          tabBarInactiveTintColor: "#A8A4B4",
          tabBarHideOnKeyboard: true,
        }}
      >
        <Tab.Screen
          name="Tab_Habits"
          component={HabitsNavigator}
          options={{
            tabBarIcon: ({ focused }) => (
              <Image
                source={require("@newstart/assets/icons/tab-icon-habits.png")}
                style={{
                  opacity: focused ? 1 : 0.4,
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                }}
              />
            ),
            tabBarLabel: i18n.t("tab-habits"),
          }}
        />
        <Tab.Screen
          name="Tab_Stats"
          component={StatsNavigator}
          options={{
            tabBarIcon: ({ focused }) => (
              <Image
                source={require("@newstart/assets/icons/tab-icon-stats.png")}
                resizeMode="contain"
                style={{
                  opacity: focused ? 1 : 0.4,
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                }}
              />
            ),
            tabBarLabel: i18n.t("tab-stats"),
          }}
        />
        <Tab.Screen
          name="Tab_Team"
          component={TeamNavigator}
          options={{
            tabBarIcon: ({ focused }) => (
              <Image
                source={require("@newstart/assets/icons/tab-icon-team.png")}
                resizeMode="contain"
                style={{
                  opacity: focused ? 1 : 0.4,
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                }}
              />
            ),
            tabBarLabel: i18n.t("tab-team"),
          }}
        />
        <Tab.Screen
          name="Tab_Guides"
          component={GuidesNavigator}
          options={{
            tabBarIcon: ({ focused }) => (
              <Image
                source={require("@newstart/assets/icons/tab-icon-guides.png")}
                resizeMode="contain"
                style={{
                  opacity: focused ? 1 : 0.4,
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                }}
              />
            ),
            tabBarLabel: i18n.t("tab-guides"),
          }}
        />
        <Tab.Screen
          name="Tab_Profile"
          component={ProfileNavigator}
          options={{
            tabBarIcon: ({ focused }) => (
              <Image
                source={require("@newstart/assets/icons/tab-icon-profile.png")}
                resizeMode="contain"
                style={{
                  opacity: focused ? 1 : 0.4,
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                }}
              />
            ),
            tabBarLabel: i18n.t("tab-profile"),
          }}
        />
        {isAdmin && (
          <Tab.Screen
            name="Tab_Admin"
            component={AdminNavigator}
            options={{
              tabBarIcon: ({ focused, size }) => (
                <MaterialIcons
                  name="security"
                  color={ui.colors.dark}
                  size={size}
                  style={{
                    opacity: focused ? 1 : 0.4,
                  }}
                />
              ),
              tabBarLabel: i18n.t("tab-admin"),
            }}
          />
        )}
      </Tab.Navigator>
    </EngineProvider>
  )
}
