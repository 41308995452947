import { HttpClient, httpErrorHandler } from "@newstart/core"
import { AuthToken, User } from "../types"

import {
  AuthResponse,
  AuthServiceInterface,
  RecoverResponse,
  RegisterRequest,
} from "./types"

export class AuthService implements AuthServiceInterface {
  constructor(private _httpClient: HttpClient) {}

  async register(args: RegisterRequest): Promise<AuthResponse> {
    return new Promise((resolve, reject) =>
      this._httpClient
        .post<AuthResponse, RegisterRequest>("/auth/register", args)
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    )
  }

  async signIn(email: string, password: string): Promise<AuthResponse> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<AuthResponse, { email: string; password: string }>(
          "/auth/authenticate",
          { email, password }
        )
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async forgotPassword(email: string): Promise<RecoverResponse> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<RecoverResponse, { email: string }>("/auth/recover", { email })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async validateRecover(code: string, email: string): Promise<RecoverResponse> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<RecoverResponse, { code: string; email: string }>(
          "/auth/validateRecover",
          { code, email }
        )
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async updatePassword(
    email: string,
    newPassword: string,
    code: string
  ): Promise<RecoverResponse> {
    console.log("service", newPassword)
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<
          RecoverResponse,
          { email: string; password: string; code: string }
        >("/auth/updatePassword", { email, password: newPassword, code })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async reloadUserData(token: AuthToken): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<User>("/me", { Authorization: `Bearer ${token}` })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async updateUser(
    args: Partial<Omit<RegisterRequest, "password">>,
    token: AuthToken
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<User, Partial<Omit<RegisterRequest, "password">>>("/me", args, {
          Authorization: `Bearer ${token}`,
        })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async deleteUser(userId: string, token: AuthToken): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .delete<User, string>("/me", userId, {
          Authorization: `Bearer ${token}`,
        })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }
}
