import React from 'react';
import { MaterialIcons, Ionicons } from '@expo/vector-icons';
import { ui } from '@newstart/ui';
import { HabitIcon } from '../HabitIcon';
import { HabitBubbleProps } from './interfaces';

import { StyledButton, StyledTitle } from './styles';
import { StyledContainer } from './StyledContainer';
import { StyledIconContainer } from './StyledIconContainer';

export const HabitBubble: React.FunctionComponent<HabitBubbleProps> = ({
	title,
	colors,
	icon,
	size,
	filled = false,
	...props
}) => {
	return (
		<StyledButton activeOpacity={0.5} {...props}>
			<StyledContainer size={size} colors={colors} filled={filled}>
				<StyledIconContainer colors={colors} filled={filled}>
					<HabitIcon name={icon} size={26} />
				</StyledIconContainer>

				<StyledTitle style={{ color: filled ? ui.colors.dark : colors[0] }}>
					{title}
				</StyledTitle>

				{filled ? (
					<Ionicons name="checkmark-circle" size={32} color={ui.colors.dark} />
				) : (
					<MaterialIcons
						name="radio-button-unchecked"
						size={32}
						color={ui.colors.light}
					/>
				)}
			</StyledContainer>
		</StyledButton>
	);
};
