import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledLogoContainer = styled.View`
	height: 60%;
	justify-content: center;
	align-items: center;
`;
export const StyledActionsContainer = styled.View`
	padding: 20px 40px;
	width: ${ui.width}px;
	height: 260px;
	justify-content: flex-end;
	align-items: flex-end;
	position: absolute;
	bottom: ${ui.bottomSpace}px;
`;

export const StyledText = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 14px;
	color: ${ui.colors.secondary};
	letter-spacing: -0.38px;
	text-align: center;
	line-height: 21px;
`;
export const StyledLink = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 14px;
	color: ${ui.colors.dark};
	letter-spacing: -0.38px;
	text-align: center;
	line-height: 21px;
	padding: 0;
`;
