import { useEngine } from '@newstart/engine';
import { useMessage } from '@newstart/ui';
import i18n from 'i18n-js';
import React from 'react';
import { View } from 'react-native';
import { HabitBubbleProps } from '../../hooks';
import { HabitExerciseBubble, HabitBubble } from '../HabitBubble';

type HabitsScreenReadyProps = {
	habitBubbles: HabitBubbleProps[];
	minHeightBubblesContainer: number;
	onHabitCheck: (habitId: string, achievementId?: string) => void;
	onExerciseOpenModal: (habitId: string) => void;
};
export const HabitsBubbleList: React.FunctionComponent<
	HabitsScreenReadyProps
> = ({
	habitBubbles,
	minHeightBubblesContainer,
	onHabitCheck,
	onExerciseOpenModal,
}) => {
	const { selectedIndex, currentIndex } = useEngine();
	const { showError } = useMessage();

	return (
		<View
			style={{
				minHeight: minHeightBubblesContainer,
				position: 'relative',
			}}
		>
			{habitBubbles.map(({ habit, styles, userPoints, achievementId }, i) => {
				if (i === 0) {
					return (
						<HabitExerciseBubble
							key={habit.id}
							title={habit.bubbleMessage}
							icon={habit.icon}
							colors={habit.category.colors}
							filled={userPoints >= habit.points}
							size={styles.size}
							progress={(userPoints / habit.points) * 100}
							steps={userPoints}
							style={styles}
							onPress={() => onExerciseOpenModal(habit.id)}
						/>
					);
				} else {
					return (
						<HabitBubble
							key={habit.id}
							title={habit.bubbleMessage}
							icon={habit.icon}
							colors={habit.category.colors}
							filled={userPoints >= habit.points}
							size={styles.size}
							style={styles}
							onPress={
								selectedIndex === currentIndex
									? () => onHabitCheck(habit.id, achievementId)
									: () => showError(i18n.t('good-habits-subtitle'))
							}
						/>
					);
				}
			})}
		</View>
	);
};
