import { useAuth } from '@newstart/auth';
import {
	getRankingService,
	GeneralRankingEntry,
	IndividualRankingEntry,
	TeamRankingEntry,
} from '@newstart/engine';
import { useMessage } from '@newstart/ui';
import { useEffect, useState } from 'react';

const rankingService = getRankingService();

export const useRankingData = () => {
	const { token } = useAuth();
	const { showError } = useMessage();

	const [isFetching, setIsFetching] = useState(true);

	const [userGender, setUserGender] = useState<string>('');
	const [userAgeGroup, setUserAgeGroup] = useState<string>('');
	const [general, setGeneral] = useState<GeneralRankingEntry[]>([]);
	const [byAge, setByAge] = useState<IndividualRankingEntry[]>([]);
	const [byGender, setByGender] = useState<IndividualRankingEntry[]>([]);

	const [teamSize, setTeamSize] = useState<number>(0);
	const [teamGeneral, setTeamGeneral] = useState<TeamRankingEntry[]>([]);
	const [byMyTeam, setByMyTeam] = useState<GeneralRankingEntry[]>([]);
	const [byTeamSize, setByTeamSize] = useState<TeamRankingEntry[]>([]);

	useEffect(() => {
		if (!token) return;
		(async function getRankings() {
			try {
				const { general, byAge, byGender, userAgeGroup, userGender, teams } =
					await rankingService.getData(token);

				setGeneral(general);
				setByAge(byAge);
				setByGender(byGender);
				setUserAgeGroup(userAgeGroup);
				setUserGender(userGender);

				setTeamGeneral(teams.general);
				setByMyTeam(teams.byMyTeam);
				setByTeamSize(teams.byTeamSize);
				setTeamSize(teams.teamSize);
			} catch (error) {
				showError(error);
			} finally {
				setIsFetching(false);
			}
		})();
	}, [token]);

	return {
		isFetching,
		userGender,
		userAgeGroup,
		general,
		byAge,
		byGender,
		teamSize,
		teamGeneral,
		byMyTeam,
		byTeamSize,
	};
};
