import React from 'react';
import { Text, View, ViewProps } from 'react-native';

export const Screen: React.FunctionComponent<ViewProps & { title: string }> = ({
	title,
	children,
}) => {
	return (
		<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
			<Text style={{ fontSize: 40 }}>{title}</Text>
			{children}
		</View>
	);
};
