import React from 'react';
import { ScrollView, View, ViewProps } from 'react-native';
import { BaseContainer } from './BaseContainer';
import { StyledScrollContent } from './styles';

type Props = ViewProps & {
	backgroundColor?: string[] | string;
	headingComponent?: React.ReactNode;
	safeArea?: boolean;
};

export const ScrollContainer: React.FunctionComponent<Props> = ({
	headingComponent,
	children,
	backgroundColor,
	...props
}) => {
	return (
		<BaseContainer backgroundColor={backgroundColor} {...props}>
			{headingComponent}
			<ScrollView style={{ flex: 1 }}>
				<StyledScrollContent>{children}</StyledScrollContent>
			</ScrollView>
		</BaseContainer>
	);
};
