import React from "react";
import {
	GestureResponderEvent,
	StyleProp,
	TextStyle,
	TouchableOpacity,
	ViewStyle,
} from "react-native";
import { StyledContainer, StyledMessage, StyledActionText } from "./styles";

type CTAProps = {
	title?: string;
	ctaText?: string;
	onPress?: (event: GestureResponderEvent) => void;
	style?: StyleProp<ViewStyle>;
	titleStyle?: StyleProp<TextStyle>;
	ctaStyle?: StyleProp<TextStyle>;
};

export const CTA: React.FunctionComponent<CTAProps> = ({
	title,
	ctaText,
	onPress,
	style,
	titleStyle,
	ctaStyle,
}) => {
	return (
		<StyledContainer {...style}>
			{title && (
				<StyledMessage style={[titleStyle, ctaText ? { marginRight: 5 } : {}]}>
					{title}
				</StyledMessage>
			)}

			{ctaText && (
				<TouchableOpacity onPress={onPress}>
					<StyledActionText style={[ctaStyle]}>{ctaText}</StyledActionText>
				</TouchableOpacity>
			)}
		</StyledContainer>
	);
};
