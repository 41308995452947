import styled from 'styled-components/native';
import { Entypo } from '@expo/vector-icons';
import { ui } from '@newstart/ui';
import { Platform } from 'react-native';

export const StyledContainer = styled.View`
	flex: 1;
	background-color: ${ui.colors.light};
`;

export const StyledSearchBarContainer = styled.View`
	height: ${Platform.OS === 'android' ? 54 : 54}px;
`;

export const StyledItemContainer = styled.TouchableOpacity`
	flex-direction: row;
	align-items: center;
	padding: 8px 8px 8px 20px;
`;

export const StyledName = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 16px;
	margin-left: 10px;
`;

export const StyledArrow = styled(Entypo).attrs({
	name: 'chevron-small-right',
	size: 24,
	color: 'rgba(39,37,47,0.28)',
})`
	margin-left: auto;
`;
