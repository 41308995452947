import React, { useCallback } from 'react';
import { Text } from 'react-native';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import i18n from 'i18n-js';
import { PasswordInput, PrimaryButton, StyledError } from '@newstart/ui';

type NewPasswordFormValue = {
	password: string;
	changepassword: string;
};

const newPasswordValidationSchema = Yup.object().shape({
	password: Yup.string().required('Required'),
	changepassword: Yup.string().when('password', {
		is: (val: string) => (val && val.length > 0 ? true : false),
		then: Yup.string().oneOf(
			[Yup.ref('password')],
			'Both password need to be the same'
		),
	}),
});

export type NewPasswordFormProps = {
	onRequestSubmit: (passwords: NewPasswordFormValue) => void;
};

export const NewPasswordForm: React.FunctionComponent<NewPasswordFormProps> = ({
	onRequestSubmit,
}) => {
	return (
		<Formik
			initialValues={{
				password: '',
				changepassword: '',
			}}
			validationSchema={newPasswordValidationSchema}
			onSubmit={onRequestSubmit}
		>
			{({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
				<>
					<Text style={{ marginBottom: 20 }}>
						{i18n.t('successfully-verified')}
					</Text>

					<PasswordInput
						label={i18n.t('input-a-new-password')}
						value={values.password}
						onChangeText={handleChange('password')}
						onBlur={handleBlur('password')}
						required
						error={<ErrorMessage component={StyledError} name="password" />}
					/>

					<PasswordInput
						label={i18n.t('confirm-password')}
						value={values.changepassword}
						onChangeText={handleChange('changepassword')}
						onBlur={handleBlur('changepassword')}
						required
						error={
							<ErrorMessage component={StyledError} name="changepassword" />
						}
					/>

					<PrimaryButton onPress={handleSubmit} loading={isSubmitting}>
						{i18n.t('reset-password')}
					</PrimaryButton>
				</>
			)}
		</Formik>
	);
};
