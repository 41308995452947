import React from "react"
import { Entypo } from "@expo/vector-icons"
import { Text } from "react-native"

import { CardContainer, CardBackground, CardColor, CardText } from "./styles"

export const GuideButton: React.FunctionComponent<{
  onPress: () => void
  background?: number
  label: string
}> = ({ onPress, background, label }) => {
  const renderContent = () => {
    return (
      <>
        <CardText> {label} </CardText>
        <Entypo name="chevron-right" size={26} color="#fff"></Entypo>
      </>
    )
  }
  return (
    <CardContainer onPress={onPress} activeOpacity={0.8}>
      {background ? (
        <CardBackground
          source={background}
          imageStyle={{
            width: "auto",
          }}
        >
          {renderContent()}
        </CardBackground>
      ) : (
        <CardColor>{renderContent()}</CardColor>
      )}
    </CardContainer>
  )
}
