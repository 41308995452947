import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
const { colors, fonts, maxWidth } = ui;

export const TitleContainer = styled.View`
	width: 100%;
	max-width: ${maxWidth}px;
	background: ${colors.light};
	padding: 30px 20px 14px 20px;
	justify-content: space-between;
	flex-direction: row;
	align-self: center;
`;

export const Label = styled.Text`
	font-family: ${fonts.Inter.SemiBold};
	font-size: 20px;
	color: ${colors.dark};
	letter-spacing: -0.44px;
`;

export const LabelBig = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 28px;
	color: ${colors.primary};
	letter-spacing: -0.77px;
	text-align: center;
`;
