import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';
import { ui } from '@newstart/ui';
import { MultipleLinesChartProps } from './MultipleLinesChart';

export const StyledChartContainer = styled.View`
	border-top-width: 1px;
	border-top-color: rgba(255, 255, 255, 0.2);
`;

export const StyledLineContainer = styled.View<
	Pick<MultipleLinesChartProps, 'height'>
>`
	border-bottom-width: 1px;
	border-bottom-color: rgba(255, 255, 255, 0.2);
	flex-direction: row;
	height: ${({ height }) => height ?? 80}px;
`;

export const StyledHeadingContainer = styled.View`
	flex: 0.26;
	justify-content: center;
	align-items: center;
`;

export const StyledHeadingIcon = styled(LinearGradient)`
	width: 24px;
	height: 24px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
`;

export const StyledHeadingText = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	letter-spacing: -0.55px;
	margin-top: 4px;
	text-align: center;
`;

export const StyledBarsContainer = styled.View`
	flex: 0.74;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 10px;
`;

export const StyledBar = styled.View<{ isDisabled?: boolean }>`
	width: 13px;
	background-color: ${[
		(props) =>
			props.isDisabled
				? 'rgba(255, 255, 255, 0.05)'
				: 'rgba(255, 255, 255, 0.2)',
	]};
	justify-content: flex-end;
	align-items: flex-end;
	border-radius: 7.5px;
`;

export const StyledBarFill = styled(LinearGradient)`
	width: 100%;
	height: 0px;
	border-radius: 7.5px;
`;
