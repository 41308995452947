import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Platform, Image } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BaseModalContainer, Loading, StatusBar } from '@newstart/ui';
import {
	Achievement,
	useEngine,
	getBonusNotificationService,
} from '@newstart/engine';
import { useAuth } from '@newstart/auth';
import i18n from 'i18n-js';
import { HabitsStackParamList } from '../../navigation';
import {
	StyledBody,
	StyledAnimation,
	StyledAchievementIntro,
	StyledAchievementPoints,
	StyledAchievementEnd,
	StyledChallengeLabel,
	StyledChallengeTitle,
} from './styles';

type BonusModalProps = NativeStackScreenProps<
	HabitsStackParamList,
	'Habits_Bonus_Modal'
>;

export const BonusModal: React.FunctionComponent<BonusModalProps> = ({
	navigation,
	route,
}) => {
	const { bonusNotificationId } = route.params;
	const { markBonusNotificationAsRead } = useEngine();
	const { token } = useAuth();

	const [isLoading, setIsLoading] = useState(true);
	const [achievement, setAchievement] = useState<Achievement>();
	const [description, setDescription] = useState('');

	useEffect(() => {
		async function fetchNotificationData() {
			if (!token) return;
			const bonusNotification = await getBonusNotificationService().getById(
				bonusNotificationId,
				token
			);
			setAchievement(bonusNotification.achievement);
			setDescription(bonusNotification.challenge.title);
			setIsLoading(false);
		}
		fetchNotificationData();
	}, [token]);

	const handleDismiss = useCallback(async () => {
		try {
			await markBonusNotificationAsRead(bonusNotificationId);
		} catch (error) {
			console.log(error);
		} finally {
			navigation.goBack();
		}
	}, [bonusNotificationId, markBonusNotificationAsRead, navigation]);

	const renderIntro = () => {
		if (achievement?.user) {
			return (
				<StyledAchievementIntro>{i18n.t('you-earned')}</StyledAchievementIntro>
			);
		} else if (achievement?.team) {
			return (
				<StyledAchievementIntro>
					{i18n.t('your-team-earned')}
				</StyledAchievementIntro>
			);
		}
	};

	if (isLoading || !achievement) {
		return (
			<BaseModalContainer
				backgroundImage={require('@newstart/assets/img/bg-bonus.png')}
				onClose={() => navigation.goBack()}
				dark
			>
				<Loading dark />
			</BaseModalContainer>
		);
	}

	return (
		<BaseModalContainer
			backgroundImage={require('@newstart/assets/img/bg-bonus.png')}
			onClose={handleDismiss}
			dark
		>
			<StatusBar style="light" translucent={false} backgroundColor="#000" />

			<StyledBody>
				{Platform.OS == 'web' ? (
					<Image
						source={require('@newstart/assets/img/medal-3.png')}
						style={{
							width: 165,
							height: 166,
							marginBottom: 80,
						}}
					/>
				) : (
					<StyledAnimation
						source={require('@newstart/assets/lotties/medal-3.json')}
						autoPlay
						loop
					/>
				)}

				{renderIntro()}
				<StyledAchievementPoints>
					{i18n.toNumber(achievement.points, {
						precision: 0,
						delimiter: ' ',
					})}
				</StyledAchievementPoints>
				<StyledAchievementEnd>{i18n.t('bonus-points')}</StyledAchievementEnd>

				<StyledChallengeLabel>{i18n.t('for-completing')}</StyledChallengeLabel>
				<StyledChallengeTitle>{description}</StyledChallengeTitle>
				<StyledChallengeLabel>{i18n.t('challenge')}</StyledChallengeLabel>
			</StyledBody>
		</BaseModalContainer>
	);
};
