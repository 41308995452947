import React from 'react';
import i18n from 'i18n-js';

import {
	StyledEmptyContainer,
	StyledBubbleInner,
	StyledBubble,
	StyledGraphic,
	StyledTitle,
	StyledSubtitle,
} from './styles';
import { BaseContainer, ScreenHeader, StatusBar } from '@newstart/ui';

export const HabitsScreenFinished = () => {
	return (
		<BaseContainer backgroundColor={['#30A5DA', '#0D00B1']} safeArea>
			<ScreenHeader title="NewStart 80" />
			<StyledEmptyContainer>
				<StyledEmptyContainer>
					<StyledBubbleInner>
						<StyledGraphic
							source={require('@newstart/assets/img/sabbath-card-icon.png')}
						/>
						<StyledTitle>{i18n.t('endgame-message-title')}</StyledTitle>
						<StyledSubtitle>
							{i18n.t('endgame-message-subtitle')}
						</StyledSubtitle>
					</StyledBubbleInner>
					<StyledBubble />
				</StyledEmptyContainer>
			</StyledEmptyContainer>
			<StatusBar style="inverted" />
		</BaseContainer>
	);
};
