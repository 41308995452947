import { HabitCategory, HabitCategoryKey } from './types';

export const habitCategories: HabitCategory[] = [
	{
		key: HabitCategoryKey.EXERCISE,
		icon: 'exercise',
		colors: ['#75FF25', '#D8F83F'],
	},
	{
		key: HabitCategoryKey.NUTRITION,
		icon: 'apple',
		colors: ['#44FFFC', '#0FADBA'],
	},
	{
		key: HabitCategoryKey.SLEEP,
		icon: 'no-screen',
		colors: ['#FF83A7', '#E99579'],
	},
	{
		key: HabitCategoryKey.WALFARE,
		icon: 'forgiveness',
		colors: ['#FFDD57', '#E89D11'],
	},
];
