import { LinearGradientPoint } from 'expo-linear-gradient';
import React from 'react';
import { ViewProps } from 'react-native';
import {
	StyledBaseContainer,
	StyledGradientContainer,
	StyledImageBackgroundContainer,
} from './styles';

type Props = ViewProps & {
	backgroundColor?: string[] | string;
	backgroundImage?: number;
	safeArea?: boolean;
	start?: LinearGradientPoint | null;
	end?: LinearGradientPoint | null;
};

export const BaseContainer: React.FunctionComponent<Props> = ({
	backgroundColor,
	backgroundImage,
	children,
	...props
}) => {
	if (backgroundImage) {
		return (
			<StyledImageBackgroundContainer
				source={backgroundImage}
				resizeMode="cover"
				resizeMethod="auto"
				{...props}
			>
				{children}
			</StyledImageBackgroundContainer>
		);
	}

	if (Array.isArray(backgroundColor)) {
		return (
			<StyledGradientContainer colors={backgroundColor} {...props}>
				{children}
			</StyledGradientContainer>
		);
	}

	return (
		<StyledBaseContainer backgroundColor={backgroundColor} {...props}>
			{children}
		</StyledBaseContainer>
	);
};
