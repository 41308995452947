import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  PropsWithChildren,
} from "react"
import { AxiosHttpClient, LocalStorageClient, API_URL } from "@newstart/core"

import { AuthService, RegisterRequest } from "../services"
import { TeamService } from "../../team/services/team-service"

import { AuthToken, User, Team } from "../types"
import { AuthContextInterface } from "./types"
import { useFlags } from "./useFlags"
import { useMessage } from "@newstart/ui"

export const USER_KEY = "@newstart:v3:user"
export const TEAM_KEY = "@newstart:v3:team"
export const TOKEN_KEY = "@newstart:v3:token"

const AuthContext = createContext<AuthContextInterface | null>(null)

const storageClient = new LocalStorageClient()
const httpClient = new AxiosHttpClient(API_URL)
const authService = new AuthService(httpClient)
const teamService = new TeamService(httpClient)

const AuthProvider: React.FC = ({ children }: PropsWithChildren) => {
  const { showError } = useMessage()

  const [user, setUser] = useState<User | null>(null)
  const [team, setTeam] = useState<Team | null>(null)
  const [token, setToken] = useState<AuthToken | null>(null)
  const [loading, setLoading] = useState(true)
  const { flags, loadFlags, getFlag, setFlag, clearFlags } = useFlags()

  useMemo(() => {
    setLoading(true)
    Promise.all([
      storageClient.get<User>(USER_KEY),
      storageClient.get<Team>(TEAM_KEY),
      storageClient.get<AuthToken>(TOKEN_KEY),
      loadFlags(),
    ])
      .then(async (values) => {
        const storagedUser = values[0]
        const storagedTeam = values[1]
        const storagedToken = values[2]

        if (storagedUser && storagedToken) {
          setToken(storagedToken)
          setUser(storagedUser)
        }
        if (storagedTeam) {
          setTeam(storagedTeam)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.warn(error)
      })
  }, [])

  const signed = Boolean(user && token)
  const joined = Boolean(team)

  const signUp = useCallback(
    async (data: RegisterRequest) => {
      const {
        firstName,
        lastName,
        email,
        password,
        birthDate,
        country,
        gender,
        username,
      } = data
      if (
        !firstName ||
        !lastName ||
        !email ||
        !birthDate ||
        !password ||
        !country ||
        !gender ||
        !username ||
        !password
      ) {
        throw new Error("Fields with (*) are requireds")
      }

      const { user, token } = await authService.register(data)
      setUser(user)
      setToken(token)
      await setFlag("user_updated")
      await storeUser(user)
      await storeToken(token)
    },
    [setUser, setToken, setFlag]
  )

  const signIn = useCallback(
    async (email: string, password: string) => {
      setLoading(true)
      try {
        const { user, token } = await authService.signIn(email, password)
        setUser(user)
        setToken(token)

        await storeUser(user)
        await storeToken(token)

        if (user.team) {
          setTeam(user.team)
          await storeTeam(user.team)
        }
        setLoading(false)
      } catch (error) {
        showError(error)
        setLoading(false)
      }
    },
    [setUser, setToken, setTeam, setLoading]
  )

  const signOut = useCallback(async () => {
    await storageClient.multiDelete([TOKEN_KEY, USER_KEY, TEAM_KEY])
    setUser(null)
    setToken(null)
    setTeam(null)
  }, [setUser, setTeam, setToken, clearFlags])

  const reloadUser = useCallback(async () => {
    if (!token) return

    const user = await authService.reloadUserData(token)
    setUser(user)
    await storeUser(user)

    if (user.team) {
      setTeam(user.team)
      await storeTeam(user.team)
    } else {
      setTeam(null)
      await storeTeam(null)
    }
  }, [token, setUser, setTeam])

  const updateUser = useCallback(
    async (data: Partial<Omit<RegisterRequest, "password">>) => {
      if (!token) return
      const user = await authService.updateUser(data, token)
      setUser(user)
      await storeUser(user)
      await setFlag("user_updated")
    },
    [setUser, setFlag, token]
  )

  const deleteUser = useCallback(
    async (userId: string) => {
      if (!token) return
      await authService.deleteUser(userId, token)
      setUser(null)
    },
    [setUser, setFlag, token]
  )

  const forgotPassword = useCallback(async (email: string) => {
    return await authService.forgotPassword(email)
  }, [])

  const validateRecover = useCallback(async (code: string, email: string) => {
    return await authService.validateRecover(code, email)
  }, [])

  const updatePassword = useCallback(
    async (email: string, newPassword: string, code: string) => {
      return await authService.updatePassword(email, newPassword, code)
    },
    []
  )

  const createTeam = useCallback(
    async (name: string) => {
      if (!token) return

      return await teamService.create(name, token)
    },
    [token]
  )

  const joinTeam = useCallback(
    async (inviteCode: string) => {
      if (!token) return

      const { user, team } = await teamService.join(inviteCode, token)

      setUser(user)
      await storeUser(user)

      setTeam(team)
      await storeTeam(team)
    },
    [token, setUser, setTeam]
  )

  const leaveTeam = useCallback(async () => {
    if (!token || !user) return

    await teamService.leave(token)

    setUser({ ...user, team: null })
    await storeUser({ ...user, team: null })

    setTeam(null)
    await storeTeam(null)
  }, [token, user, setUser, setTeam])

  const renameTeam = useCallback(
    async (newName: string) => {
      if (!token || !team) return

      const updatedTeam = await teamService.update(
        team.id,
        { name: newName },
        token
      )

      setTeam(updatedTeam)
      await storeTeam(updatedTeam)
    },
    [team, token, setTeam]
  )

  const changeTeamOwner = useCallback(
    async (teamId: string, newOwnerUserId: string) => {
      if (!token) return

      const updatedTeam = await teamService.update(
        teamId,
        { ownerId: newOwnerUserId },
        token
      )

      setTeam(updatedTeam)
      await storeTeam(updatedTeam)
    },
    [token, setTeam]
  )

  const removeUserFromTeam = useCallback(
    async (teamId: string, userId: string) => {
      if (!token || !team) return

      const updatedMembers = await teamService.removeMember(
        teamId,
        userId,
        token
      )

      const members = updatedMembers.filter((member) => member.id !== userId)

      setTeam({ ...team, members })
      await storeTeam({ ...team, members })
    },
    [token, team, setTeam]
  )

  const deleteTeam = useCallback(async () => {
    if (!token || !user) return

    await teamService.delete(token)

    setUser({ ...user, team: null })
    await storeUser({ ...user, team: null })

    setTeam(null)
    await storeTeam(null)
  }, [token, user, setUser, setTeam])

  async function storeUser(user: User | null) {
    if (user) {
      return await storageClient.set<User>(USER_KEY, user)
    }
    return await storageClient.delete(TEAM_KEY)
  }

  async function storeTeam(team: Team | null) {
    if (team) {
      return await storageClient.set<Team>(TEAM_KEY, team)
    }
    return await storageClient.delete(TEAM_KEY)
  }

  async function storeToken(token: AuthToken) {
    return await storageClient.set<AuthToken>(TOKEN_KEY, token)
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        user,
        isAdmin: user ? user.isAdmin : false,
        loading,
        signUp,
        signIn,
        signOut,
        reloadUser,
        forgotPassword,
        validateRecover,
        updatePassword,
        deleteUser,
        updateUser,
        joined,
        team,
        createTeam,
        joinTeam,
        leaveTeam,
        flags,
        getFlag,
        setFlag,
        renameTeam,
        deleteTeam,
        changeTeamOwner,
        removeUserFromTeam,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
