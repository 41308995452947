import { ExerciseType, Level } from '@newstart/engine';

export const exerciseInputReducer = (
	state: ExerciseInputModalState,
	action: ExerciseInputModalAction
): ExerciseInputModalState => {
	switch (action.type) {
		case 'set_editing_mode':
			return calculate({
				...state,
				...action.payload,
				isEditing: true,
				status: 'exercise_type_selected',
			});
		case 'select_exercise_type':
			return {
				...state,
				exerciseType: action.payload.exerciseType,
				status: 'exercise_type_selected',
			};
		case 'set_elevation':
			return calculate({ ...state, ...action.payload });
		case 'set_steps':
			return calculate({ ...state, ...action.payload });
		case 'saving':
			return {
				...state,
				isSubmitting: true,
			};
		case 'saved':
			return {
				...state,
				status: 'done',
				isSubmitting: false,
			};
		case 'error':
			return {
				...state,
				isSubmitting: false,
			};
		default:
			throw new Error();
	}
};

export type ExerciseInputModalState = {
	status: 'ready' | 'exercise_type_selected' | 'loading' | 'done';
	rawSteps: number;
	steps: number;
	exerciseType?: ExerciseType;
	elevation?: Level;
	isSubmitting?: boolean;
	isEditing?: boolean;
	achievementId?: string;
};

type ActionType =
	| 'set_editing_mode'
	| 'select_exercise_type'
	| 'set_elevation'
	| 'set_steps'
	| 'saving'
	| 'saved'
	| 'error';

type BaseAction<K extends ActionType, T> = {
	type: K;
	payload: T;
};

export type ExerciseInputModalAction =
	| BaseAction<
			'set_editing_mode',
			{
				steps: number;
				rawSteps: number;
				exerciseType: ExerciseType;
				elevation: Level;
				achievementId: string;
			}
	  >
	| BaseAction<'select_exercise_type', { exerciseType: ExerciseType }>
	| BaseAction<'set_elevation', { elevation: Level; steps: number }>
	| BaseAction<'set_steps', { rawSteps: number; steps: number }>
	| BaseAction<
			'saved',
			{
				steps: number;
				exerciseType: ExerciseType;
				elevation?: Level;
			}
	  >
	| { type: 'saving' }
	| { type: 'error' };

const calculate = (state: ExerciseInputModalState): ExerciseInputModalState => {
	const { exerciseType, steps, elevation } = state;
	if (!exerciseType) return state;

	const { converter, multiplier } = exerciseType;

	if (converter) {
		return { ...state, steps: Math.round(steps * converter) };
	}

	if (multiplier) {
		const { LOW, MEDIUM, HIGH, SUPERHIGH } = multiplier;
		if (elevation === 'LOW') {
			return { ...state, steps: Math.round(steps * LOW) };
		}
		if (elevation === 'MEDIUM') {
			return { ...state, steps: Math.round(steps * MEDIUM) };
		}
		if (elevation === 'HIGH') {
			return { ...state, steps: Math.round(steps * HIGH) };
		}
		if (SUPERHIGH && elevation === 'SUPERHIGH') {
			return { ...state, steps: Math.round(steps * SUPERHIGH) };
		}
	}
	return state;
};
