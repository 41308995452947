import React, { useCallback, useState } from 'react';
import { BaseContainer, Form, useMessage } from '@newstart/ui';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AuthStackParamList } from '../../navigation';
import { useAuth } from '../../contexts';

import { ResetForm } from './ResetForm';
import { CodeForm } from './CodeForm';
import { NewPasswordForm } from './NewPasswordForm';
import i18n from 'i18n-js';

enum Stage {
	REQUEST_LINK = 'REQUEST_LINK',
	VERIFY = 'VERIFY',
	RESET = 'RESET',
}

type ForgotPasswordScreenProps = NativeStackScreenProps<
	AuthStackParamList,
	'Auth_Forgot_Password_Screen'
>;

export const ForgotPasswordScreen: React.FunctionComponent<
	ForgotPasswordScreenProps
> = ({ navigation }) => {
	const { forgotPassword, validateRecover, updatePassword } = useAuth();
	const { showError, showSuccess } = useMessage();

	const [loading, setLoading] = useState(false);
	const [stage, setStage] = useState<Stage>(Stage.REQUEST_LINK);
	const [email, setEmail] = useState('');
	const [code, setCode] = useState('');

	const onResetFormSubmit = useCallback(
		async (email: string) => {
			setLoading(true);
			try {
				setEmail(email);
				const { ack, message } = await forgotPassword(email);
				setLoading(false);

				if (ack === 'success') {
					setStage(Stage.VERIFY);
				}
				if (ack === 'failure') {
					throw new Error(message);
				}
			} catch (error) {
				showError(error);
				setLoading(false);
			}
		},
		[setLoading, setEmail, showError, forgotPassword]
	);

	const onCodeFormSubmit = useCallback(
		async (code: string) => {
			setLoading(true);
			try {
				const { ack, message } = await validateRecover(code, email);
				setLoading(false);

				if (ack === 'success') {
					setCode(code);
					setStage(Stage.RESET);
				}
				if (ack === 'failure') {
					throw new Error(message);
				}
			} catch (error) {
				showError(error);
				setLoading(false);
			}
		},
		[setLoading, setStage, setCode, showError, email, validateRecover]
	);

	const onNewPasswordFormSubmit = useCallback(
		async (values) => {
			try {
				const newPassword = values.password;
				const { ack, message } = await updatePassword(email, newPassword, code);

				if (ack === 'success') {
					showSuccess(i18n.t('password-updated-successfully'));
					navigation.navigate('Auth_Login_Screen');
				}
				if (ack === 'failure') {
					throw new Error(message);
				}
			} catch (error) {
				showError(error);
			}
		},
		[showError, email, code, updatePassword]
	);

	return (
		<BaseContainer>
			<Form>
				{stage === Stage.REQUEST_LINK && (
					<ResetForm onRequestSubmit={onResetFormSubmit} loading={loading} />
				)}
				{stage === Stage.VERIFY && (
					<CodeForm onRequestSubmit={onCodeFormSubmit} loading={loading} />
				)}
				{stage === Stage.RESET && (
					<NewPasswordForm onRequestSubmit={onNewPasswordFormSubmit} />
				)}
			</Form>
		</BaseContainer>
	);
};
