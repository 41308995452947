import React, { useCallback } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import { BaseContainer, useMessage, Form, CTA, StatusBar } from '@newstart/ui';
import { RegisterForm } from '../../components';

import { useAuth } from '../../contexts/useAuth';
import { AuthStackParamList } from '../../navigation/AuthNavigator';
import { parseDateToDatabase } from '../../utils/dates';

type RegisterScreenProps = NativeStackScreenProps<
	AuthStackParamList,
	'Auth_Register_Screen'
>;

export const RegisterScreen: React.FunctionComponent<RegisterScreenProps> = ({
	navigation,
}) => {
	const { signUp } = useAuth();
	const { showError } = useMessage();

	const onSubmit = useCallback(
		async (values) => {
			try {
				delete values.changepassword;
				await signUp({
					...values,
					birthDate: parseDateToDatabase(values.birthDate),
					timezone: Localization.timezone,
				});
			} catch (error) {
				showError(error);
			}
		},
		[signUp]
	);

	return (
		<BaseContainer>
			<StatusBar style="dark" />
			<Form>
				<RegisterForm
					onSubmit={onSubmit}
					defaultValue={{
						firstName: '',
						lastName: '',
						email: '',
						birthDate: '',
						country: '',
						gender: 'M',
						username: '',
						password: '',
						changepassword: '',
					}}
				/>

				<CTA
					title={i18n.t('already_have_an_account')}
					ctaText={i18n.t('login')}
					onPress={() => navigation.replace('Auth_Login_Screen')}
					style={{ marginTop: 15 }}
				/>
			</Form>
		</BaseContainer>
	);
};
