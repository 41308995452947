import React from 'react';
import {
	StyledElevationButtonContainer,
	StyledElevationButton,
	StyledElevationButtonIcon,
	StyledElevationButtonLabel,
} from './styles';

type ElevationButtonProps = {
	iconAsset: number;
	description?: string;
	onPress: () => void;
	isDisabled?: boolean;
	isSelected?: boolean;
};

export const ElevationButton: React.FunctionComponent<ElevationButtonProps> = ({
	iconAsset,
	description,
	onPress,
	isDisabled,
	isSelected,
}) => {
	return (
		<StyledElevationButtonContainer onPress={onPress}>
			<StyledElevationButton selected={isSelected} disabled={isDisabled}>
				<StyledElevationButtonIcon
					selected={isSelected}
					disabled={isDisabled}
					source={iconAsset}
				/>
			</StyledElevationButton>
			{description && (
				<StyledElevationButtonLabel selected={isSelected} disabled={isDisabled}>
					{description}
				</StyledElevationButtonLabel>
			)}
		</StyledElevationButtonContainer>
	);
};
