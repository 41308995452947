import {
	HttpClient,
	AxiosHttpClient,
	httpErrorHandler,
	API_URL,
} from '@newstart/core';
import { Habit, HabitCategory, HabitCategoryKey, HabitData } from './types';
import { habitCategories } from './habit-categories';
import { ExerciseTypeList, exerciseTypes } from './exercise-types';

class HabitService {
	constructor(private _httpClient: HttpClient) {}

	/**
	 * Lista de hábitos
	 * @param token usuário logado
	 * @returns
	 */
	public async find(token: string): Promise<Habit[]> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.get<HabitData[]>('/habits', { Authorization: `Bearer ${token}` })
				.then((data) => resolve(data.map((h) => this.parseHabit(h))))
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	/**
	 * Lista de categories dos hábitos
	 */
	public getCategories(): HabitCategory[] {
		return habitCategories;
	}

	public getExerciseTypes(): ExerciseTypeList {
		return exerciseTypes;
	}

	private parseHabit(habit: HabitData): Habit {
		return {
			...habit,
			category: this.parseHabitCategory(habit.category),
		};
	}

	private parseHabitCategory(key: HabitCategoryKey): HabitCategory {
		return (
			habitCategories.find((category) => category.key === key) ||
			habitCategories[0]
		);
	}
}

export const getHabitService = () => {
	return new HabitService(new AxiosHttpClient(API_URL));
};
