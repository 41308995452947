import React, { useCallback } from "react"
import { AntDesign } from "@expo/vector-icons"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import AppIntroSlider from "react-native-app-intro-slider"
import { StatusBar, ui } from "@newstart/ui"
import i18n from "i18n-js"

import { OnboardingStackParamList } from "../../navigation"
import {
  StyledButtonCircle,
  StyledImage,
  StyledScreenContainer,
  StyledSkipText,
  StyledSlide,
  StyledText,
  StyledTitle,
} from "./styles"
import { useAuth } from "@newstart/auth"

type WalkthroughScreenProps = NativeStackScreenProps<
  OnboardingStackParamList,
  "Onboarding_Walkthrough_Screen"
>

const data = [
  {
    title: "Bienvenue au programme NewStart 80",
    text: `Si c’est ta première fois, tu seras surpris-e ! Si c’est la deuxième, tu seras enchanté-e. Oui, NewStart 80 fait bouger pour le mieux tous les aspects de la vie. Une belle expérience à vivre avec ton corps, tes relations et ton bien-être intérieur.`,
    image: require("@newstart/assets/img/walkthrough-1.png"),
    bg: "#EFEFFF",
  },
  // {
  // 	title: 'Nouveautés du programme NewStart 80',
  // 	text: `Pour sa version 2022, de nouvelles modalités sportives ont été ajoutées au programme NewStart 80. En plus de la marche, de la course, de la natation et du vélo, d’autres sports ont été intégrés tels que les raquettes à neige, le ski de randonnée, le vélo électrique, la rame, la danse, le fitness, etc. De plus, pour les activés comme la marche, le jogging et le vélo, tu peux notifier si le dénivelé a été faible, modéré, moyen ou élevé. Plus c’est dur, plus tu gagnes de points.`,
  // 	image: require('@newstart/assets/img/walkthrough-2.png'),
  // 	bg: '#EFEFFF',
  // },
  {
    title: "NewStart 80 en résumé",
    text: `Donc, en résumé, pendant 80 jours, en solo et en équipe, tu es défié-e à marcher au moins 10k pas jour (ou toute autre activité prévue) et à intégrer 12 nouvelles bonnes habitudes dans ton style de vie. Celles-ci vont crescendo tout au long de l’expérience.`,
    image: require("@newstart/assets/img/walkthrough-3.png"),
    bg: "#EFEFFF",
  },
  {
    title: "Points bonus",
    text: `L’adoption d’une bonne habitude dans ton style de vie te donne, à toi et à ton équipe, 1200 "pas" bonus quotidiennement. Par exemple, une semaine tu seras invité-e à boire 2 litres d’eau par jour et la suivante, en plus de cela, à ne pas regarder les écrans 1 heure avant de dormir. Chaque jour, les défis réussis te feront gagner 1200 « pas bonus » en plus de tes pas accumulés en faisant du sport.`,
    image: require("@newstart/assets/img/walkthrough-4.png"),
    bg: "#EFEFFF",
  },
  {
    title: "Défis en équipes",
    text: `Pour gagner encore plus de points, des défis sont proposés en équipes. Par exemple, pratiquer une activité sportive non pas seul.e mais avec d’autres membres de ton équipe permet de cumuler encore des « pas » supplémentaires. Histoire de vous réjouir ensemble ! 
		Une question ? Contacte-nous via contact@vie-et-sante.ch. Alors, prêt-e ? On y va !`,
    image: require("@newstart/assets/img/walkthrough-5.png"),
    bg: "#EFEFFF",
  },
]
type Item = typeof data[0]

export const WalkthroughScreen: React.FunctionComponent<
  WalkthroughScreenProps
> = ({ navigation }) => {
  const { setFlag } = useAuth()
  const renderItem = ({ item }: { item: Item }) => {
    return (
      <StyledSlide style={{ backgroundColor: item.bg }}>
        <StyledImage source={item.image} />
        <StyledTitle>{item.title}</StyledTitle>
        <StyledText>{item.text}</StyledText>
      </StyledSlide>
    )
  }

  const keyExtractor = (item: Item) => item.title

  const renderNextButton = () => {
    return (
      <StyledButtonCircle>
        <AntDesign
          name="arrowright"
          size={24}
          color="rgba(255, 255, 255, .9)"
        />
      </StyledButtonCircle>
    )
  }

  const renderDoneButton = () => {
    return (
      <StyledButtonCircle>
        <AntDesign name="check" size={24} color="rgba(255, 255, 255, .9)" />
      </StyledButtonCircle>
    )
  }

  const renderSkipButton = () => {
    return <StyledSkipText>{i18n.t("skip")}</StyledSkipText>
  }

  const onDone = useCallback(async () => {
    await setFlag("walkthrough_completed")
  }, [setFlag])

  return (
    <StyledScreenContainer>
      <StatusBar style="dark" />
      <AppIntroSlider
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        data={data}
        renderDoneButton={renderDoneButton}
        renderNextButton={renderNextButton}
        renderSkipButton={renderSkipButton}
        onDone={onDone}
        showSkipButton
        activeDotStyle={{ backgroundColor: ui.colors.primary }}
      />
    </StyledScreenContainer>
  )
}
