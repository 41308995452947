import React, { useCallback } from 'react';
import { Share } from 'react-native';
import i18n from 'i18n-js';
import { useMessage, WhiteButton } from '@newstart/ui';
import { useAuth } from '@newstart/auth';

import {
	StyledBubble,
	StyledBubbleInner,
	StyledEmptyContainer,
	StyledLabel,
	StyledShareIcon,
} from './styles';
import AnimatedLottieView from 'lottie-react-native';

export const TeamEmptyScreen: React.FC = () => {
	const { team } = useAuth();
	const { showError } = useMessage();
	if (!team) return null;

	const handleSharing = useCallback(async () => {
		try {
			await Share.share({
				message: i18n.t('invitate-message', { code: team.inviteCode }),
			});
		} catch (error) {
			showError(error);
		}
	}, [team.inviteCode, showError]);

	return (
		<StyledEmptyContainer>
			<StyledBubbleInner>
				<AnimatedLottieView
					source={require('@newstart/assets/lotties/handup.json')}
					style={{ width: 280, height: 280 }}
					autoPlay
					loop
				/>
				<StyledLabel>
					{i18n.t('the-journey-is-better-with-your-friends')}
				</StyledLabel>
				<WhiteButton onPress={handleSharing} icon={<StyledShareIcon />}>
					{i18n.t('invite-my-friends')}
				</WhiteButton>
			</StyledBubbleInner>
			<StyledBubble />
		</StyledEmptyContainer>
	);
};
