import { MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import { ScreenHeader } from '@newstart/ui';
import { StyledHeaderContainer, StyledIconsContainer } from './styles';

type TeamMembersScreenProps = {
	title: string;
	onOpenMembersModal: () => void;
	onOpenSettings: () => void;
};

export const TeamScreenHeader: React.FunctionComponent<
	TeamMembersScreenProps
> = ({ title, onOpenMembersModal, onOpenSettings }) => {
	return (
		<StyledHeaderContainer>
			<ScreenHeader title={title} />
			<StyledIconsContainer>
				<TouchableOpacity onPress={onOpenMembersModal}>
					<MaterialCommunityIcons
						name="account-group"
						size={30}
						color="rgba(255,255,255,0.7)"
					/>
				</TouchableOpacity>
				<TouchableOpacity onPress={onOpenSettings}>
					<SimpleLineIcons
						name="settings"
						size={24}
						color="rgba(255,255,255,0.7)"
					/>
				</TouchableOpacity>
			</StyledIconsContainer>
		</StyledHeaderContainer>
	);
};
