import styled from 'styled-components/native';
import { ui } from '@newstart/ui';

// Intro

export const StyledIntroDetails = styled.View`
	padding: 20px;
	justify-content: center;
	align-items: center;
`;

export const StyledIntroDetailLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: ${ui.colors.light};
	opacity: 0.6;
	letter-spacing: -0.44px;
	text-transform: uppercase;
	margin-top: 10px;
`;

export const StyledIntroDetailValue = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 18px;
	color: ${ui.colors.light};
	letter-spacing: -0.35px;
	text-align: center;
	line-height: 24px;
	margin-bottom: 10px;
`;

// Choose

export const StyledButtonIconContainer = styled.View`
	width: 40px;
	height: 40px;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.2);
`;

export const StyledButtonsContainer = styled.View`
	padding: 20px 20px ${ui.bottomSpace + 10}px 20px;
	/* margin-top: auto; */
`;

// Form

export const StyledFormHeader = styled.View`
	justify-content: center;
	align-items: center;
	padding: 0 30px;
`;

export const StyledExerciseTypeIcon = styled.View`
	width: 42px;
	height: 42px;
	border-radius: 28px;
	justify-content: center;
	align-items: center;
	border: 1px solid ${ui.colors.dark};
	margin-bottom: 6px;
`;

export const StyledQuestion = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 18px;
	color: ${ui.colors.dark};
	letter-spacing: -0.55px;
	text-align: center;
	line-height: 20px;
`;

export const StyledQuestionStrong = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 18px;
	color: ${ui.colors.dark};
	letter-spacing: -0.55px;
	text-align: center;
	line-height: 20px;
	text-decoration: underline;
`;

export const StyledInput = styled.TextInput`
	font-family: ${ui.fonts.Inter.Light};
	font-size: 42px;
	color: ${ui.colors.dark};
	letter-spacing: -2.38px;
	text-align: center;
	padding: 2px;
	width: 100%;
	margin-bottom: 6px;
`;
