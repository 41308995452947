import React, { useCallback } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import moment from "moment-timezone"
import * as Localization from "expo-localization"
import {
  useAuth,
  parseDateToDatabase,
  parseDateFromDatabase,
} from "@newstart/auth"
import {
  BaseContainer,
  useMessage,
  Form,
  BaseModalContainer,
} from "@newstart/ui"
import { UpdateUserForm, UpdateUserFormValue } from "@newstart/onboarding"
import i18n from "i18n-js"
moment.locale(Localization.locale)

import { ProfileStackParamList } from "../../navigation"

type EditProfileModalProps = NativeStackScreenProps<
  ProfileStackParamList,
  "Profile_Notifications_Screen"
>

export const EditProfileScreen: React.FunctionComponent<
  EditProfileModalProps
> = ({ navigation }) => {
  const { showError, showSuccess } = useMessage()
  const { loading, user, updateUser } = useAuth()

  if (!user) return null

  const handleSubmit = useCallback(
    async (values: UpdateUserFormValue) => {
      try {
        await updateUser({
          ...values,
          birthDate: parseDateToDatabase(values.birthDate),
          timezone: Localization.timezone,
        })
        showSuccess("Updated")
        navigation.goBack()
      } catch (error) {
        showError(error)
      }
    },
    [showError]
  )

  if (loading) return null

  return (
    <BaseModalContainer
      onClose={() => navigation.goBack()}
      title={i18n.t("edit-profile")}
    >
      <Form>
        <UpdateUserForm
          onSubmit={handleSubmit}
          defaultValue={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            birthDate: parseDateFromDatabase(user.birthDate),
            country: user.country ?? "FR",
            gender: user.gender,
            username: user.username,
            phone: user.phone,
          }}
        />
      </Form>
    </BaseModalContainer>
  )
}
