import React from 'react';
import { StyleSheet } from 'react-native';
import { TextInputMask, TextInputMaskProps } from 'react-native-masked-text';
import { ui } from '../styles';
import { StyledFormInputContainer, StyledLabel } from './styles';

export type MaskInputProps = TextInputMaskProps & {
	label?: string;
	required?: boolean;
	error?: React.ReactNode;
};

export const MaskInput: React.FunctionComponent<MaskInputProps> = ({
	label,
	required,
	error,
	...props
}) => {
	return (
		<StyledFormInputContainer>
			{label && (
				<StyledLabel>
					{label}
					{required && '*'}
				</StyledLabel>
			)}
			<TextInputMask style={styles.input} {...props} />
			{error}
		</StyledFormInputContainer>
	);
};

const styles = StyleSheet.create({
	input: {
		paddingVertical: 14,
		paddingHorizontal: 18,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		borderRadius: 12,
		fontFamily: ui.fonts.Inter.Regular,
		fontSize: 17,
		color: ui.colors.dark,
	},
});
