import React from 'react';
import i18n from 'i18n-js';
import { Image, TouchableOpacity } from 'react-native';
import {
	StyledContainer,
	StyledText,
	StyledPointsText,
	StyledIconContainer,
} from './styles';
import { HabitIcon } from '@newstart/habits';
import { HabitIconName } from '@newstart/engine';
import { ui } from '@newstart/ui';

type HistoryItemProps = {
	label: string;
	borderColor: string;
	points: number;
	habitIconName?: HabitIconName;
	exerciseTypeIcon?: number;
	iconNode?: React.ReactNode;
	onPress: () => void;
};

export const HistoryItem: React.FunctionComponent<HistoryItemProps> = ({
	label,
	points,
	borderColor,
	habitIconName,
	exerciseTypeIcon,
	iconNode,
	onPress,
}) => {
	return (
		<TouchableOpacity onPress={onPress}>
			<StyledContainer>
				<StyledIconContainer borderColor={borderColor}>
					{iconNode ? (
						iconNode
					) : exerciseTypeIcon ? (
						<Image
							source={exerciseTypeIcon}
							style={{
								tintColor: ui.colors.dark,
								transform: [{ scale: 0.5 }],
							}}
						/>
					) : (
						habitIconName && (
							<HabitIcon
								name={habitIconName}
								size={18}
								color={ui.colors.dark}
							/>
						)
					)}
				</StyledIconContainer>
				<StyledText numberOfLines={1}>{label}</StyledText>
				<StyledPointsText>
					{i18n.toNumber(points, { precision: 0, delimiter: ' ' })}
				</StyledPointsText>
			</StyledContainer>
		</TouchableOpacity>
	);
};
