import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
	HabitsScreen,
	ExerciseInputModal,
	HabitIntroModal,
	BonusModal,
} from '../screens';
import { ExerciseType, Level } from '@newstart/engine';

const Stack = createNativeStackNavigator<HabitsStackParamList>();

export type HabitsStackParamList = {
	Habits_Screen: undefined;
	Habits_Intro_Modal: { habitId: string };
	Habits_Exercise_Input_Modal: {
		habitId: string;
		isEditing?: boolean;
		steps?: number;
		rawSteps?: number;
		exerciseType?: ExerciseType;
		level?: Level;
		achievementId?: string;
	};
	Habits_Bonus_Modal: {
		bonusNotificationId: string;
	};
};

export const HabitsNavigator = () => {
	return (
		<Stack.Navigator initialRouteName="Habits_Screen">
			<Stack.Group screenOptions={{ headerShown: false }}>
				<Stack.Screen
					name="Habits_Screen"
					component={HabitsScreen}
					options={{ title: 'Habits' }}
				/>
			</Stack.Group>
			<Stack.Group
				screenOptions={{ presentation: 'modal', headerShown: false }}
			>
				<Stack.Screen name="Habits_Intro_Modal" component={HabitIntroModal} />
				<Stack.Screen
					name="Habits_Exercise_Input_Modal"
					component={ExerciseInputModal}
				/>
				<Stack.Screen name="Habits_Bonus_Modal" component={BonusModal} />
			</Stack.Group>
		</Stack.Navigator>
	);
};
