import React from 'react';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { getHabitService } from '@newstart/engine';
import { BaseModalContainer, BlackButton, ui } from '@newstart/ui';
import i18n from 'i18n-js';

import { ModalHeading } from '../../components';
import {
	ExerciseInputModalAction,
	ExerciseInputModalState,
} from './exerciseInputReducer';
import { StyledButtonIconContainer, StyledButtonsContainer } from './styles';

type ExerciseInputIntroProps = {
	state: ExerciseInputModalState;
	dispatch: React.Dispatch<ExerciseInputModalAction>;
};

const exerciseTypes = getHabitService().getExerciseTypes();

export const ExerciseInputChoose: React.FunctionComponent<
	ExerciseInputIntroProps
> = ({ state, dispatch }) => {
	const { goBack } = useNavigation();

	const exercises = Object.values(exerciseTypes);

	return (
		<BaseModalContainer
			backgroundColor={['#75FF25', '#D8F83F']}
			onClose={() => goBack()}
			style={{
				justifyContent: 'space-between',
			}}
		>
			<ModalHeading
				icon="exercise"
				title={i18n.t('exercise')}
				subtitle={i18n.t('what-did-you-do-today')}
			/>
			<StyledButtonsContainer>
				{exercises.map((item) => (
					<BlackButton
						key={item.key}
						onPress={() =>
							dispatch({
								type: 'select_exercise_type',
								payload: { exerciseType: item },
							})
						}
						icon={
							<StyledButtonIconContainer>
								<Image
									source={item.icon}
									style={{
										tintColor: ui.colors.light,
										transform: [{ scale: 0.7 }],
									}}
								/>
							</StyledButtonIconContainer>
						}
						style={{
							height: 52,
							marginTop: 3,
							marginBottom: 3,
						}}
					>
						{i18n.t(`exercise-type-${item.key.toLowerCase()}`)}
					</BlackButton>
				))}
			</StyledButtonsContainer>
		</BaseModalContainer>
	);
};
