import styled from 'styled-components/native';
import { ui } from '@newstart/ui';

export const StyledNavigationHeader = styled.View`
	flex-direction: row;
	position: absolute;
	right: 10px;
	top: ${ui.statusBarHeight + 10}px;
	z-index: 1;
`;
