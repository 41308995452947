import React, { useState } from 'react';
import { Text } from 'react-native';
import i18n from 'i18n-js';

import { Input, PrimaryButton } from '@newstart/ui';

type CodeFormProps = {
	onRequestSubmit: (code: string) => void;
	loading: boolean;
};

export const CodeForm: React.FunctionComponent<CodeFormProps> = ({
	onRequestSubmit,
	loading,
}) => {
	const [code, setCode] = useState('');

	return (
		<>
			<Text style={{ marginBottom: 25 }}>
				{i18n.t('input-the-code-sent-to-your-email-address')}
			</Text>
			<Input
				label={i18n.t('input-code')}
				value={code}
				onChangeText={(text: string) => setCode(text)}
				autoCapitalize="none"
			/>

			<PrimaryButton
				onPress={() => onRequestSubmit(code)}
				loading={loading}
				disabled={!(code.length > 0)}
			>
				{i18n.t('verify-code')}
			</PrimaryButton>
		</>
	);
};
