import React from 'react';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import i18n from 'i18n-js';
import {
	PrimaryButton,
	Input,
	CountryInput,
	MaskInput,
	Radio,
	StyledError,
	PasswordInput,
} from '@newstart/ui';

type RegisterFormValue = {
	firstName: string;
	lastName: string;
	email: string;
	birthDate: string;
	phone?: string;
	country: string;
	gender: string;
	username: string;
	password: string;
	changepassword: string;
};

const registerValidationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'Must be at least 2 characters')
		.max(50, 'Must be less than 50 characters')
		.required('Required'),
	lastName: Yup.string()
		.min(2, 'Must be at least 2 characters')
		.max(50, 'Must be less than 50 characters')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	birthDate: Yup.string().min(8, 'Invalid date').required('Required'),
	country: Yup.string().required('Required'),
	username: Yup.string()
		.min(8, 'Must be at least 8 characters')
		.max(20, 'Must be less than 20 characters')
		.required('Required')
		.matches(/^[a-zA-Z0-9]+$/, 'Cannot contain special characters or spaces'),
	password: Yup.string()
		.min(6, 'Must be at least 6 characters')
		.required('Required'),
	changepassword: Yup.string()
		.required('Required')
		.when('password', {
			is: (val: string) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('password')],
				'Both password need to be the same'
			),
		}),
});

type RegisterFormProps = {
	defaultValue: RegisterFormValue;
	onSubmit: (values: RegisterFormValue) => void;
};

export const RegisterForm: React.FunctionComponent<RegisterFormProps> = ({
	defaultValue,
	onSubmit,
}) => {
	return (
		<Formik
			initialValues={defaultValue}
			validationSchema={registerValidationSchema}
			onSubmit={onSubmit}
			// initialTouched={{ password: true, changepassword: true }}
		>
			{({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
				<>
					<Input
						label={i18n.t('first_name')}
						value={values.firstName}
						onChangeText={handleChange('firstName')}
						onBlur={handleBlur('firstName')}
						autoCompleteType="name"
						textContentType="givenName"
						required
						error={<ErrorMessage component={StyledError} name="firstName" />}
					/>

					<Input
						label={i18n.t('last_name')}
						value={values.lastName}
						onChangeText={handleChange('lastName')}
						onBlur={handleBlur('lastName')}
						textContentType="familyName"
						required
						error={<ErrorMessage component={StyledError} name="lastName" />}
					/>

					<Input
						label={i18n.t('email_address')}
						value={values.email}
						onChangeText={handleChange('email')}
						onBlur={handleBlur('email')}
						autoCapitalize="none"
						autoCompleteType="email"
						keyboardType="email-address"
						textContentType="emailAddress"
						required
						error={<ErrorMessage component={StyledError} name="email" />}
					/>

					<MaskInput
						label={i18n.t('birthDate')}
						type="datetime"
						placeholder="DD/MM/YYYY"
						options={{ format: 'DD/MM/YYYY' }}
						value={values.birthDate}
						onChangeText={handleChange('birthDate')}
						onBlur={handleBlur('birthDate')}
						required
						error={<ErrorMessage component={StyledError} name="birthDate" />}
					/>

					<CountryInput
						label={i18n.t('country')}
						value={values.country}
						onChangeSelection={handleChange('country')}
						required
						error={<ErrorMessage component={StyledError} name="country" />}
					/>

					<MaskInput
						label={i18n.t('phone')}
						type="cel-phone"
						options={{ maskType: 'INTERNATIONAL' }}
						value={values.phone}
						onChangeText={handleChange('phone')}
						onBlur={handleBlur('phone')}
						error={<ErrorMessage component={StyledError} name="phone" />}
					/>

					<Radio
						label={i18n.t('gender')}
						defaultValue={values.gender}
						required
						options={[
							{ label: i18n.t('male'), value: 'M' },
							{ label: i18n.t('female'), value: 'F' },
						]}
						onChangeOption={handleChange('gender')}
					/>

					<Input
						label={i18n.t('username')}
						value={values.username}
						onChangeText={handleChange('username')}
						onBlur={handleBlur('username')}
						autoCapitalize="none"
						autoCompleteType="username"
						textContentType="familyName"
						required
						error={<ErrorMessage component={StyledError} name="username" />}
					/>

					<PasswordInput
						label={i18n.t('password')}
						value={values.password}
						onChangeText={handleChange('password')}
						onBlur={handleBlur('password')}
						required
						error={<ErrorMessage component={StyledError} name="password" />}
					/>

					<PasswordInput
						label={i18n.t('confirm-password')}
						value={values.changepassword}
						onChangeText={handleChange('changepassword')}
						onBlur={handleBlur('changepassword')}
						required
						error={
							<ErrorMessage component={StyledError} name="changepassword" />
						}
					/>

					<PrimaryButton onPress={handleSubmit} loading={isSubmitting}>
						{i18n.t('register')}
					</PrimaryButton>
				</>
			)}
		</Formik>
	);
};
