import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px 0 18px;
	margin-bottom: 10px;
`;

export const StyledPlaceText = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 14px;
	text-align: right;
	color: ${ui.colors.highlight};
	letter-spacing: -0.5px;
	flex-wrap: nowrap;
	margin-right: 8px;
`;

export const StyledInnerContainer = styled.View<{ isMe?: boolean }>`
	flex-grow: 1;
	flex-direction: row;
	padding: 10px 20px;
	border-radius: 9px;
	background-color: rgba(255, 255, 255, 0.15);
	border-width: ${(props) => (props.isMe ? '2px' : '0px')};
	border-color: ${(props) => (props.isMe ? ui.colors.light : 'transparent')};
	align-items: center;
	justify-content: space-between;
`;

export const StyledText = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 16px;
	color: ${ui.colors.light};
	letter-spacing: -0.44px;
	max-width: 220px;
`;

export const StyledPointsText = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 17px;
	color: ${ui.colors.light};
	letter-spacing: -0.44px;
	margin-left: auto;
`;
