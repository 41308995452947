import React, { useCallback, useEffect, useReducer } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useMessage } from '@newstart/ui';
import { useEngine } from '@newstart/engine';

import { HabitsStackParamList } from '../../navigation';
import { exerciseInputReducer } from './exerciseInputReducer';
import { ExerciseInputForm } from './ExerciseInput.Form';
import { ExerciseInputChoose } from './ExerciseInput.Choose';
import { ExerciseInputDone } from './ExerciseInput.Done';

type ExerciseInputModalProps = NativeStackScreenProps<
	HabitsStackParamList,
	'Habits_Exercise_Input_Modal'
>;

export const ExerciseInputModal: React.FunctionComponent<
	ExerciseInputModalProps
> = ({ navigation, route }) => {
	const { params } = route;
	const {
		isEditing,
		rawSteps: defaultRawSteps,
		steps: defaultSteps,
		exerciseType: defaultExerciseType,
		level: defaultLevel,
		achievementId,
	} = params;

	const { showError } = useMessage();
	const { addExercise, editExercise } = useEngine();

	const [state, dispatch] = useReducer(exerciseInputReducer, {
		status: 'ready',
		steps: 0,
		rawSteps: 0,
	});

	useEffect(() => {
		if (
			isEditing &&
			achievementId &&
			defaultRawSteps &&
			defaultSteps &&
			defaultExerciseType
		) {
			dispatch({
				type: 'set_editing_mode',
				payload: {
					steps: defaultSteps,
					rawSteps: defaultRawSteps,
					exerciseType: defaultExerciseType,
					elevation: defaultLevel ?? 'LOW',
					achievementId,
				},
			});
		}
	}, []);

	useEffect(() => {
		if (state.status === 'done') {
			setTimeout(() => {
				navigation.goBack();
			}, 1500);
		}
	}, [state.status]);

	const handleSubmit = useCallback(async () => {
		if (!state.exerciseType) return;
		try {
			dispatch({ type: 'saving' });
			if (!isEditing) {
				await addExercise({
					rawPoints: state.rawSteps,
					points: state.steps,
					level: state.elevation,
					exerciseType: state.exerciseType.key,
					habitId: params.habitId,
				});
			} else {
				await editExercise({
					achievementId: state.achievementId!,
					rawPoints: state.rawSteps,
					points: state.steps,
					level: state.elevation,
					exerciseType: state.exerciseType.key,
					habitId: params.habitId,
				});
			}
			dispatch({
				type: 'saved',
				payload: {
					steps: state.steps,
					elevation: state.elevation,
					exerciseType: state.exerciseType,
				},
			});
		} catch (error) {
			dispatch({ type: 'error' });
			showError(error);
		}
	}, [
		state.steps,
		state.elevation,
		state.exerciseType,
		params.habitId,
		dispatch,
		addExercise,
	]);

	if (state.status === 'ready') {
		return <ExerciseInputChoose state={state} dispatch={dispatch} />;
	}

	if (state.status === 'exercise_type_selected') {
		return (
			<ExerciseInputForm
				state={state}
				dispatch={dispatch}
				onSubmit={handleSubmit}
			/>
		);
	}

	if (state.status === 'done') {
		return <ExerciseInputDone state={state} dispatch={dispatch} />;
	}

	return null;
};
