import React from 'react';
import { ActivityIndicator, ViewProps } from 'react-native';
import {
	StyledArrow,
	StyledButton,
	StyledIconContainer,
	StyledLabel,
} from './styles';

import { ui } from '../styles';

export type BaseButtonProps = ViewProps & {
	loading?: boolean;
	disabled?: boolean;
	backgroundColor?: string;
	textSize?: number;
	textColor?: string;
	onPress: () => void;
	icon?: React.ReactNode;
};

export const BaseButton: React.FunctionComponent<BaseButtonProps> = ({
	loading,
	disabled,
	backgroundColor = ui.colors.primary,
	textSize = 18,
	textColor = ui.colors.light,
	children,
	icon,
	onPress,
	...props
}) => {
	return (
		<StyledButton
			onPress={() => (disabled ? false : onPress())}
			backgroundColor={backgroundColor}
			disabled={disabled}
			{...props}
		>
			{loading ? (
				<ActivityIndicator size="small" color={textColor} />
			) : (
				<>
					{icon && <StyledIconContainer>{icon}</StyledIconContainer>}
					<StyledLabel textSize={textSize} textColor={textColor}>
						{children}
					</StyledLabel>
					{icon && <StyledArrow />}
				</>
			)}
		</StyledButton>
	);
};
