import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Level } from '@newstart/engine';
import {
	StyledElevationContainer,
	StyledElevationButtonsContainer,
	StyledElevationLabel,
	StyledConversionInfo,
} from './styles';
import { ElevationButton } from './ElevationButton';

type ElevationInputProps = {
	defaultValue?: Level;
	multiplier?: {
		LOW: number;
		MEDIUM: number;
		HIGH: number;
		SUPERHIGH?: number;
	};
	multiplierDescriptions?: {
		LOW: string;
		MEDIUM: string;
		HIGH: string;
		SUPERHIGH?: string;
	};
	multiplierLabel?: string;
	converter?: number;
	unit: 'steps' | 'km' | 'min' | 'meters';
	onSetLevel?: (elevation: Level) => void;
};

export const ElevationInput: React.FunctionComponent<ElevationInputProps> = ({
	defaultValue,
	multiplier,
	multiplierDescriptions,
	multiplierLabel,
	onSetLevel,
	converter,
	unit,
}) => {
	if (!multiplier)
		return (
			<StyledConversionInfo>
				{i18n.t('each-step-is-worth-x-points', {
					count: converter,
					verb: i18n.t(unit, { count: 1 }),
				})}
			</StyledConversionInfo>
		);

	const [selectedLevel, setSelectedLevel] = useState<Level>(
		defaultValue || 'LOW'
	);

	useEffect(() => {
		onSetLevel?.(defaultValue || 'LOW');
	}, []);

	const handleSetLevel = useCallback(
		(level: Level) => {
			if (multiplier[level]) {
				setSelectedLevel(level);
				onSetLevel?.(level);
			}
		},
		[multiplier, onSetLevel, setSelectedLevel]
	);

	return (
		<StyledElevationContainer>
			<StyledElevationLabel>
				{multiplierLabel ? i18n.t(multiplierLabel) : i18n.t('elevation')}
			</StyledElevationLabel>
			<StyledElevationButtonsContainer>
				<ElevationButton
					onPress={() => handleSetLevel('LOW')}
					isDisabled={!multiplier.LOW}
					isSelected={selectedLevel === 'LOW'}
					description={multiplierDescriptions?.LOW}
					iconAsset={require('@newstart/assets/icons/elevation-low.png')}
				/>
				<ElevationButton
					onPress={() => handleSetLevel('MEDIUM')}
					isDisabled={!multiplier.MEDIUM}
					isSelected={selectedLevel === 'MEDIUM'}
					description={multiplierDescriptions?.MEDIUM}
					iconAsset={require('@newstart/assets/icons/elevation-medium.png')}
				/>
				<ElevationButton
					onPress={() => handleSetLevel('HIGH')}
					isDisabled={!multiplier.HIGH}
					isSelected={selectedLevel === 'HIGH'}
					description={multiplierDescriptions?.HIGH}
					iconAsset={require('@newstart/assets/icons/elevation-high.png')}
				/>
				<ElevationButton
					onPress={() => handleSetLevel('SUPERHIGH')}
					isDisabled={!multiplier.SUPERHIGH}
					isSelected={selectedLevel === 'SUPERHIGH'}
					description={multiplierDescriptions?.SUPERHIGH}
					iconAsset={require('@newstart/assets/icons/elevation-superhigh.png')}
				/>
			</StyledElevationButtonsContainer>

			<StyledConversionInfo>
				{i18n.t('each-step-is-worth-x-points', {
					count: multiplier[selectedLevel],
					verb: i18n.t(unit, { count: 1 }),
				})}
			</StyledConversionInfo>
		</StyledElevationContainer>
	);
};
