import { AuthToken } from '@newstart/auth';
import {
	AxiosHttpClient,
	API_URL,
	HttpClient,
	httpErrorHandler,
} from '@newstart/core';
import {
	Achievement,
	DTO,
	TeamChallengeAchievement,
	TeamHabitAchievement,
	TeamMemberDailyPerformance,
	UpdateDTO,
	UserChallengeAchievement,
	UserHabitAchievement,
	UserHabitDailyPerformance,
} from './types';

class AchievementsService {
	constructor(private _httpClient: HttpClient) {}

	private async _create(
		url: string,
		dto: DTO<Achievement>,
		token: AuthToken
	): Promise<Achievement> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.post<Achievement, DTO<Achievement>>(
					url,
					{ ...dto },
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	private async _createUserAchievement(
		dto: DTO<Achievement>,
		token: AuthToken
	): Promise<Achievement> {
		return this._create('/me/achievements', dto, token);
	}

	private async _createTeamAchievement(
		dto: DTO<Achievement>,
		token: AuthToken
	): Promise<Achievement> {
		return this._create('/team/achievements', dto, token);
	}

	async createUserHabitAchievement(
		dto: DTO<UserHabitAchievement>,
		token: AuthToken
	): Promise<Achievement> {
		return this._createUserAchievement(dto, token);
	}

	async createUserChallengeAchievement(
		dto: DTO<UserChallengeAchievement>,
		token: AuthToken
	): Promise<Achievement> {
		return this._createUserAchievement(dto, token);
	}

	async createTeamHabitAchievement(
		dto: DTO<TeamHabitAchievement>,
		token: AuthToken
	): Promise<Achievement> {
		return this._createTeamAchievement(dto, token);
	}

	async createTeamChallengeAchievement(
		dto: DTO<TeamChallengeAchievement>,
		token: AuthToken
	): Promise<Achievement> {
		return this._createTeamAchievement(dto, token);
	}

	async updateUserAchievement(
		achievementId: string,
		data: UpdateDTO,
		token: AuthToken
	): Promise<Achievement> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.put<Achievement, UpdateDTO>(
					`/me/achievements/${achievementId}`,
					data,
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async remove(achievementId: string, token: AuthToken): Promise<void> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.delete<void>(
					`/me/achievements/${achievementId}`,
					{},
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async findAll(token: AuthToken): Promise<FindAllReturn> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.get<FindAllReturn>('/me/achievements', {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async findByUserId(
		userId: string,
		token: AuthToken
	): Promise<FindByUserIdReturn> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.get<FindByUserIdReturn>(`/users/${userId}/achievements`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}
}

export const getAchievementsService = () => {
	return new AchievementsService(new AxiosHttpClient(API_URL));
};

type FindAllReturn = {
	achievements: Array<UserHabitAchievement | UserChallengeAchievement>;
	performance: UserHabitDailyPerformance[];
	teamAchievements: Array<UserHabitAchievement>;
	teamPerformance: Array<TeamMemberDailyPerformance>;
	userPoints: number;
	teamPoints: number;
};

type FindByUserIdReturn = Array<
	UserHabitAchievement | UserChallengeAchievement
>;
