import { Avatar } from '@newstart/ui';
import React, { useMemo } from 'react';
import {
	StyledChartContainer,
	StyledBar,
	StyledBarFill,
	StyledBarsContainer,
	StyledHeadingContainer,
	StyledLineContainer,
	StyleUsername,
} from './styles';

export type PeopleLineChartProps = {
	data: TeamMemberPerformanceData[];
	height?: number;
};

export type TeamMemberPerformanceData = {
	user: {
		id: string;
		username: string;
		firstName: string;
		lastName: string;
		photoUrl: string | null;
	};
	numbers: number[];
};

export const PeopleLineChart: React.FunctionComponent<PeopleLineChartProps> = ({
	data,
	height = 80,
}) => {
	const count = data.length;
	const days = 7;
	const fullDays: number[] = useMemo(() => {
		const result: number[] = [];
		for (let u = 0; u < days; u++) {
			let sum = 0;
			data.map(({ numbers }) => (sum += numbers[u]));
			if (sum >= count * 100) {
				result.push(u);
			}
		}
		return result;
	}, [count]);

	function resolveName(firstName: string, lastName: string, username: string) {
		return username ?? `${firstName} ${lastName}`;
	}

	return (
		<StyledChartContainer>
			{data.map((item, i) => {
				const first = i === 0;
				const last = i === data.length - 1;
				return (
					<StyledLineContainer key={`line.${i}`} height={height} last={last}>
						<StyledHeadingContainer>
							<Avatar size={42} photoUrl={item.user.photoUrl} />
							<StyleUsername numberOfLines={1}>
								{resolveName(
									item.user.firstName,
									item.user.lastName,
									item.user.username
								)}
							</StyleUsername>
						</StyledHeadingContainer>
						<StyledBarsContainer>
							{item.numbers.map((number, ni) => {
								const full = fullDays.includes(ni);
								return (
									<StyledBar
										key={`bar.${ni}`}
										first={first}
										last={last}
										full={full}
									>
										<StyledBarFill
											style={{
												height: `${number}%`,
											}}
											colors={
												full
													? ['transparent']
													: ['rgba(255,255,255,0.8)', 'rgba(255,255,255,0.3)']
											}
											first={first}
											last={last}
											full={full}
										/>
									</StyledBar>
								);
							})}
						</StyledBarsContainer>
					</StyledLineContainer>
				);
			})}
		</StyledChartContainer>
	);
};
