import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledContainer = styled.View`
	flex: 1;
	justify-content: center;
	align-items: center;
	max-height: 220px;
	padding: 0 30px;
`;

export const StyledIconContainer = styled.View`
	background-color: rgba(255, 255, 255, 0.3);
	width: 50px;
	height: 50px;
	border-radius: 25px;
	justify-content: center;
	align-items: center;
	margin-bottom: 6px;
`;

export const StyledTitle = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 30px;
	color: ${ui.colors.dark};
	letter-spacing: -1.21px;
	text-align: center;
	margin-bottom: 6px;
`;

export const StyledSubtitle = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 18px;
	color: ${ui.colors.dark};
	letter-spacing: -0.35px;
	text-align: center;
	line-height: 24px;
`;
