import React from "react";
import { View, Dimensions, ViewProps } from "react-native";
import Svg, { Circle } from "react-native-svg-web";
import { ui } from "@newstart/ui";
const { colors, width } = ui;

type Props = ViewProps & {
	percentage: number;
	radius?: number;
	strokeWidth?: number;
	duration?: number;
	color?: string;
	delay?: number;
	max?: number;
};

export const CircularProgress: React.FunctionComponent<Props> = ({
	percentage = 75,
	radius = (width - 60) / 2,
	strokeWidth = 40,
	duration = 500,
	color = colors.dark,
	delay = 0,
	max = 100,
	style,
	...props
}) => {
	const circleRef = React.useRef<any>();
	const circumference = 2 * Math.PI * radius;
	const halfCircle = radius + strokeWidth;

	const maxPerc = (100 * percentage) / max;
	const strokeDashoffset = circumference - (circumference * maxPerc) / 100;

	return (
		<View
			style={[
				{
					width: radius * 2,
					height: radius * 2,
					transform: [{ rotate: "-90deg" }],
				},
				style,
			]}
			{...props}
		>
			<Svg
				height={radius * 2}
				width={radius * 2}
				viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
			>
				<Circle
					cx="50%"
					cy="50%"
					r={radius}
					fill="transparent"
					stroke={"white"}
					strokeWidth={strokeWidth}
					strokeLinejoin="round"
					strokeDasharray="7"
					strokeOpacity="0.2"
				/>
				<Circle
					ref={circleRef}
					cx="50%"
					cy="50%"
					r={radius}
					fill="transparent"
					stroke={color}
					strokeWidth={strokeWidth}
					strokeLinecap="round"
					strokeDashoffset={strokeDashoffset}
					strokeDasharray={circumference}
				/>
			</Svg>
		</View>
	);
};
