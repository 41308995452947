import { useCallback, useEffect } from "react"
import { Alert, Platform } from "react-native"
import i18n from "i18n-js"
import * as Linking from "expo-linking"
import { LocalStorageClient } from "@newstart/core"
import moment from "moment-timezone"

const storageClient = new LocalStorageClient()
const DONATE_ALERT_KEY = "@newstart:donateAlert"
const DONATE_DONE_VALUE = "done"
const DONATE_NEVER_VALUE = "never"

export const useDonateAlert = () => {
  if (Platform.OS === "ios") return null

  const handleLater = useCallback(async () => {
    const laterDate = moment().add(10, "days").format("YYYY-MM-DD HH:mm:ss")
    await storageClient.set(DONATE_ALERT_KEY, laterDate)
  }, [])

  const handleCancel = useCallback(async () => {
    await storageClient.set(DONATE_ALERT_KEY, DONATE_NEVER_VALUE)
  }, [])

  const handleDonate = useCallback(async () => {
    await Linking.openURL("https://donorbox.org/newstart-80")
    await storageClient.set(DONATE_ALERT_KEY, DONATE_DONE_VALUE)
  }, [])

  const createThreeButtonAlert = () =>
    Alert.alert(i18n.t("donate-alert-title"), i18n.t("donate-alert-subtitle"), [
      {
        text: i18n.t("donate-alert-later"),
        onPress: handleLater,
      },
      {
        text: i18n.t("donate-alert-cancel"),
        onPress: handleCancel,
        style: "cancel",
      },
      {
        text: i18n.t("donate-alert-confirm"),
        onPress: handleDonate,
      },
    ])

  useEffect(() => {
    storageClient.get<string>(DONATE_ALERT_KEY).then((value) => {
      if (value) {
        if (value === DONATE_NEVER_VALUE || value === DONATE_DONE_VALUE) {
          return
        }

        const now = moment()
        const later = moment(value)
        if (now.isAfter(later)) {
          createThreeButtonAlert()
        }
      } else {
        createThreeButtonAlert()
      }
    })
  }, [])
}
