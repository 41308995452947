import {
	HttpClient,
	AxiosHttpClient,
	httpErrorHandler,
	API_URL,
} from '@newstart/core';
import { Team } from '@newstart/auth';
import { JoinTeamResponse, TeamServiceInterface } from './types';

export class TeamService implements TeamServiceInterface {
	constructor(private _httpClient: HttpClient) {}

	async create(name: string, token: string): Promise<Team> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.post<Team, { name: string }>(
					'/me/team',
					{ name },
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async join(inviteCode: string, token: string): Promise<JoinTeamResponse> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.post<JoinTeamResponse, { inviteCode: string }>(
					'/me/team/members',
					{ inviteCode },
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async leave(token: string): Promise<{ success: boolean }> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.delete<{ success: boolean }>(
					'/me/team/members',
					{},
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async update(
		teamId: string,
		data: Partial<Team>,
		token: string
	): Promise<Team> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.put<Team, Partial<Team>>(
					`/teams/${teamId}`,
					{ ...data },
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async delete(token: string): Promise<{ success: boolean }> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.delete<{ success: boolean }>(
					`/me/team`,
					{},
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async removeMember(
		teamId: string,
		userId: string,
		token: string
	): Promise<Team['members']> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.delete<Team['members'], { userId: string }>(
					`/teams/${teamId}/members`,
					{ userId },
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async getMembersByTeamId(
		teamId: string,
		token: string
	): Promise<GetMembersByTeamIdResponse> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.get<GetMembersByTeamIdResponse>(`/teams/${teamId}/members`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}
}

type GetMembersByTeamIdResponse = Array<{
	id: string;
	firstName: string;
	lastName: string;
	username: string | null;
	photoUrl: string | null;
	points: number;
}>;

export const getTeamService = () => {
	return new TeamService(new AxiosHttpClient(API_URL));
};
