import { useCallback, useState } from 'react';
import {
	BaseContainer,
	CTA,
	Form,
	Input,
	PrimaryButton,
	ScreenHeader,
	ScrollContainer,
} from '@newstart/ui';
import { Team } from '@newstart/auth';
import i18n from 'i18n-js';
import { StyledInfo } from './styles';
import { CreateTeamNavigation } from './CreateTeamScreen';

type CreatingProps = {
	navigation: CreateTeamNavigation;
	createTeam: (teamName: string) => Promise<void>;
	loading: boolean;
};

export const Creating: React.FunctionComponent<CreatingProps> = ({
	navigation,
	loading,
	createTeam,
}) => {
	const [teamName, setTeamName] = useState('');

	return (
		<BaseContainer>
			<Form>
				<StyledInfo>
					{i18n.t(
						'by-creating-a-team-you-are-committed-to-invite-6-more-friends-to-join'
					)}
				</StyledInfo>

				<Input
					label={i18n.t('name-the-team')}
					value={teamName}
					onChangeText={setTeamName}
				/>

				<PrimaryButton onPress={() => createTeam(teamName)} loading={loading}>
					{i18n.t('create-team')}
				</PrimaryButton>

				<CTA
					title={i18n.t('do-you-have-the-invitation-code')}
					ctaText={i18n.t('join-the-team')}
					onPress={() => navigation.replace('Onboarding_Join_Team_Modal')}
					style={{ marginTop: 20 }}
				/>
			</Form>
		</BaseContainer>
	);
};
