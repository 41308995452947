import React, { useMemo } from 'react';
import i18n from 'i18n-js';
import { HabitIcon } from '@newstart/habits';
import {
	getHabitService,
	Habit,
	HabitCategory,
	HabitCategoryKey,
	useEngine,
	UserHabitDailyPerformance,
} from '@newstart/engine';
import {
	StyledChartContainer,
	StyledBar,
	StyledBarFill,
	StyledBarsContainer,
	StyledHeadingContainer,
	StyledHeadingIcon,
	StyledHeadingText,
	StyledLineContainer,
} from './styles';

export type MultipleLinesChartProps = {
	height?: number;
};

export type HabitCategoryChartData = {
	category?: HabitCategory;
	numbers: number[];
	maxPoints: number[];
};

const habitCategories = getHabitService().getCategories();

export const MultipleLinesChart: React.FunctionComponent<
	MultipleLinesChartProps
> = ({ height = 80 }) => {
	const { habits, currentIndex, userPerformance } = useEngine();

	const data: HabitCategoryChartData[] = useMemo(() => {
		function getChartDays(currentIndex: number) {
			const days: number[] = [];
			for (let a = currentIndex; a >= currentIndex - 14; a--) {
				days.push(a);
			}
			return days.reverse();
		}

		const chartDays = getChartDays(currentIndex);

		return habitCategories.map((category) => {
			const numbers: number[] = [];
			const maxPoints: number[] = [];

			chartDays.map((i, ni) => {
				const maxCategoryPoints = maxPointsByHabitCategoryAtIndex(habits, i);
				const userPoints = sumPointsByHabitCategoryAtIndex(
					habits,
					userPerformance,
					i
				);
				numbers[ni] =
					(userPoints[category.key] / maxCategoryPoints[category.key]) * 100;
				maxPoints[ni] = maxCategoryPoints[category.key];
			});

			return {
				category,
				numbers,
				maxPoints,
			};
		});
	}, [userPerformance]);

	return (
		<StyledChartContainer>
			{data.map(
				({ numbers, maxPoints, category }, i) =>
					category && (
						<StyledLineContainer key={`line.${i}`} height={height}>
							<StyledHeadingContainer>
								<StyledHeadingIcon colors={category.colors}>
									<HabitIcon size={14} name={category.icon} />
								</StyledHeadingIcon>
								<StyledHeadingText style={{ color: category.colors[0] }}>
									{i18n.t(category.key)}
								</StyledHeadingText>
							</StyledHeadingContainer>
							<StyledBarsContainer>
								{numbers.map((number, ni) => (
									<StyledBar key={`bar.${ni}`} isDisabled={maxPoints[ni] === 0}>
										<StyledBarFill
											style={{ height: `${Math.min(100, number)}%` }}
											colors={category.colors}
										/>
									</StyledBar>
								))}
							</StyledBarsContainer>
						</StyledLineContainer>
					)
			)}
		</StyledChartContainer>
	);
};

/**
 * Soma quantos pontos máximos uma categoria de hábitos pode ter no index informado.
 * @param habits todos os hábitos
 * @param index index para filtrar hábitos ativos
 * @returns
 */
function maxPointsByHabitCategoryAtIndex(habits: Habit[], index: number) {
	const habitsByCategory: Record<HabitCategoryKey, number> = {
		EXERCISE: 0,
		NUTRITION: 0,
		SLEEP: 0,
		WALFARE: 0,
	};
	habits
		.filter((habit) => habit.startAt <= index)
		.map((habit) => {
			habitsByCategory[habit.category.key] += habit.points;
		});
	return habitsByCategory;
}

function getCategoryByHabitId(habits: Habit[], habitId: string) {
	const habitsByCategory: Record<string, HabitCategoryKey> = {};
	habits.map((habit) => (habitsByCategory[habit.id] = habit.category.key));
	return habitsByCategory[habitId];
}

/**
 * Soma dos pontos do usuário por categoria no index informado.
 * @param habits
 * @param userPerformance
 * @param index
 * @returns
 */
function sumPointsByHabitCategoryAtIndex(
	habits: Habit[],
	userPerformance: UserHabitDailyPerformance[],
	index: number
) {
	const habitsByCategory: Record<HabitCategoryKey, number> = {
		EXERCISE: 0,
		NUTRITION: 0,
		SLEEP: 0,
		WALFARE: 0,
	};
	userPerformance.map((performance) => {
		const categoryKey = getCategoryByHabitId(habits, performance.habitId);
		if (performance.createdAt === index) {
			habitsByCategory[categoryKey] += performance._sum.points;
		}
	});
	return habitsByCategory;
}
