import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledFormContainer = styled.View`
	flex: 1;
	padding: 10px 30px;
	width: 100%;
`;

export const StyledLabelCodeField = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 20px;
	color: #8e9aa4;
	letter-spacing: -0.55px;
	text-align: center;
`;
