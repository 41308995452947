import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageClient } from './storage';

export class LocalStorageClient implements StorageClient {
	async get<T>(key: string): Promise<T | null> {
		try {
			const value = await AsyncStorage.getItem(key);
			return value ? (JSON.parse(value) as T) : null;
		} catch (error: any) {
			throw new Error(error);
		}
	}

	async set<T>(key: string, data: T): Promise<void> {
		try {
			await AsyncStorage.setItem(key, JSON.stringify(data));
		} catch (error: any) {
			throw new Error(error);
		}
	}

	async multiDelete(keys: string[]): Promise<void> {
		try {
			await AsyncStorage.multiRemove(keys);
		} catch (error: any) {
			throw new Error(error);
		}
	}

	async delete(key: string): Promise<void> {
		try {
			await AsyncStorage.removeItem(key);
		} catch (error: any) {
			throw new Error(error);
		}
	}

	async clear(): Promise<void> {
		try {
			await AsyncStorage.clear();
		} catch (error: any) {
			throw new Error(error);
		}
	}
}
