import styled from 'styled-components/native';
import { ui } from '@newstart/ui';

export const StyledSubtitle = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: rgba(255, 255, 255, 0.5);
	letter-spacing: -0.44px;
	margin-top: 10px;
	margin-bottom: 10px;
`;
