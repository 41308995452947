import React from 'react';
import { TextProps } from 'react-native';
import { ThemedHeaderProps } from '../types';
import {
	StyledHeadingContainer,
	StyledSectionSubtitle,
	StyledSectionTitle,
} from './styles';

type SectionHeaderProps = TextProps &
	ThemedHeaderProps & {
		title: string;
		subtitle?: string;
	};

export const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
	title,
	subtitle,
	theme = 'dark',
	...props
}) => {
	return (
		<StyledHeadingContainer>
			<StyledSectionTitle theme={theme} {...props}>
				{title}
			</StyledSectionTitle>
			{subtitle && (
				<StyledSectionSubtitle theme={theme} {...props}>
					{subtitle}
				</StyledSectionSubtitle>
			)}
		</StyledHeadingContainer>
	);
};
