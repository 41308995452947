import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledScreenContainer = styled.View`
	flex: 1;
	justify-content: center;
`;

export const StyledSlide = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: 40px;
`;

export const StyledImage = styled.Image`
	width: 390px;
	height: 230px;
	margin: 32px 0;
`;

export const StyledTitle = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 20px;
	color: ${ui.colors.dark};
	text-align: center;
	margin-bottom: 18px;
`;

export const StyledText = styled.Text`
	color: ${ui.colors.dark};
	text-align: center;
	font-size: 16px;
`;

export const StyledButtonCircle = styled.View`
	width: 40px;
	height: 40px;
	background-color: ${ui.colors.secondary};
	border-radius: 20px;
	justify-content: center;
	align-items: center;
`;

export const StyledSkipText = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 16px;
	color: ${ui.colors.secondary};
	margin-top: 12px;
`;
