import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
// import { PusherProvider } from '@harelpls/use-pusher';
import { ui } from '@newstart/ui';
// import { API_URL } from '@newstart/core';
import { useAuth } from '@newstart/auth';

import { Screen } from '../../../Screen';
import { MembersModal, RenamingTeamModal, TeamScreen } from '../screens';

export type TeamStackParamList = {
	Team_Screen: undefined;
	Team_Members_Modal: undefined;
	Team_Renaming_Modal: undefined;
	Team_Challenge_Modal: { challengeId: string };
};

const Stack = createNativeStackNavigator<TeamStackParamList>();

export const TeamNavigator = () => {
	const { token, team } = useAuth();
	if (!token || !team) return null;

	return (
		// <PusherProvider
		// 	clientKey="01e7264feb0b725e05b7"
		// 	cluster="eu"
		// 	triggerEndpoint={`${API_URL}/teams/${team.id}/chat/trigger`}
		// 	authEndpoint={`${API_URL}/teams/${team.id}/chat/auth`}
		// 	auth={{ headers: { Authorization: `Bearer ${token}` } }}
		// >
		<Stack.Navigator>
			<Stack.Group screenOptions={{ headerShown: false }}>
				<Stack.Screen name="Team_Screen" component={TeamScreen} />
			</Stack.Group>
			<Stack.Group
				screenOptions={{
					presentation: 'modal',
					headerShown: false,
				}}
			>
				<Stack.Screen
					name="Team_Members_Modal"
					component={MembersModal}
					options={{
						title: 'Members',
					}}
				/>
				<Stack.Screen
					name="Team_Renaming_Modal"
					component={RenamingTeamModal}
					options={{
						title: 'Rename Team',
					}}
				/>
				<Stack.Screen name="Team_Challenge_Modal" component={Screen} />
			</Stack.Group>
		</Stack.Navigator>
		// </PusherProvider>
	);
};
