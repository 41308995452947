import { ui } from '@newstart/ui';
import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

export const StyledChallengeContainer = styled.View`
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	margin-left: 20px;
	overflow: hidden;
`;
export const StyledChallengeBackground = styled(LinearGradient).attrs({
	colors: ['#d8f83f', '#75ff25'],
	start: { x: 0.9, y: 0.2 },
	end: { x: 0, y: 1.3 },
})`
	padding: 20px;
`;

export const StyledChallengeTextBold = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: ${ui.colors.dark};
	letter-spacing: -0.36px;
`;

export const StyledChallengeTextRegular = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 16px;
	color: ${ui.colors.dark};
	letter-spacing: -0.31px;
	line-height: 22px;
	margin-top: 10px;
`;

export const StyledChallengeMetaContainer = styled.View`
	flex-direction: column;
	border-left-width: 2px;
	border-left-color: rgba(38, 27, 66, 0.2);
	padding-left: 14px;
	margin-top: 10px;
`;

export const StyledChallengeMetaLine = styled.View`
	flex-direction: row;
	margin: 4px 0;
	opacity: 0.5;
`;
