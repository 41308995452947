import {
	Habit,
	UserChallengeAchievement,
	UserHabitAchievement,
	UserHabitDailyPerformance,
} from '@newstart/engine';

export const filterDailyHabits = (selectedIndex: number, habits: Habit[]) => {
	return habits.filter((habit) => habit.startAt <= selectedIndex);
};

export const filterDailyPerformance = (
	selectedIndex: number,
	dailyHabits: Habit[],
	performance: UserHabitDailyPerformance[]
): UserHabitDailyPerformance[] => {
	return dailyHabits.map((habit) => {
		const habitPerformance = performance.find(
			(performance) =>
				performance.habitId === habit.id &&
				performance.createdAt === selectedIndex
		);

		if (habitPerformance) {
			return habitPerformance;
		}

		return {
			_sum: {
				points: 0,
			},
			createdAt: selectedIndex,
			habitId: habit.id,
		};
	});
};

export const isHabitAchievement = (
	achievement: UserHabitAchievement | UserChallengeAchievement
): achievement is UserHabitAchievement => {
	return (achievement as UserHabitAchievement).habitId !== undefined;
};
