import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Entypo } from '@expo/vector-icons';
import { ui } from '@newstart/ui';
const { colors, fonts } = ui;

export const StyledFormContainer = styled.View`
	flex: 1;
	padding: 15px;
	width: 100%;
	max-width: 340px;
`;

export const StyledInfo = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 14px;
	color: ${colors.dark};
	padding: 15px;
	text-align: center;
	margin-bottom: 20px;
`;
export const StyledLabelCodeField = styled.Text`
	font-family: ${fonts.Inter.SemiBold};
	font-size: 20px;
	color: #8e9aa4;
	letter-spacing: -0.55px;
	text-align: center;
`;
export const StyledTeamName = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 32px;
	color: ${colors.dark};
	letter-spacing: -0.88px;
	text-align: center;
	margin-bottom: 20px;
`;
export const StyledCodeText = styled.Text`
	font-family: ${fonts.Inter.Bold};
	color: ${colors.dark};
	text-align: center;
	font-size: 50px;
	letter-spacing: 12.6px;
	margin-bottom: 20px;
	padding: 40px 0;
`;

export const StyledShareButton = styled.TouchableOpacity`
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	background-color: ${colors.light};
	border-radius: 4px;
	width: 160px;
	margin: 10px auto;
`;

export const StyledButtonContainer = styled.TouchableHighlight`
	width: 100%;
	margin-bottom: 20px;
`;
export const StyledButtonBackground = styled(LinearGradient).attrs({
	colors: ['#0D00B1', '#13ADF2'],
	start: [0, 1],
	end: [1, 0],
})`
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	padding: 30px 100px 35px 20px;
`;
export const StyledButtonTitle = styled.Text`
	font-family: ${fonts.Inter.Bold};
	color: ${colors.dark};
	font-size: 22px;
	letter-spacing: -0.61px;
	margin-bottom: 8px;
`;

export const StyledButtonDescription = styled.Text`
	font-family: ${fonts.Inter.SemiBold};
	color: ${colors.dark};
	font-size: 14px;
	letter-spacing: -0.38px;
	line-height: 19px;
`;

export const StyledButtonIcon = styled(Entypo).attrs({
	name: 'chevron-right',
	size: 26,
	color: colors.dark,
})`
	position: absolute;
	right: 10px;
	top: 50px;
`;
