import { ui } from '@newstart/ui';

const { width } = ui;

export const SPACING = 10;
export const s = width * 0.4;
export const specs = {
	ITEM_WIDTH: s,
	ITEM_HEIGHT: s * 1.8,
	RADIUS: 14,
	SPACING,
	FULL_SIZE: s + SPACING * 2,
};
