import React, { useState } from 'react';
import { Text } from 'react-native';
import i18n from 'i18n-js';
import { Input, PrimaryButton } from '@newstart/ui';

type ResetFormProps = {
	onRequestSubmit: (email: string) => void;
	loading: boolean;
};

export const ResetForm: React.FunctionComponent<ResetFormProps> = ({
	onRequestSubmit,
	loading,
}) => {
	const [emailValue, setEmailValue] = useState('');

	return (
		<>
			<Text style={{ marginBottom: 25 }}>{i18n.t('reset-form-message')}</Text>

			<Input
				label={i18n.t('email_address')}
				value={emailValue}
				onChangeText={(text: string) => setEmailValue(text)}
				autoCapitalize="none"
				autoCompleteType="email"
				keyboardType="email-address"
				textContentType="emailAddress"
			/>

			<PrimaryButton
				onPress={() => onRequestSubmit(emailValue)}
				loading={loading}
			>
				{i18n.t('request-reset-code')}
			</PrimaryButton>
		</>
	);
};
