import React from 'react';
import CountryPicker, {
	Country,
	CountryCode,
} from 'react-native-country-picker-modal';
import { StyledFormInputContainer, StyledLabel, StyledError } from './styles';

export type CountryPickerProps = {
	value: string;
	onChangeSelection: (text: string) => void;
	label?: string;
	required?: boolean;
	error?: React.ReactNode;
};

export const CountryInput: React.FunctionComponent<CountryPickerProps> = ({
	value,
	onChangeSelection,
	label,
	required,
	error,
}) => {
	return (
		<StyledFormInputContainer>
			{label && (
				<StyledLabel>
					{label}
					{required && '*'}
				</StyledLabel>
			)}
			<CountryPicker
				countryCode={value as CountryCode}
				withFilter
				withFlag
				withCountryNameButton
				withEmoji
				modalProps={{ animationType: 'slide', presentationStyle: 'formSheet' }}
				// translation="por"
				onSelect={(country: Country) =>
					onChangeSelection(country.cca2 as string)
				}
				containerButtonStyle={{
					backgroundColor: 'rgba(0,0,0,0.05)',
					paddingLeft: 18,
					paddingRight: 18,
					paddingTop: 6,
					paddingBottom: 6,
					borderRadius: 12,
				}}
			/>
			{error}
		</StyledFormInputContainer>
	);
};
