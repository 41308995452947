import React, { useCallback, useMemo } from 'react';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { CompositeScreenProps, useNavigation } from '@react-navigation/native';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { AppTabsParamList } from 'navigation/types';
import i18n from 'i18n-js';
import {
	ScrollContainer,
	ScreenHeader,
	ScreenSection,
	StatusBar,
	ui,
	BaseContainer,
} from '@newstart/ui';
import { useEngine, Habit } from '@newstart/engine';
import { LocalStorageClient } from '@newstart/core';

import {
	DaySliderSelector,
	HabitsBubbleList,
	VideosCarousel,
} from '../../../components';
import { HabitsStackParamList } from '../../../navigation';
import { useDailyHabitState } from '../../../hooks';
import {
	StyledActivityIndicator,
	StyledMessage,
	StyledMessageText,
} from './styles';

type HabitsScreenProps = CompositeScreenProps<
	BottomTabScreenProps<AppTabsParamList, 'Tab_Habits'>,
	NativeStackScreenProps<HabitsStackParamList, 'Habits_Screen'>
>;

const localStorageClient = new LocalStorageClient();

export const HabitsScreenReady: React.FunctionComponent<HabitsScreenProps> = ({
	navigation,
}) => {
	const {
		status,
		selectedIndex,
		loading,
		habits,
		userPerformance,
		userAchievements,
		notifications,
		checkHabit,
		uncheckHabit,
	} = useEngine();

	const unreadNotifications = useMemo(
		() => notifications.filter((un) => un.readedAt === null),
		[notifications]
	);

	const { habitBubbles, minHeightBubblesContainer } = useDailyHabitState({
		loading,
		selectedIndex,
		habits,
		userPerformance,
		userAchievements,
	});

	const handleExerciseInputOpenModal = useCallback(
		async (habitId: string) => {
			navigation.navigate('Habits_Exercise_Input_Modal', {
				habitId,
			});
		},
		[selectedIndex]
	);

	const handleHabitCheck = useCallback(
		async (habitId: Habit['id'], achievementId?: string) => {
			if (await isHabitIntroduced(habitId)) {
				if (achievementId) {
					await uncheckHabit(achievementId);
				} else {
					await checkHabit(habitId);
				}
			} else {
				await setHabitAsIntroduced(habitId);
				navigation.navigate('Habits_Intro_Modal', {
					habitId,
				});
			}
		},
		[checkHabit, uncheckHabit]
	);

	if (status === 'prefetch') {
		return (
			<BaseContainer
				backgroundColor={['#30A5DA', '#0D00B1']}
				style={{ justifyContent: 'center', alignItems: 'center' }}
			>
				<ActivityIndicator size="large" color={ui.colors.light} />
			</BaseContainer>
		);
	}

	return (
		<ScrollContainer
			backgroundColor={['#30A5DA', '#0D00B1']}
			headingComponent={
				<>
					<ScreenHeader title={`${i18n.t('day')} ${selectedIndex + 1}`} />
					{status === 'fetching' && <StyledActivityIndicator />}
					<DaySliderSelector />
				</>
			}
			safeArea
		>
			{unreadNotifications.length > 0 && (
				<ScreenSection title={i18n.t('important-messages')}>
					<TouchableOpacity
						activeOpacity={0.8}
						onPress={() =>
							navigation.navigate('Tab_Profile', {
								screen: 'Profile_Notifications_Screen',
								initial: false,
							})
						}
					>
						<StyledMessage readed={false}>
							<StyledMessageText numberOfLines={2} readed={false}>
								{unreadNotifications[0].notification.title} -{' '}
								{unreadNotifications[0].notification.message}
							</StyledMessageText>
						</StyledMessage>
					</TouchableOpacity>
				</ScreenSection>
			)}

			<ScreenSection title={i18n.t('good-habits')}>
				<HabitsBubbleList
					habitBubbles={habitBubbles}
					minHeightBubblesContainer={minHeightBubblesContainer}
					onExerciseOpenModal={handleExerciseInputOpenModal}
					onHabitCheck={handleHabitCheck}
				/>
			</ScreenSection>

			<ScreenSection title={i18n.t('learn')}>
				<VideosCarousel data={habits.slice(1, 12)} />
			</ScreenSection>

			<StatusBar style="inverted" />
		</ScrollContainer>
	);
};

const habitKey = '@newstart:v2:habit_intros';

const isHabitIntroduced = async (habitId: string) => {
	const introducedHabits = await localStorageClient.get<string[]>(habitKey);
	return introducedHabits !== null && introducedHabits.includes(habitId);
};

const setHabitAsIntroduced = async (habitId: string) => {
	const introducedHabitsStoraged = await localStorageClient.get<string[]>(
		habitKey
	);
	const introducedHabits =
		introducedHabitsStoraged !== null ? introducedHabitsStoraged : [];

	await localStorageClient.set(habitKey, [...introducedHabits, habitId]);
};
