import React, { Fragment, useMemo, useState } from 'react';
import i18n from 'i18n-js';
import {
	ActivityIndicator,
	FlatList,
	ListRenderItem,
	Text,
	View,
} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
	CompositeNavigationProp,
	useNavigation,
} from '@react-navigation/native';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { AppTabsParamList } from '@newstart/navigation';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
	connectActionSheet,
	useActionSheet,
} from '@expo/react-native-action-sheet';
import { HabitsStackParamList } from '@newstart/habits';
import {
	formatDate,
	getHabitService,
	useEngine,
	ExerciseTypeKey,
} from '@newstart/engine';
import { HabitAchievementData, HistoryScreenItem } from './types';
import { HistoryItem } from './HistoryItem';
import { isChallengeAchievement, isHabitAchievement } from './utils';
import { StyledSubtitle } from './styles';
import { ui, useMessage } from '@newstart/ui';

const exerciseTypes = getHabitService().getExerciseTypes();

type HabitsScreenNavigationProp = CompositeNavigationProp<
	BottomTabNavigationProp<AppTabsParamList, 'Tab_Habits'>,
	NativeStackNavigationProp<HabitsStackParamList>
>;

export const HistoryList: React.FunctionComponent = connectActionSheet(() => {
	const { startDate, userAchievements, habits, loading, uncheckHabit } =
		useEngine();
	const navigation = useNavigation<HabitsScreenNavigationProp>();
	const { showActionSheetWithOptions } = useActionSheet();
	const { showError, showSuccess } = useMessage();

	// const [indices, setIndices] = useState<number[]>([]);

	const handleActionSheet = (achievement: HabitAchievementData['data']) => {
		if (!achievement.isBonus) {
			let options: string[];
			let destructiveButtonIndex: number;
			let cancelButtonIndex: number;

			if (achievement.exerciseType) {
				options = [
					i18n.t('edit'), // 0
					i18n.t('delete'), // 1
					i18n.t('cancel'), // 2
				];
				destructiveButtonIndex = 1;
				cancelButtonIndex = 2;
			} else {
				options = [
					i18n.t('delete'), // 0
					i18n.t('cancel'), // 1
				];
				destructiveButtonIndex = 0;
				cancelButtonIndex = 1;
			}

			showActionSheetWithOptions(
				{
					options,
					cancelButtonIndex,
					destructiveButtonIndex,
				},
				(buttonIndex) => {
					if (buttonIndex === 0) {
						if (achievement.exerciseType) {
							navigation.navigate('Habits_Exercise_Input_Modal', {
								isEditing: true,
								achievementId: achievement.id,
								habitId: achievement.habitId,
								steps: achievement.points,
								rawSteps: achievement.rawPoints,
								exerciseType: exerciseTypes[achievement.exerciseType!],
								level: achievement.level,
							});
						} else {
							handleDeleting(achievement.id);
						}
					} else if (buttonIndex === 1) {
						if (achievement.exerciseType) {
							handleDeleting(achievement.id);
						}
					}
				}
			);
		}
	};

	const handleDeleting = (achievementId: string) => {
		let options = [
			i18n.t('confirm'), // 0
			i18n.t('cancel'), // 1
		];
		let destructiveButtonIndex = 0;
		let cancelButtonIndex = 1;

		showActionSheetWithOptions(
			{
				options,
				cancelButtonIndex,
				destructiveButtonIndex,
				title: i18n.t('are-you-sure'),
				message: i18n.t('this-action-cannot-be-undone'),
			},
			async (buttonIndex) => {
				if (buttonIndex === 0) {
					try {
						await uncheckHabit(achievementId);
					} catch (error) {
						showError(error);
					}
				}
			}
		);
	};

	const { data } = useMemo(() => {
		const items: HistoryScreenItem[] = [];

		userAchievements.map((achievement) => {
			if (
				!items.some(
					(item) =>
						item.format === 'subtitle' &&
						item.data.createdAt === achievement.createdAt
				)
			) {
				items.push({
					key: `subtitle${achievement.createdAt}`,
					format: 'subtitle',
					data: { createdAt: achievement.createdAt },
				});
			}

			if (isHabitAchievement(achievement)) {
				const habit =
					habits.find((habit) => habit.id === achievement.habitId) ?? habits[0];

				items.push({
					key: achievement.id,
					format: 'habitAchievement',
					data: {
						id: achievement.id,
						rawPoints: achievement.rawPoints,
						points: achievement.points,
						description: parseDescription(
							achievement.rawPoints,
							habit.bubbleMessage,
							achievement.exerciseType
						),
						habitIconName: habit.icon,
						exerciseType: achievement.exerciseType,
						exerciseTypeIcon: achievement.exerciseType
							? exerciseTypes[achievement.exerciseType].icon
							: undefined,
						borderColor: habit.category.colors[0],
						isBonus: achievement.isBonus,
						habitId: achievement.habitId,
						level: achievement.level,
					},
				});
			} else if (isChallengeAchievement(achievement)) {
				items.push({
					key: achievement.id,
					format: 'challengeAchievement',
					data: {
						id: achievement.id,
						points: achievement.points,
						description: 'bonus de défi complet',
						borderColor: ui.colors.light,
						isBonus: achievement.isBonus,
						challengeId: achievement.challengeId,
					},
				});
			}
		});

		// let indicesList: number[] = [];
		// items.forEach(
		// 	(item, index) => item.format === 'subtitle' && indicesList.push(index)
		// );
		// setIndices(indicesList);

		return { data: items };
	}, [userAchievements]);

	const renderItem: ListRenderItem<HistoryScreenItem> = ({ item }) => {
		const { format } = item;
		if (format === 'habitAchievement')
			return (
				<HistoryItem
					key={item.key}
					onPress={() => handleActionSheet(item.data)}
					label={item.data.description}
					points={item.data.points}
					borderColor={item.data.borderColor}
					habitIconName={item.data.habitIconName}
					exerciseTypeIcon={item.data.exerciseTypeIcon}
				/>
			);

		if (format === 'challengeAchievement')
			return (
				<HistoryItem
					key={item.key}
					onPress={() => {}}
					label={item.data.description}
					points={item.data.points}
					borderColor={item.data.borderColor}
					iconNode={
						<MaterialCommunityIcons
							name="medal"
							size={24}
							color={ui.colors.dark}
						/>
					}
				/>
			);

		if (format === 'subtitle')
			return (
				<StyledSubtitle>
					{formatDate(startDate, item.data.createdAt, 'DD/MM')}
				</StyledSubtitle>
			);

		return <Fragment />;
	};

	if (loading)
		return (
			<View
				style={{ padding: 20, justifyContent: 'center', alignItems: 'center' }}
			>
				<ActivityIndicator size="small" color={ui.colors.light} />
			</View>
		);

	return (
		<FlatList<HistoryScreenItem>
			data={data}
			renderItem={renderItem}
			keyExtractor={(item) => item.key}
			// stickyHeaderIndices={indices}
			contentInsetAdjustmentBehavior="automatic"
			style={{
				width: '100%',
				flexGrow: 0,
			}}
			contentContainerStyle={{
				paddingLeft: 20,
				paddingRight: 20,
			}}
			ListFooterComponent={<View style={{ height: 380 }} />}
		/>
	);
});

const parseDescription = (
	rawPoints: number,
	defaultMessage: string,
	exerciseType?: ExerciseTypeKey
): string => {
	const points = i18n.toNumber(rawPoints, { precision: 0, delimiter: ' ' });

	switch (exerciseType) {
		case 'WALKING':
			return i18n.t('walked-x-steps', { points, count: points });
			break;

		case 'RUNNING':
			return i18n.t('ran-x-steps', { points, count: points });
			break;

		case 'CYCLING':
			return i18n.t('pedaled-x-kilometers', { points, count: points });
			break;

		case 'ELETRIC_CYCLING':
			return i18n.t('pedaled-x-kilometers', { points, count: points });
			break;

		case 'SWIMMING':
			return i18n.t('swam-x-meters', { points, count: points });
			break;

		case 'OTHER':
			return i18n.t('other-x-minutes', { points, count: points });
			break;

		case 'SNOWSHOE':
			return i18n.t('walked-x-steps', { points, count: points });
			break;

		default:
			return defaultMessage;
			break;
	}
};
