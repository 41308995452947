import moment from 'moment-timezone';
import * as Localization from 'expo-localization';
moment.locale(Localization.locale);

export function parseDateToDatabase(date: string) {
	return moment(date, 'DD/MM/YYYY').toISOString();
}

export function parseDateFromDatabase(date: Date, format?: string) {
	return moment.utc(date).format(format ?? 'DD/MM/YYYY');
}
