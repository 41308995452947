import React, { useCallback } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import i18n from 'i18n-js';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '@newstart/auth';
import {
	Form,
	Input,
	useMessage,
	StyledError,
	BaseModalContainer,
	WhiteButton,
	StatusBar,
} from '@newstart/ui';
import { TeamStackParamList } from '../../navigation';

type RenamingTeamModalProps = NativeStackScreenProps<
	TeamStackParamList,
	'Team_Renaming_Modal'
>;

export const RenamingTeamModal: React.FunctionComponent<
	RenamingTeamModalProps
> = ({ navigation }) => {
	const { team, renameTeam } = useAuth();
	const { showError } = useMessage();

	const onSubmit = useCallback(
		async (values) => {
			try {
				await renameTeam(values.teamName);
			} catch (error) {
				showError(error);
			} finally {
				navigation.goBack();
			}
		},
		[renameTeam]
	);

	return (
		<BaseModalContainer
			backgroundColor={['#4E3980', '#2A1165']}
			onClose={() => navigation.goBack()}
			title={i18n.t('rename-team')}
			dark
		>
			<StatusBar style="auto" />
			<Form>
				<Formik
					initialValues={{
						teamName: team?.name,
					}}
					validationSchema={Yup.object().shape({
						teamName: Yup.string().required('Required'),
					})}
					onSubmit={onSubmit}
				>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						values,
						isSubmitting,
					}) => (
						<>
							<Input
								label={i18n.t('edit-team-name')}
								value={values.teamName}
								onChangeText={handleChange('teamName')}
								onBlur={handleBlur('teamName')}
								autoFocus
								autoCapitalize="none"
								required
								error={<ErrorMessage component={StyledError} name="teamName" />}
								dark
							/>
							<WhiteButton onPress={handleSubmit} loading={isSubmitting}>
								{i18n.t('rename-team')}
							</WhiteButton>
						</>
					)}
				</Formik>
			</Form>
		</BaseModalContainer>
	);
};
