import styled from "styled-components/native"
import { LinearGradient } from "expo-linear-gradient"
import { AntDesign } from "@expo/vector-icons"
import { ui } from "../../styles"

export const StyledBaseContainer = styled.View<{
  safeArea?: boolean
  backgroundColor?: string
}>`
  flex: 1;
  width: 100%;
  padding-top: ${({ safeArea }) => (safeArea ? ui.statusBarHeight : "0")}px;
  justify-content: flex-start;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ui.colors.light};
`

export const StyledGradientContainer = styled(LinearGradient)<{
  safeArea?: boolean
}>`
  flex: 1;
  width: 100%;
  padding-top: ${({ safeArea }) => (safeArea ? ui.statusBarHeight : "0")}px;
  justify-content: flex-start;
  position: relative;
`

export const StyledImageBackgroundContainer = styled.ImageBackground<{
  safeArea?: boolean
}>`
  flex: 1;
  width: 100%;
  padding-top: ${({ safeArea }) => (safeArea ? ui.statusBarHeight : "0")}px;
  justify-content: flex-start;
  /* background-position: top center; */
`

export const StyledHeader = styled.View`
  height: 60px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
`

export const StyledScrollContent = styled.View`
  flex: 1;
  width: 100%;
  max-width: ${ui.maxWidth}px;
  margin: auto;
  padding-top: 20px;
`

export const StyledCloseContainer = styled.TouchableOpacity`
  width: 42px;
  height: 42px;
  position: absolute;
  left: 5px;
  top: 2px;
  justify-content: center;
  align-items: center;
  z-index: 5;
`

export const StyledCloseIcon = styled(AntDesign).attrs({
  name: "close",
  size: 24,
})`
  z-index: 5;
`

export const StyledTitleModalContainer = styled.View`
  padding: 0 30px 20px 30px;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const StyledTitleModalText = styled.Text<{
  dark?: boolean
  titleColor?: string
}>`
  font-family: ${ui.fonts.Inter.Bold};
  font-size: 26px;
  color: ${(props) =>
    props.dark
      ? ui.colors.light
      : props.titleColor
      ? props.titleColor
      : ui.colors.primary};
  letter-spacing: -0.4px;
`
