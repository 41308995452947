import React from 'react';
import i18n from 'i18n-js';
import { Platform } from 'react-native';
import {
	StyledHeader,
	StyledStatsHeader,
	StyledName,
	StyledTeam,
	StyledTeamName,
	StyledShareButton,
	StyledShareButtonText,
	StyledShareWebText,
	StyledStats,
	StyledStatsLabel,
	StyledStatsValue,
	StyledProfilePictureContainer,
	StyledProfilePicture,
} from './styles';
import { AvatarUpload } from '../AvatarUpload';
import { Feather } from '@expo/vector-icons';
import { useAuth } from '@newstart/auth';

type ProfileHeaderProps = {
	userPoints: string;
	userTeamPoints: string;
	onRequestShare: () => void;
};
export const ProfileHeader: React.FunctionComponent<ProfileHeaderProps> = ({
	userPoints,
	userTeamPoints,
	onRequestShare,
}) => {
	const { user } = useAuth();
	if (!user) return null;

	return (
		<StyledHeader>
			<StyledStatsHeader>
				<StyledStats>
					<StyledStatsLabel>{i18n.t('your-points')}</StyledStatsLabel>
					<StyledStatsValue>{userPoints}</StyledStatsValue>
				</StyledStats>

				<StyledProfilePictureContainer>
					<StyledProfilePicture
						source={require('@newstart/assets/img/avatar-placeholder.png')}
					>
						<AvatarUpload user={user} />
					</StyledProfilePicture>
				</StyledProfilePictureContainer>

				<StyledStats>
					<StyledStatsLabel>{i18n.t('your-team')}</StyledStatsLabel>
					<StyledStatsValue>{userTeamPoints}</StyledStatsValue>
				</StyledStats>
			</StyledStatsHeader>
			<StyledName>
				{user.firstName} {user.lastName}
			</StyledName>
			<StyledTeam>
				<StyledTeamName>{user.team?.name}</StyledTeamName>
				{Platform.OS === 'web' ? (
					<StyledShareWebText>
						{i18n.t('invite-my-friends')}: {user.team?.inviteCode}
					</StyledShareWebText>
				) : (
					<StyledShareButton onPress={onRequestShare}>
						<Feather name="share" size={14} color="black" />
						<StyledShareButtonText>
							{i18n.t('invite-my-friends')}
						</StyledShareButtonText>
					</StyledShareButton>
				)}
			</StyledTeam>
		</StyledHeader>
	);
};
