import React from 'react';
import { ImageBackgroundProps } from 'react-native';

import { StyledAvatarContainer, StyledAvatarImage } from './styles';

type AvatarProps = Omit<ImageBackgroundProps, 'source'> & {
	photoUrl: string | null;
	size?: number;
};

export const Avatar: React.FunctionComponent<AvatarProps> = ({
	photoUrl,
	size = 115,
	...props
}) => {
	return (
		<StyledAvatarContainer
			source={require('@newstart/assets/img/avatar-placeholder.png')}
			size={size}
			{...props}
		>
			{photoUrl !== null && (
				<StyledAvatarImage source={{ uri: photoUrl }} size={size} />
			)}
		</StyledAvatarContainer>
	);
};
