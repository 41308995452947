import React, { useCallback } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import i18n from "i18n-js"
import {
  BaseContainer,
  PrimaryButton,
  useMessage,
  CTA,
  Form,
  Input,
  StyledError,
  StatusBar,
  PasswordInput,
} from "@newstart/ui"

import { useAuth } from "../../contexts/useAuth"
import { AuthStackParamList } from "../../navigation/AuthNavigator"

type LoginScreenProps = NativeStackScreenProps<
  AuthStackParamList,
  "Auth_Login_Screen"
>

export const LoginScreen: React.FunctionComponent<LoginScreenProps> = ({
  navigation,
}) => {
  const { signIn } = useAuth()

  const onSubmit = useCallback(
    async (values) => {
      await signIn(values.email, values.password)
    },
    [signIn]
  )

  return (
    <BaseContainer>
      <StatusBar style="dark" />
      <Form>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Invalid email").required("Required"),
            password: Yup.string().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isSubmitting,
            errors,
          }) => (
            <>
              <Input
                label={i18n.t("email_address")}
                value={values.email}
                onChangeText={handleChange("email")}
                onBlur={handleBlur("email")}
                autoCapitalize="none"
                autoCompleteType="email"
                keyboardType="email-address"
                textContentType="emailAddress"
                required
                error={<ErrorMessage component={StyledError} name="email" />}
              />

              <PasswordInput
                label={i18n.t("password")}
                value={values.password}
                onChangeText={handleChange("password")}
                onBlur={handleBlur("password")}
                required
                error={<ErrorMessage component={StyledError} name="password" />}
              />

              <CTA
                title={i18n.t("forgot_password")}
                ctaText={i18n.t("recover-password")}
                onPress={() =>
                  navigation.navigate("Auth_Forgot_Password_Screen")
                }
                style={{ marginTop: 15, marginBottom: 20 }}
              />

              <PrimaryButton onPress={handleSubmit} loading={isSubmitting}>
                {i18n.t("login")}
              </PrimaryButton>
            </>
          )}
        </Formik>

        <CTA
          title={i18n.t("dont_have_an_account")}
          ctaText={i18n.t("register")}
          onPress={() => navigation.replace("Auth_Register_Screen")}
          style={{ marginTop: 15 }}
        />
      </Form>
    </BaseContainer>
  )
}
