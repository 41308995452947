import React, { useState, useEffect } from 'react';
import RadioForm, {
	RadioButton,
	RadioButtonInput,
	RadioButtonLabel,
} from 'react-native-simple-radio-button';
import { StyledFormInputContainer, StyledLabel } from './styles';
import { ui } from '../styles';
const { fonts, colors } = ui;

type RadioProps = {
	options: Array<{ label: string; value: string }>;
	defaultValue?: string;
	onChangeOption: (value: string) => void;
	label?: string;
	required?: boolean;
	error?: React.ReactNode;
};

export const Radio: React.FunctionComponent<RadioProps> = ({
	options,
	defaultValue,
	onChangeOption,
	label,
	required,
	error,
}) => {
	const [indexSeleted, setIndexSelected] = useState(0);

	useEffect(() => {
		if (defaultValue) {
			let i = options.findIndex((x) => x.value === defaultValue);
			setIndexSelected(i);
			onChangeOption(options[i].value);
		} else {
			onChangeOption(options[0].value);
		}
	}, []);

	const handlePress = (value: string) => {
		setIndexSelected(options.map((item) => item.value).indexOf(value));
		onChangeOption(value);
	};

	return (
		<StyledFormInputContainer>
			{label && (
				<StyledLabel>
					{label}
					{required && '*'}
				</StyledLabel>
			)}

			<RadioForm formHorizontal={true} animation={true} initial={0}>
				{options.map((obj, i) => (
					<RadioButton labelHorizontal={true} key={i}>
						<RadioButtonInput
							obj={obj}
							index={i}
							isSelected={indexSeleted === i}
							onPress={handlePress}
							buttonInnerColor={colors.primary}
							buttonOuterColor={
								indexSeleted === i ? colors.primary : colors.dark
							}
							buttonSize={26}
							buttonOuterSize={40}
							buttonStyle={{ borderWidth: 1 }}
							// buttonWrapStyle={{marginLeft: 10}}
						/>
						<RadioButtonLabel
							obj={obj}
							index={i}
							labelHorizontal={true}
							onPress={handlePress}
							labelStyle={{
								fontFamily: fonts.Inter.Regular,
								fontSize: 17,
								color: colors.dark,
							}}
							labelWrapStyle={{ marginRight: 20 }}
						/>
					</RadioButton>
				))}
			</RadioForm>

			{error}
		</StyledFormInputContainer>
	);
};
