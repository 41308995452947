import styled from 'styled-components/native';

export const StyledHeaderContainer = styled.View``;

export const StyledIconsContainer = styled.View`
	position: absolute;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70px;
	top: 8px;
	right: 30px;
`;
