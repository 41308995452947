import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, ErrorMessage, FormikHelpers } from 'formik';
import i18n from 'i18n-js';
import {
	PrimaryButton,
	Input,
	CountryInput,
	MaskInput,
	Radio,
	StyledError,
} from '@newstart/ui';
import { useAuth } from '@newstart/auth';

export type UpdateUserFormValue = {
	firstName: string;
	lastName: string;
	email: string;
	birthDate: string;
	phone?: string;
	country: string;
	gender: string;
	username: string;
};

const registerValidationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'Must be at least 2 characters')
		.max(50, 'Must be less than 50 characters')
		.required('Required'),
	lastName: Yup.string()
		.min(2, 'Must be at least 2 characters')
		.max(50, 'Must be less than 50 characters')
		.required('Required'),
	birthDate: Yup.string().min(8, 'Invalid date').required('Required'),
	country: Yup.string().required('Required'),
	username: Yup.string()
		.min(8, 'Must be at least 8 characters')
		.max(20, 'Must be less than 20 characters')
		.required('Required')
		.matches(/^[a-zA-Z0-9]+$/, 'Cannot contain special characters or spaces'),
});

type UpdateUserFormProps = {
	defaultValue: UpdateUserFormValue;
	onSubmit: (values: UpdateUserFormValue) => Promise<void>;
};

export const UpdateUserForm: React.FunctionComponent<UpdateUserFormProps> = ({
	defaultValue,
	onSubmit,
}) => {
	const { loading } = useAuth();

	if (loading) return null;

	const handleSubmit = useCallback(
		async (
			values: UpdateUserFormValue,
			helpers: FormikHelpers<UpdateUserFormValue>
		) => {
			if (values.birthDate.length < 8 || values.birthDate === 'Invalid date') {
				helpers.setFieldError('birthDate', 'Required');
				return;
			}
			return await onSubmit(values);
		},
		[]
	);

	return (
		<Formik
			initialValues={defaultValue}
			validationSchema={registerValidationSchema}
			onSubmit={handleSubmit}
		>
			{({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
				<>
					<Input
						label={i18n.t('first_name')}
						value={values.firstName}
						onChangeText={handleChange('firstName')}
						onBlur={handleBlur('firstName')}
						autoCompleteType="name"
						textContentType="givenName"
						required
						error={<ErrorMessage component={StyledError} name="firstName" />}
					/>

					<Input
						label={i18n.t('last_name')}
						value={values.lastName}
						onChangeText={handleChange('lastName')}
						onBlur={handleBlur('lastName')}
						textContentType="familyName"
						required
						error={<ErrorMessage component={StyledError} name="lastName" />}
					/>

					<Input
						label={i18n.t('email_address')}
						value={values.email}
						onChangeText={handleChange('email')}
						onBlur={handleBlur('email')}
						autoCapitalize="none"
						autoCompleteType="email"
						keyboardType="email-address"
						textContentType="emailAddress"
						editable={false}
						required
						error={<ErrorMessage component={StyledError} name="email" />}
					/>

					<MaskInput
						label={i18n.t('birthDate')}
						type="datetime"
						placeholder="DD/MM/YYYY"
						options={{ format: 'DD/MM/YYYY' }}
						value={values.birthDate}
						onChangeText={handleChange('birthDate')}
						onBlur={handleBlur('birthDate')}
						required
						error={<ErrorMessage component={StyledError} name="birthDate" />}
					/>

					<CountryInput
						label={i18n.t('country')}
						value={values.country}
						onChangeSelection={handleChange('country')}
						required
						error={<ErrorMessage component={StyledError} name="country" />}
					/>

					<MaskInput
						label={i18n.t('phone')}
						type="cel-phone"
						options={{ maskType: 'INTERNATIONAL' }}
						value={values.phone}
						onChangeText={handleChange('phone')}
						onBlur={handleBlur('phone')}
						error={<ErrorMessage component={StyledError} name="phone" />}
					/>

					<Radio
						label={i18n.t('gender')}
						defaultValue={values.gender}
						required
						options={[
							{ label: i18n.t('male'), value: 'M' },
							{ label: i18n.t('female'), value: 'F' },
						]}
						onChangeOption={handleChange('gender')}
					/>

					<Input
						label={i18n.t('username')}
						value={values.username}
						onChangeText={handleChange('username')}
						onBlur={handleBlur('username')}
						autoCapitalize="none"
						autoCompleteType="username"
						textContentType="familyName"
						required
						error={<ErrorMessage component={StyledError} name="username" />}
					/>

					<PrimaryButton
						onPress={handleSubmit}
						loading={isSubmitting}
						disabled={isSubmitting}
					>
						{i18n.t('save')}
					</PrimaryButton>
				</>
			)}
		</Formik>
	);
};
