import React, { useCallback, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
	CodeField,
	useBlurOnFulfill,
	useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import i18n from 'i18n-js';
import {
	BaseContainer,
	useMessage,
	PrimaryButton,
	CTA,
	ui,
	Form,
} from '@newstart/ui';
import { useAuth } from '@newstart/auth';

import { OnboardingStackParamList } from '../../navigation';
import { StyledLabelCodeField } from './styles';

const { colors, fonts } = ui;

const CELL_COUNT = 5;

type JoinTeamScreenProps = NativeStackScreenProps<
	OnboardingStackParamList,
	'Onboarding_Walkthrough_Screen'
>;

export const JoinTeamScreen: React.FunctionComponent<JoinTeamScreenProps> = ({
	navigation,
}) => {
	const { showError } = useMessage();
	const { joinTeam } = useAuth();

	const [value, setValue] = useState('');
	const [loading, setLoading] = useState(false);

	const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
	const [props, getCellOnLayoutHandler] = useClearByFocusCell({
		value,
		setValue,
	});

	const onSubmit = useCallback(async () => {
		setLoading(true);
		try {
			await joinTeam(value);
		} catch (error) {
			showError(error);
		}
	}, [value, showError, joinTeam]);

	return (
		<BaseContainer safeArea>
			<Form>
				<StyledLabelCodeField>{i18n.t('invite-code')}</StyledLabelCodeField>

				<CodeField
					ref={ref}
					{...props}
					value={value}
					onChangeText={setValue}
					cellCount={CELL_COUNT}
					rootStyle={styles.codeFieldRoot}
					// textContentType="oneTimeCode"
					autoCapitalize="none"
					renderCell={({ index, symbol, isFocused }) => (
						<View
							key={index}
							onLayout={getCellOnLayoutHandler(index)}
							style={[styles.cellRoot, isFocused && styles.focusCell]}
						>
							<Text style={styles.cellText}>{symbol || null}</Text>
						</View>
					)}
				/>

				<PrimaryButton
					onPress={onSubmit}
					loading={loading}
					disabled={value.length !== CELL_COUNT}
				>
					{i18n.t('continue')}
				</PrimaryButton>

				<CTA
					title={i18n.t('no-invitation')}
					ctaText={i18n.t('create-your-team')}
					onPress={() => navigation.replace('Onboarding_Create_Team_Modal')}
					style={{ marginTop: 60 }}
				/>
			</Form>
		</BaseContainer>
	);
};

const styles = StyleSheet.create({
	codeFieldRoot: {
		marginTop: 20,
		marginBottom: 40,
		width: 300,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	cellRoot: {
		width: 50,
		height: 80,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		borderRadius: 12,
	},
	cellText: {
		color: colors.dark,
		fontFamily: fonts.Inter.Bold,
		fontSize: 36,
		textAlign: 'center',
	},
	focusCell: {
		borderColor: colors.secondary,
		borderWidth: 2,
	},
});
