import React, { useCallback, useEffect, useState } from "react"
import { Asset } from "expo-asset"
import { ActionSheetProvider } from "@expo/react-native-action-sheet"
import {
  useFonts,
  Inter_300Light,
  Inter_400Regular,
  Inter_600SemiBold,
  Inter_700Bold,
} from "@expo-google-fonts/inter"
import FlashMessage from "react-native-flash-message"
import * as Localization from "expo-localization"
import i18n from "i18n-js"
import { RootStackNavigator } from "./src/navigation"
import * as SplashScreen from "expo-splash-screen"

import locales from "./src/translation"
import { AuthProvider } from "./src/modules/auth"
import { View } from "react-native"
i18n.translations = locales
i18n.locale = Localization.locale
i18n.fallbacks = true

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync()

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false)

  let [fontsLoaded] = useFonts({
    inter_light: Inter_300Light,
    inter_regular: Inter_400Regular,
    inter_semibold: Inter_600SemiBold,
    inter_bold: Inter_700Bold,
  })

  useEffect(() => {
    async function prepare() {
      try {
        // Pre-load fonts, make any API calls you need to do here
        loadResourcesAsync()
      } catch (e) {
        console.warn(e)
      } finally {
        // Tell the application to render
        setAppIsReady(true)
      }
    }

    prepare()
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady || !fontsLoaded) {
    return null
  }

  return (
    <ActionSheetProvider>
      <AuthProvider>
        <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
          <RootStackNavigator />
          <FlashMessage position="top" style={{ zIndex: 999 }} />
        </View>
      </AuthProvider>
    </ActionSheetProvider>
  )
}

async function loadResourcesAsync() {
  await Asset.loadAsync([
    require("./src/assets/icons/tab-icon-guides.png"),
    require("./src/assets/icons/tab-icon-habits.png"),
    require("./src/assets/icons/tab-icon-profile.png"),
    require("./src/assets/icons/tab-icon-stats.png"),
    require("./src/assets/icons/tab-icon-team.png"),
    require("./src/assets/img/walkthrough-1.png"),
    require("./src/assets/img/walkthrough-2.png"),
    require("./src/assets/img/walkthrough-3.png"),
    require("./src/assets/img/walkthrough-4.png"),
    require("./src/assets/img/walkthrough-5.png"),
    require("./src/assets/img/habits/1.jpg"),
    require("./src/assets/img/habits/2.jpg"),
    require("./src/assets/img/habits/3.jpg"),
    require("./src/assets/img/habits/4.jpg"),
    require("./src/assets/img/habits/5.jpg"),
    require("./src/assets/img/habits/6.jpg"),
    require("./src/assets/img/habits/7.jpg"),
    require("./src/assets/img/habits/8.jpg"),
    require("./src/assets/img/habits/9.jpg"),
    require("./src/assets/img/habits/10.jpg"),
    require("./src/assets/img/habits/11.jpg"),
    require("./src/assets/img/avatar-placeholder.png"),
    require("./src/assets/img/bg-bonus.png"),
    require("./src/assets/img/bg-profile.png"),
    require("./src/assets/img/bg-welcome.png"),
    require("./src/assets/img/guide-exercice.png"),
    require("./src/assets/img/guide-nutrition.png"),
    require("./src/assets/img/guide-sleep.png"),
    require("./src/assets/img/guide-trust.png"),
    require("./src/assets/img/guide-video-4.jpg"),
    require("./src/assets/img/guide-video-5.jpg"),
    require("./src/assets/img/guide-video-6.jpg"),
    require("./src/assets/img/logos.png"),
    require("./src/assets/img/logo-blue.png"),
    require("./src/assets/img/check-orange.png"),
    require("./src/assets/img/rocket.png"),
    require("./src/assets/img/medal-3.png"),
    require("./src/assets/img/clock-icon.png"),
  ])
}
