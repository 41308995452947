import {
	UserChallengeAchievement,
	UserHabitAchievement,
} from '@newstart/engine';

export const isHabitAchievement = (
	achievement: UserHabitAchievement | UserChallengeAchievement
): achievement is UserHabitAchievement => {
	return 'habitId' in achievement && achievement.habitId !== null;
};

export const isChallengeAchievement = (
	achievement: UserHabitAchievement | UserChallengeAchievement
): achievement is UserChallengeAchievement => {
	return 'challengeId' in achievement && achievement.challengeId !== null;
};
