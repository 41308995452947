import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledActivityIndicator = styled.ActivityIndicator.attrs({
	size: 'large',
	color: ui.colors.light,
})`
	position: absolute;
	top: ${ui.statusBarHeight + 12}px;
	right: 20px;
	transform: scale(0.8);
`;

export const StyledMessage = styled.View<{ readed: boolean }>`
	background-color: ${({ readed }) =>
		readed ? 'rgba(255,255,255,0.09)' : ui.colors.light};
	border-left-width: 1px;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-top-left-radius: 45px;
	border-bottom-left-radius: 45px;
	border-color: ${({ readed }) =>
		readed ? ui.colors.light : 'rgba(255,255,255,0.24)'};
	padding: 20px 30px 20px 40px;
	margin-left: 20px;
	max-height: 90px;
`;

export const StyledMessageText = styled.Text<{ readed: boolean }>`
	font-family: ${({ readed }) =>
		readed ? ui.fonts.Inter.Regular : ui.fonts.Inter.Bold};
	font-size: 16px;
	color: ${({ readed }) => (readed ? ui.colors.light : ui.colors.dark)};
	letter-spacing: -0.31px;
	line-height: 24px;
`;
