import i18n from 'i18n-js';
import { ExerciseType, ExerciseTypeKey } from './types';

export const exerciseTypes: ExerciseTypeList = {
	WALKING: {
		key: 'WALKING',
		unit: 'steps',
		name: i18n.t('exersice-type-walking'),
		icon: require('@newstart/assets/icons/exercise-type-walking.png'),
		question: 'How many steps did you walk?',
		multiplier: { LOW: 1, MEDIUM: 1.4, HIGH: 1.8, SUPERHIGH: 2.2 },
		multiplierDescriptions: {
			LOW: '0-100m',
			MEDIUM: '100-250m',
			HIGH: '250-400m',
			SUPERHIGH: '+ 400m',
		},
	},
	RUNNING: {
		key: 'RUNNING',
		unit: 'steps',
		name: i18n.translate('exercise-type-running'),
		icon: require('@newstart/assets/icons/exercise-type-running.png'),
		question: 'How many steps did you run?',
		multiplier: { LOW: 1.2, MEDIUM: 1.6, HIGH: 2, SUPERHIGH: 2.4 },
		multiplierDescriptions: {
			LOW: '0-100m',
			MEDIUM: '100-250m',
			HIGH: '250-400m',
			SUPERHIGH: '+ 400m',
		},
	},
	CYCLING: {
		key: 'CYCLING',
		unit: 'km',
		name: i18n.t('exercise-type-cycling'),
		icon: require('@newstart/assets/icons/exercise-type-cycling.png'),
		question: 'How many kilometers did you cycle?',
		multiplier: { LOW: 400, MEDIUM: 700, HIGH: 1000, SUPERHIGH: 1300 },
		multiplierDescriptions: {
			LOW: '0-100m',
			MEDIUM: '100-250m',
			HIGH: '250-400m',
			SUPERHIGH: '+ 400m',
		},
	},
	ELETRIC_CYCLING: {
		key: 'ELETRIC_CYCLING',
		unit: 'km',
		name: i18n.t('exercise-type-eletric-cycling'),
		icon: require('@newstart/assets/icons/exercise-type-eletric-cycling.png'),
		question: 'How many kilometers did you cycle?',
		converter: 200,
	},
	SWIMMING: {
		key: 'SWIMMING',
		unit: 'meters',
		name: i18n.t('exercise-type-swimming'),
		icon: require('@newstart/assets/icons/exercise-type-swimming.png'),
		question: 'How many kilometers did you swim?',
		converter: 3,
	},
	SNOWSHOE: {
		key: 'SNOWSHOE',
		unit: 'steps',
		name: i18n.t('exercise-type-snowshoe'),
		icon: require('@newstart/assets/icons/exercise-type-snowshoe.png'),
		question: 'How many steps did you snowshoe?',
		multiplier: { LOW: 1.5, MEDIUM: 2, HIGH: 2.5, SUPERHIGH: 3 },
		multiplierDescriptions: {
			LOW: '0-100m',
			MEDIUM: '100-250m',
			HIGH: '250-400m',
			SUPERHIGH: '+ 400m',
		},
	},
	OTHER: {
		key: 'OTHER',
		unit: 'min',
		name: i18n.t('exercise-type-other'),
		icon: require('@newstart/assets/icons/exercise-type-other.png'),
		question: 'How many minutes did you do this exercise?',
		multiplier: { LOW: 50, MEDIUM: 100, HIGH: 150 },
		multiplierLabel: 'intensity',
	},
};

/**
 * Lista de tipos de exercícios
 */
export type ExerciseTypeList = Record<ExerciseTypeKey, ExerciseType>;
