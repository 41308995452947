import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

const { width, maxWidth } = ui;
const ITEM_WIDTH = width - 40;

export const StyledDonorButton = styled.View`
	background-color: ${ui.colors.primary};
	width: ${ITEM_WIDTH}px;
	max-width: ${maxWidth}px;
	height: 100px;
	flex: 1;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
`;

export const StyledDonorboxLogo = styled.Image`
	width: 207px;
	height: 58px;
`;
