import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { HabitCategoryKey } from '@newstart/engine';
import { GuidesStackParamList } from '../navigation';

import {
	StyledCover,
	StyledBody,
	StyledText,
	StyledTitle,
} from '../components';
import { BaseModalContainer, StatusBar } from '@newstart/ui';

type Props = NativeStackScreenProps<
	GuidesStackParamList,
	'Guides_Content_Modal'
>;

export const GuideContentModal: React.FunctionComponent<Props> = ({
	route,
	navigation,
}) => {
	const { category, title } = route.params;
	return (
		<BaseModalContainer
			onClose={() => navigation.goBack()}
			safeArea={false}
			dark
		>
			<StyledCover source={renderImage(category)}>
				<StyledTitle>{title}</StyledTitle>
			</StyledCover>
			<StyledBody
				contentInsetAdjustmentBehavior="automatic"
				style={{ height: '100%' }}
			>
				{renderContent(category)}
			</StyledBody>
			<StatusBar style="light" />
		</BaseModalContainer>
	);
};

function renderImage(category: HabitCategoryKey) {
	switch (category) {
		case HabitCategoryKey.EXERCISE:
			return require('../../../assets/img/guide-exercice.png');
			break;
		case HabitCategoryKey.NUTRITION:
			return require('../../../assets/img/guide-nutrition.png');
			break;
		case HabitCategoryKey.SLEEP:
			return require('../../../assets/img/guide-sleep.png');
			break;
		case HabitCategoryKey.WALFARE:
			return require('../../../assets/img/guide-trust.png');
			break;
	}
}

function renderContent(category: HabitCategoryKey) {
	if (category === HabitCategoryKey.EXERCISE) {
		return (
			<>
				<StyledText>
					Marcher, courir, nager ou encore faire du vélo, faites le plein
					d’énergie!
				</StyledText>
				<StyledText>
					Retrouvez la forme, la santé et la bonne humeur à travers le sport.
				</StyledText>
				<StyledText>
					Avec une équipe à vos côtés, tout devient possible!
				</StyledText>
				<StyledText>
					Dans ce programme nous vous invitons à marcher quotidiennement et
					calculer vos pas avec un podomètre.
				</StyledText>
			</>
		);
	} else if (category === HabitCategoryKey.NUTRITION) {
		return (
			<>
				<StyledText>
					L’alimentation équilibrée est l’une des réponses à une bonne santé.
				</StyledText>
				<StyledText>
					Redécouvrez une alimentation variée, équilibrée et nutritive, qui vous
					permettra de retrouver beaucoup d’énergie, de perdre du poids de
					manière douce et durable, et de prévenir aussi l’apparition de
					maladies.
				</StyledText>
				<StyledText>
					Participer à "New-Start 80" vous dotera des outils et des informations
					nécessaires sur la manière de se nourrir sainement.
				</StyledText>
			</>
		);
	} else if (category === HabitCategoryKey.SLEEP) {
		return (
			<>
				<StyledText>Bien dormir, c’est mieux vivre au quotidien.</StyledText>
				<StyledText>
					Le sommeil est une fonction biologique essentielle sans laquelle nous
					ne pouvons pas vivre.
				</StyledText>
				<StyledText>
					Redécouvrez les bienfaits et l’importance du sommeil pour:
				</StyledText>
				<StyledText style={{ paddingLeft: 20 }}>
					• La récupération de nos forces physiques et psychiques
				</StyledText>
				<StyledText style={{ paddingLeft: 20 }}>
					• La restauration de l’organisme
				</StyledText>
				<StyledText style={{ paddingLeft: 20 }}>
					• La formation, la maturation et le bon fonctionnement de notre
					cerveau et du système nerveux central…
				</StyledText>
				<StyledText>
					Participer à "New-Start 80" vous permettra de mieux comprendre le
					sommeil et ses enjeux pour un meilleur sommeil.
				</StyledText>
			</>
		);
	} else if (category === HabitCategoryKey.WALFARE) {
		return (
			<>
				<StyledText>Votre bien-être est notre première motivation!</StyledText>
				<StyledText>
					Stress, anxiété et course effrénée de la vie, il est temps de se
					reposer!
				</StyledText>
				<StyledText>
					Prendre le temps de vivre, s’accorder du temps pour soi, pour
					rencontrer ceux que l’on aime, ce qu’on aime. C’est pourquoi, profitez
					du jour de repos qui est offert!
				</StyledText>
				<StyledText>
					Participer à "New-start 80" vous conduira sur une réflexion sur le
					bien-être intérieur, un chemin de relations apaisées et calmées, avec
					soi, avec Dieu et son prochain!
				</StyledText>
			</>
		);
	}
}
