import React, { useMemo } from 'react';
import i18n from 'i18n-js';

import { ScreenSection, ui } from '@newstart/ui';
import { useEngine } from '@newstart/engine';
import { useAuth } from '@newstart/auth';
import { ChallengeBubble, PeopleLineChart } from '../../components';

export const TeamActivityScreen: React.FC = () => {
	const { team } = useAuth();
	const { startDate, currentIndex, teamMembersPerformance, challenges } =
		useEngine();

	if (!team) {
		return null;
	}

	const chartData = useMemo(() => {
		const passedDays = currentIndex + 1;
		const index = passedDays > 7 ? passedDays : 7;
		const chartDays = [...Array(index).keys()].slice(-7);

		return team.members.map((member) => {
			const numbers: number[] = [];

			chartDays.map((indexOfProgram, ni) => {
				const maxCategoryPoints = 10000;
				const performance = teamMembersPerformance.find(
					(p) => p.userId === member.id && p.createdAt === indexOfProgram
				);
				const userPointsAtIndex = performance?._sum.points ?? 0;
				numbers[ni] = Math.min(
					100,
					(userPointsAtIndex / maxCategoryPoints) * 100
				);
			});

			return {
				user: member,
				numbers,
			};
		});
	}, [team, teamMembersPerformance]);

	const challenge = challenges.length > 0 && challenges[0];

	return (
		<>
			{challenge && (
				<ScreenSection title="Challenges">
					<ChallengeBubble startDate={startDate} challenge={challenge} />
				</ScreenSection>
			)}

			<ScreenSection title={i18n.t('members-stats')}>
				<PeopleLineChart data={chartData} />
			</ScreenSection>

			{/* <ScreenSection title={i18n.t('history')}></ScreenSection> */}
		</>
	);
};
