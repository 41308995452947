import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledContainer = styled.View`
	flex-direction: row;
	padding: 10px 20px 10px 14px;
	border-radius: 9px;
	margin-bottom: 10px;
	background-color: rgba(255, 255, 255, 0.15);
	align-items: center;
`;

export const StyledText = styled.Text`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 16px;
	color: ${ui.colors.light};
	letter-spacing: -0.44px;
	max-width: 65%;
	text-transform: lowercase;
`;

export const StyledPointsText = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 17px;
	color: ${ui.colors.light};
	letter-spacing: -0.44px;
	margin-left: auto;
`;

export const StyledIconContainer = styled.View<{ borderColor: string }>`
	width: 32px;
	height: 32px;
	border-radius: 16px;
	border-width: 2px;
	border-color: ${(props) => props.borderColor};
	background: ${(props) => props.borderColor};
	margin-right: 10px;
	justify-content: center;
	align-items: center;
`;
