export const httpErrorHandler = (error: any, reject: (args: any) => void) => {
	if (error.response && error.response.data.hasOwnProperty('code')) {
		// Request made and server responded
		console.warn(error.response.data);
		reject(error.response.data);
	} else if (error.request) {
		// The request was made but no response was received
		console.warn(error.request);
		reject(error.request._response);
	} else {
		// Something happened in setting up the request that triggered an Error
		console.warn('Http Request Error:', error.message);
		reject(error.message);
	}
};
