import React, { useRef } from 'react';
import { Animated, ListRenderItem } from 'react-native';
import { Habit } from '@newstart/engine';
import { ui } from '@newstart/ui';
import { VideosCarouselItem } from './VideosCarouselItem';
import { specs } from './styles';

const { ITEM_WIDTH, FULL_SIZE } = specs;

const thumbs = [
	require('@newstart/assets/img/habits/1.jpg'), // exercício não aparece
	require('@newstart/assets/img/habits/1.jpg'),
	require('@newstart/assets/img/habits/2.jpg'),
	require('@newstart/assets/img/habits/3.jpg'),
	require('@newstart/assets/img/habits/4.jpg'),
	require('@newstart/assets/img/habits/5.jpg'),
	require('@newstart/assets/img/habits/6.jpg'),
	require('@newstart/assets/img/habits/7.jpg'),
	require('@newstart/assets/img/habits/8.jpg'),
	require('@newstart/assets/img/habits/9.jpg'),
	require('@newstart/assets/img/habits/10.jpg'),
	require('@newstart/assets/img/habits/11.jpg'),
];

export type VideosCarouselProps = {
	data: Habit[];
};

export const VideosCarousel = ({ data }: VideosCarouselProps) => {
	const scrollX = useRef(new Animated.Value(0)).current;

	const renderItem: ListRenderItem<Habit> = ({ item, index }) => {
		const inputRange = [
			(index - 1) * FULL_SIZE,
			index * FULL_SIZE,
			(index + 1) * FULL_SIZE,
		];
		const translateX = scrollX.interpolate({
			inputRange,
			outputRange: [10, 0, -10],
			extrapolate: 'clamp',
		});
		const scale = scrollX.interpolate({
			inputRange,
			outputRange: [1, 1.4, 1],
			extrapolate: 'clamp',
		});
		return (
			<VideosCarouselItem
				index={index}
				label={item.name}
				thumb={thumbs[item.order] ?? thumbs[0]}
				videoUrl={item.videoUrl}
				color={item.category.colors[0]}
				scale={scale}
				translateX={translateX}
			/>
		);
	};

	return (
		<Animated.FlatList
			data={data}
			keyExtractor={(item) => item.id}
			horizontal
			showsHorizontalScrollIndicator={false}
			snapToInterval={FULL_SIZE}
			decelerationRate="fast"
			onScroll={Animated.event(
				[{ nativeEvent: { contentOffset: { x: scrollX } } }],
				{ useNativeDriver: true }
			)}
			renderItem={renderItem}
			contentContainerStyle={{
				paddingLeft: 10,
				paddingRight: ui.width - FULL_SIZE,
			}}
		/>
	);
};
