import { useCallback, useEffect } from 'react';
import { Platform, Share, Text, View } from 'react-native';
import { Team } from '@newstart/auth';
import { BaseContainer, PrimaryButton, useMessage } from '@newstart/ui';
import { Feather } from '@expo/vector-icons';
import i18n from 'i18n-js';
import {
	StyledInfo,
	StyledTeamName,
	StyledCodeText,
	StyledShareButton,
} from './styles';
import { CreateTeamNavigation } from './CreateTeamScreen';

type CreatedProps = {
	navigation: CreateTeamNavigation;
	onDone: () => Promise<void>;
	loading: boolean;
	team?: Team;
};

export const Created: React.FunctionComponent<CreatedProps> = ({
	navigation,
	onDone,
	loading,
	team,
}) => {
	const { showError } = useMessage();

	if (!team) {
		return null;
	}

	const handleSharing = useCallback(async () => {
		try {
			await Share.share({
				message: i18n.t('invitate-message', { code: team.inviteCode }),
			});
		} catch (error) {
			showError(error);
		}
	}, [team, showError]);

	useEffect(() => {
		navigation.setOptions({
			title: i18n.t('congratulations'),
			headerBackVisible: false,
			headerLargeTitle: false,
		});
	}, []);

	return (
		<BaseContainer>
			<StyledInfo>{i18n.t('your-team-was-created')}</StyledInfo>
			<StyledTeamName numberOfLines={1}>{team.name}</StyledTeamName>

			<StyledInfo>
				{i18n.t('now-share-this-code-to-your-friends-to-join-you')}
			</StyledInfo>
			<StyledCodeText>{team.inviteCode}</StyledCodeText>

			{Platform.OS === 'web' ? (
				<Text style={{ textAlign: 'center' }}>
					{i18n.t('invite-my-friends')}
				</Text>
			) : (
				<StyledShareButton onPress={handleSharing}>
					<Feather name="share" size={30} color="black" />
					<Text style={{ textAlign: 'center' }}>
						{i18n.t('invite-my-friends')}
					</Text>
				</StyledShareButton>
			)}

			<View
				style={{
					width: '100%',
					maxWidth: 420,
					marginTop: 'auto',
					marginBottom: 40,
					padding: 30,
				}}
			>
				<PrimaryButton onPress={onDone} loading={loading}>
					{i18n.t('done')}
				</PrimaryButton>
			</View>
		</BaseContainer>
	);
};
