import { PropsWithChildren } from "react"
import { HabitBubbleProps } from "./interfaces"
import {
  StyledIconContainerFilled,
  StyledIconContainerTransparent,
} from "./styles"

export const StyledIconContainer: React.FunctionComponent<
  PropsWithChildren<Pick<HabitBubbleProps, "filled" | "colors">>
> = ({ filled, colors, children }) => {
  if (filled) {
    return (
      <StyledIconContainerTransparent>
        {children}
      </StyledIconContainerTransparent>
    )
  }
  return (
    <StyledIconContainerFilled colors={colors}>
      {children}
    </StyledIconContainerFilled>
  )
}
