import React from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ActivityIndicator, Image } from 'react-native';
import {
	BaseContainer,
	PrimaryButton,
	StatusBar,
	ui,
	WhiteButton,
} from '@newstart/ui';
import { AuthStackParamList } from '../../navigation';
import * as Linking from 'expo-linking';
import i18n from 'i18n-js';
import {
	StyledActionsContainer,
	StyledLink,
	StyledLogoContainer,
	StyledText,
} from './styles';
import { useAuth } from '../../contexts';

type WelcomeScreenProps = NativeStackScreenProps<
	AuthStackParamList,
	'Auth_Welcome_Screen'
>;

export const WelcomeScreen: React.FunctionComponent<WelcomeScreenProps> = ({
	navigation,
}) => {
	const { loading } = useAuth();

	if (loading)
		return (
			<BaseContainer
				backgroundImage={require('@newstart/assets/img/bg-welcome.png')}
				style={{ justifyContent: 'center', alignItems: 'center' }}
			>
				<StatusBar style="dark" />
				<ActivityIndicator size="large" color={ui.colors.dark} />
			</BaseContainer>
		);

	return (
		<BaseContainer
			backgroundImage={require('@newstart/assets/img/bg-welcome.png')}
		>
			<StatusBar style="dark" />
			<StyledLogoContainer>
				<Image source={require('@newstart/assets/img/logo-blue.png')} />
			</StyledLogoContainer>

			<StyledActionsContainer>
				<PrimaryButton
					onPress={() => navigation.navigate('Auth_Register_Screen')}
				>
					{i18n.t('register')}
				</PrimaryButton>
				<WhiteButton onPress={() => navigation.navigate('Auth_Login_Screen')}>
					{i18n.t('login')}
				</WhiteButton>

				<StyledText>
					By tapping Sign In and using New Start 80, you agree to our{' '}
					<StyledLink
						onPress={() => {
							Linking.openURL('https://espoirmedias.business.site/#summary');
						}}
					>
						Privacy Policy
					</StyledLink>
					.
				</StyledText>
			</StyledActionsContainer>
		</BaseContainer>
	);
};
