import styled from 'styled-components/native';
import { ui } from '@newstart/ui';

const { colors, fonts, maxWidth } = ui;

export const StyledContainer = styled.ScrollView.attrs({
	contentContainerStyle: {
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingBottom: 40,
		paddingHorizontal: 20,
	},
})`
	width: 100%;
	margin-top: 10px;
`;

export const StyledPosition = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 17px;
	color: ${colors.light};
	letter-spacing: -0.47px;
	text-align: center;
`;

export const StyledPositionContainer = styled.View`
	background-color: #261b42;
	border-radius: 16px;
	padding: 2px 14px;
`;

export const StyledName = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 28px;
	color: ${colors.highlight};
	letter-spacing: -0.77px;
	text-align: center;
	margin: 10px 0;
`;

export const StyledPoints = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 22px;
	color: ${colors.highlight};
	letter-spacing: -0.61px;
	text-align: center;
`;

export const StyledMemberBubbleContainer = styled.View`
	width: 100%;
	max-width: ${maxWidth}px;
	min-height: 350px;
	max-height: 650px;
	background-color: rgba(255, 255, 255, 0.1);
	padding: 10px;
	border-radius: 10px;
	margin-top: 30px;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
`;

export const StyledMemberBubbleItem = styled.View`
	width: 50%;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
`;

export const StyledMemberBubbleName = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 15px;
	color: ${colors.light};
	letter-spacing: -0.44px;
	text-align: center;
	margin-top: 6px;
`;

export const StyledMemberBubblePoints = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 15px;
	color: ${colors.light};
	letter-spacing: -0.44px;
	text-align: center;
	margin-top: 4px;
`;
