import React, { useState } from 'react';
import { Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { BaseModalContainer, BlackButton, ui, useKeyboard } from '@newstart/ui';

import {
	ExerciseInputModalAction,
	ExerciseInputModalState,
} from './exerciseInputReducer';
import { useFormatting } from './useFormatting';

import { ElevationInput } from '../../components';
import {
	StyledExerciseTypeIcon,
	StyledFormHeader,
	StyledInput,
} from './styles';

type ExerciseInputFormProps = {
	state: ExerciseInputModalState;
	dispatch: React.Dispatch<ExerciseInputModalAction>;
	onSubmit: () => Promise<void>;
};

export const ExerciseInputForm: React.FunctionComponent<
	ExerciseInputFormProps
> = ({ state, dispatch, onSubmit }) => {
	const { exerciseType, steps, rawSteps, isSubmitting, isEditing } = state;
	if (!exerciseType) return null;

	const { goBack } = useNavigation();
	const keyboardHeight = useKeyboard();

	const [innerValue, setInnerValue] = useState(
		isEditing ? String(rawSteps) : ''
	);

	const { question, buttonLabel, inputPlaceholder } = useFormatting({
		exerciseType,
		steps,
	});

	return (
		<BaseModalContainer
			backgroundColor={['#75FF25', '#D8F83F']}
			onClose={() => goBack()}
			style={{
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: 16,
				paddingBottom: keyboardHeight + 5,
			}}
		>
			<StyledFormHeader>
				<StyledExerciseTypeIcon>
					<Image
						source={exerciseType.icon}
						style={{ transform: [{ scale: 0.7 }] }}
					/>
				</StyledExerciseTypeIcon>
				{question}
			</StyledFormHeader>

			<StyledInput
				keyboardType="decimal-pad"
				autoCorrect={false}
				autoFocus={true}
				defaultValue={innerValue}
				placeholder={` ${inputPlaceholder}`}
				selectionColor={ui.colors.primary}
				onChangeText={(text) => {
					setInnerValue(text);
					dispatch({
						type: 'set_steps',
						payload: {
							rawSteps: parseFloat(text ? text.replace(',', '.') : text),
							steps: parseFloat(text ? text.replace(',', '.') : text),
						},
					});
				}}
			/>

			<ElevationInput
				defaultValue={state.elevation}
				converter={exerciseType.converter}
				multiplier={exerciseType.multiplier}
				multiplierLabel={exerciseType.multiplierLabel}
				multiplierDescriptions={exerciseType.multiplierDescriptions}
				unit={exerciseType.unit}
				onSetLevel={(level) =>
					dispatch({
						type: 'set_elevation',
						payload: { elevation: level, steps: parseFloat(innerValue) },
					})
				}
			/>

			<BlackButton
				disabled={!innerValue || !steps}
				onPress={onSubmit}
				loading={isSubmitting}
			>
				{buttonLabel}
			</BlackButton>
		</BaseModalContainer>
	);
};
