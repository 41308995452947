import React from 'react';
import { BaseContainer } from '@newstart/ui';
import {
	ExerciseInputModalAction,
	ExerciseInputModalState,
} from './exerciseInputReducer';
import { Image, Platform } from 'react-native';
import AnimatedLottieView from 'lottie-react-native';

type ExerciseInputIntroProps = {
	state: ExerciseInputModalState;
	dispatch: React.Dispatch<ExerciseInputModalAction>;
};

export const ExerciseInputDone: React.FunctionComponent<
	ExerciseInputIntroProps
> = ({ state, dispatch }) => {
	return (
		<BaseContainer backgroundColor={['#75FF25', '#D8F83F']}>
			{Platform.OS == 'web' ? (
				<Image
					source={require('@newstart/assets/img/check-orange.png')}
					style={{
						width: 281,
						height: 286,
						alignSelf: 'center',
					}}
				/>
			) : (
				<AnimatedLottieView
					source={require('@newstart/assets/lotties/check-orange.json')}
					autoPlay
					loop
				/>
			)}
		</BaseContainer>
	);
};
