import styled from 'styled-components/native';

import { ui } from '../styles';

export const StyledContainer = styled.View`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
`;
export const StyledMessage = styled.Text`
	font-size: 16px;
	font-family: ${ui.fonts.Inter.Regular};
	color: ${ui.colors.dark};
`;

export const StyledActionText = styled.Text`
	font-size: 16px;
	color: ${ui.colors.primary};
	font-family: ${ui.fonts.Inter.Bold};
`;
