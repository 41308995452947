import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StatsScreen, StatsTeamDetailModal } from '../screens';

export type StatsStackParamList = {
	Stats_Screen: { activeTab: 'me' | 'team' };
	Stats_Team_Detail: {
		teamId: string;
		position: number;
		name: string;
		points: number;
	};
};

const Stack = createNativeStackNavigator<StatsStackParamList>();

export const StatsNavigator = () => {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen
				name="Stats_Screen"
				component={StatsScreen}
				initialParams={{ activeTab: 'me' }}
			/>
			<Stack.Screen
				name="Stats_Team_Detail"
				component={StatsTeamDetailModal}
				options={{ presentation: 'modal' }}
			/>
		</Stack.Navigator>
	);
};
