import React, { Fragment, useMemo, useState } from "react"
import { FlatList, Image, ListRenderItem, Platform } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { MaterialIcons } from "@expo/vector-icons"
import { ui, ScreenHeader, BaseContainer, StatusBar } from "@newstart/ui"
import { HabitCategoryKey } from "@newstart/engine"
import * as Linking from "expo-linking"
import i18n from "i18n-js"

const { height, colors } = ui

import { GuidesStackParamList } from "../navigation"
import {
  Arrow,
  Carousel,
  ContactButtonBottom,
  ContactButtonLabel,
  GuideButton,
  Title,
} from "../components"
import { GuidesScreenItem } from "./types"
import { StyledDonorboxLogo, StyledDonorButton } from "./styles"
import { TouchableOpacity } from "react-native-gesture-handler"
import { CardColor, CardContainer } from "../components/GuideButton/styles"

type Props = NativeStackScreenProps<GuidesStackParamList, "Guides_Screen">

export const GuidesScreen: React.FunctionComponent<Props> = ({
  navigation,
}) => {
  const [indices, setIndices] = useState<number[]>([])

  const { data } = useMemo(() => {
    const items: GuidesScreenItem[] = [
      {
        key: "hero-slider",
        data: [
          {
            id: 1,
            videoUrl: "https://www.youtube.com/watch?v=mmnDDZjoBes",
            background: require("@newstart/assets/img/guide-video-6.jpg"),
          },
          {
            id: 2,
            videoUrl: "https://www.youtube.com/watch?v=UMRAxM_h0I0",
            background: require("@newstart/assets/img/guide-video-5.jpg"),
          },
          // {
          // 	id: 3,
          // 	videoUrl: 'https://www.youtube.com/watch?v=QrB7hMzW7OQ',
          // 	background: require('@newstart/assets/img/guide-video-4.jpg'),
          // },
        ],
        format: "carousel",
      },
      ...(Platform.OS !== "ios"
        ? [
            {
              key: "donate",
              data: {
                url: "https://donorbox.org/newstart-80",
              },
              format: "donate",
            } as GuidesScreenItem,
          ]
        : []),
      {
        key: "subtitle_1",
        data: {
          label: i18n.t("articles"),
        },
        format: "title",
      },
      {
        key: HabitCategoryKey.EXERCISE,
        data: {
          title: i18n.t("physical_exercise"),
          background: require("../../../assets/img/guide-exercice.png"),
        },
        format: "article",
      },
      {
        key: HabitCategoryKey.NUTRITION,
        data: {
          title: i18n.t("nutrition"),
          background: require("../../../assets/img/guide-nutrition.png"),
        },
        format: "article",
      },
      {
        key: HabitCategoryKey.SLEEP,
        data: {
          title: i18n.t("rest_sleep"),
          background: require("../../../assets/img/guide-sleep.png"),
        },
        format: "article",
      },
      {
        key: HabitCategoryKey.WALFARE,
        data: {
          title: i18n.t("inner_well_being"),
          background: require("../../../assets/img/guide-trust.png"),
        },
        format: "article",
      },
      {
        key: "faq",
        data: {
          title: "FAQ",
        },
        format: "faq",
      },
      {
        key: "subtitle_2",
        data: {
          label: i18n.t("get-in-touch"),
        },
        format: "title",
      },
      {
        key: "contact",
        data: {
          email: "contact@vie-et-sante.ch",
          urlEmail: "mailto:contact@vie-et-sante.ch",
          background: require("../../../assets/img/guide-contact.png"),
        },
        format: "contact",
      },
    ]

    let indicesList: number[] = []
    items.forEach(
      (item, index) => item.format === "title" && indicesList.push(index)
    )
    setIndices(indicesList)

    return { data: items }
  }, [])

  const renderItem: ListRenderItem<GuidesScreenItem> = ({ item }) => {
    const { format } = item
    if (format === "carousel") return <Carousel data={item.data} />

    if (format === "title") return <Title>{item.data.label}</Title>

    if (format === "article")
      return (
        <GuideButton
          onPress={() => {
            navigation.navigate("Guides_Content_Modal", {
              category: item.key as HabitCategoryKey,
              title: item.data.title,
            })
          }}
          label={item.data.title}
          background={item.data.background}
        />
      )

    if (format === "faq")
      return (
        <GuideButton
          onPress={() => {
            navigation.navigate("Guides_Faq_Modal")
          }}
          label={item.data.title}
        />
      )

    if (format === "donate")
      return (
        <>
          <Title>{i18n.t("donate")}</Title>
          <CardContainer
            onPress={() => Linking.openURL(item.data.url)}
            activeOpacity={1}
          >
            <StyledDonorButton>
              <StyledDonorboxLogo
                source={require("@newstart/assets/img/donorbox.png")}
              />
            </StyledDonorButton>
          </CardContainer>
        </>
      )

    if (format === "contact")
      return (
        <ContactButtonBottom
          onPress={() => {
            Linking.openURL(item.data.urlEmail)
          }}
        >
          <MaterialIcons name="mail-outline" size={20} color={colors.dark} />
          <ContactButtonLabel>{item.data.email}</ContactButtonLabel>
          <Arrow />
        </ContactButtonBottom>
      )

    return <Fragment />
  }

  return (
    <BaseContainer style={{ height }} safeArea>
      <StatusBar style="auto" />
      <ScreenHeader title="Guides" theme="light" />
      <FlatList<GuidesScreenItem>
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.key}
        stickyHeaderIndices={indices}
        refreshing={false}
        style={{
          width: "100%",
          flex: 1,
        }}
        contentContainerStyle={{
          padding: 20,
        }}
      />
    </BaseContainer>
  )
}
