import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
const { colors, fonts, maxWidth } = ui;

export const StyledItemContainer = styled.TouchableOpacity<{
	isFirst?: boolean;
}>`
	background: #ffffff;
	width: 100%;
	max-width: ${maxWidth}px;
	align-self: center;
	padding: 20px;
	border-top-width: ${(props) => (props.isFirst ? 1 : 0)}px;
	border-top-color: ${colors.border};
	border-bottom-width: 1px;
	border-bottom-color: ${colors.border};
`;

export const StyledItem = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const StyledTitle = styled.Text<{ isRead: boolean }>`
	font-family: ${(props) =>
		props.isRead ? fonts.Inter.Regular : fonts.Inter.Bold};
	font-size: 16px;
	color: ${colors.dark};
	letter-spacing: -0.44px;
	text-align: left;
	line-height: 22px;
	width: 70%;
`;

export const StyledMessage = styled.Text<{ isRead: boolean }>`
	font-family: ${fonts.Inter.Regular};
	font-size: 16px;
	color: ${colors.dark};
	letter-spacing: -0.44px;
	text-align: left;
	line-height: 22px;
	opacity: ${(props) => (props.isRead ? 0.5 : 1)};
`;

export const StyledDateText = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 14px;
	color: #857d98;
	letter-spacing: -0.38px;
	text-align: right;
	line-height: 22px;
	width: 100px;
`;

export const StyledEmpty = styled.View`
	border-radius: 4px;
	border-top-width: 1px;
	border-top-color: ${colors.border};
	border-bottom-width: 1px;
	border-bottom-color: ${colors.border};
	padding: 30px;
`;
export const StyledEmptyText = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 16px;
	text-align: center;
	color: ${colors.secondary};
	letter-spacing: -0.44px;
`;
