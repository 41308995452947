import styled from 'styled-components/native';
import { Entypo } from '@expo/vector-icons';
import { ui } from '@newstart/ui';

export { Carousel } from './Carousel';
export { Title } from './Title';
export { GuideButton } from './GuideButton';

export const ContactButtonTop = styled.TouchableOpacity`
	background: rgba(133, 125, 152, 0.11);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: #261b42;
	letter-spacing: -0.44px;
	height: 50px;
	flex-direction: row;
	align-items: center;
	flex: 1;
	margin-bottom: 1px;
	padding: 0 20px 0 30px;
`;
export const ContactButtonBottom = styled.TouchableOpacity`
	background: rgba(133, 125, 152, 0.11);
	border-radius: 25px;
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: #261b42;
	letter-spacing: -0.44px;
	height: 50px;
	flex-direction: row;
	align-items: center;
	padding: 0 20px 0 30px;
	flex: 1;
`;
export const ContactButtonLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 15px;
	color: #261b42;
	letter-spacing: -0.44px;
	margin-left: 10px;
`;
export const Arrow = styled(Entypo).attrs({
	name: 'chevron-small-right',
	size: 24,
	color: 'rgba(39,37,47,0.28)',
})`
	margin-left: auto;
`;

export const StyledCover = styled.ImageBackground`
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 180px;
`;

export const StyledTitle = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 30px;
	color: ${ui.colors.light};
	letter-spacing: -0.82px;
`;

export const StyledBody = styled.ScrollView.attrs({
	contentContainerStyle: { padding: 20 },
})`
	background-color: ${ui.colors.light};
	flex: 1;
`;

export const StyledText = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 18px;
	color: ${ui.colors.dark};
	letter-spacing: -0.5px;
	line-height: 22px;
	margin-bottom: 14px;
`;

export const StyledSubtitle = styled.Text<{ variant?: 'h2' }>`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: ${({ variant }) => (variant === 'h2' ? 16 : 20)}px;
	color: ${ui.colors.secondary};
	letter-spacing: -0.55px;
	line-height: 24px;
	margin-top: 28px;
	margin-bottom: 18px;
	text-transform: ${({ variant }) => (variant === 'h2' ? 'uppercase' : 'none')};
`;
