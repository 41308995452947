import { showMessage } from 'react-native-flash-message';
import { ui } from '../styles';
import { useErrorMessage } from './useErrorMessage';

export const useMessage = () => {
	const { showError } = useErrorMessage();
	return {
		showError,
		showSuccess: (message: string) => {
			showMessage({
				message,
				type: 'success',
				duration: 3000,
				color: ui.colors.dark,
				backgroundColor: ui.colors.highlight,
				floating: true,
			});
		},
		showInfo: (message: string) => {
			showMessage({
				message,
				type: 'info',
				duration: 3000,
				floating: true,
			});
		},
	};
};
