import { AuthToken } from '@newstart/auth';
import {
	AxiosHttpClient,
	HttpClient,
	httpErrorHandler,
	API_URL,
} from '@newstart/core';
import { BonusChallengeNotification } from './types';

class BonusNotificationService {
	constructor(private _httpClient: HttpClient) {}

	async findAll(token: AuthToken): Promise<BonusChallengeNotification[]> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.get<BonusChallengeNotification[]>(`/me/bonus`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}

	async getById(
		bonusNotificationId: string,
		token: AuthToken
	): Promise<BonusChallengeNotification> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.get<BonusChallengeNotification>(`/me/bonus/${bonusNotificationId}`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}

	async markAsRead(
		bonusNotificationId: string,
		token: AuthToken
	): Promise<void> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.post<void, {}>(
					`/me/bonus/${bonusNotificationId}/read`,
					{},
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}
}

export const getBonusNotificationService = () => {
	return new BonusNotificationService(new AxiosHttpClient(API_URL));
};
