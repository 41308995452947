import { AuthToken } from '@newstart/auth';
import {
	HttpClient,
	httpErrorHandler,
	AxiosHttpClient,
	API_URL,
} from '@newstart/core';
import { UserNotification } from '../notifications';
import {
	// TeamChallengeAchievement,
	// TeamHabitAchievement,
	TeamMemberDailyPerformance,
	UserChallengeAchievement,
	UserHabitAchievement,
	UserHabitDailyPerformance,
} from '../achievements';

export class ProgramService {
	constructor(private _httpClient: HttpClient) {}

	async init(token: AuthToken): Promise<ProgramInitData> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.get<ProgramInitData>('/me/init', { Authorization: `Bearer ${token}` })
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}
}

export const getProgramService = () => {
	return new ProgramService(new AxiosHttpClient(API_URL));
};

type ProgramInitData = {
	startDate: string;
	notifications: UserNotification[];
	achievements: {
		user: Array<UserHabitAchievement | UserChallengeAchievement>;
		team: UserHabitAchievement[];
	};
	performance: {
		user: UserHabitDailyPerformance[];
		team: TeamMemberDailyPerformance[];
	};
	challenges: Challenge[];
	userPoints: number;
	teamPoints: number;
};

export type Challenge = {
	id: string;
	title: string;
	description: string;
	startAt: number;
	endAt: number;
	rewardsForTeam: number | null;
	rewardsForUser: number | 5000;
	mechanics: string;
};
