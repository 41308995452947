import moment from 'moment-timezone';
import * as Localization from 'expo-localization';
moment.locale(Localization.locale);

export const getCurrentIndex = (startDate: string): number => {
	const start = moment.tz(startDate, Localization.timezone);
	const today = moment.tz(moment(), Localization.timezone);
	const diffInHours = today.diff(start, 'hours');
	const diff = Math.floor(diffInHours / 24);
	return diff;
};

export const formatDate = (
	startDate: string,
	index: number,
	format?: string
): string => {
	return moment(startDate).add(index, 'days').format(format);
};
``;
