import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
const { colors, fonts } = ui;

export const StyledBtn = styled.TouchableOpacity`
	background-color: #fff;
	border-bottom-width: 1px;
	border-top-width: 1px;
	border-bottom-color: #e8e5ee;
	border-top-color: #e8e5ee;
	width: 100%;
	padding: 18px 0;
`;
export const StyledBtnLabel = styled.Text`
	font-family: ${fonts.Inter.SemiBold};
	font-size: 20px;
	color: ${colors.primary};
	letter-spacing: -0.55px;
	text-align: center;
`;

export const StyledAboutMessage = styled.View`
	margin-top: 60px;
	align-items: center;
	max-width: 380px;
	position: absolute;
	bottom: 30px;
`;

export const StyledLogo = styled.Image`
	width: 242px;
	height: 54px;
	margin-bottom: 20px;
`;

export const StyledCopyright = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 14px;
	color: #b7b2c4;
	letter-spacing: -0.38px;
	text-align: center;
	line-height: 22px;
`;
export const StyledBold = styled.Text`
	font-family: ${fonts.Inter.Bold};
`;

export const StyledVersion = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 10px;
	color: #b7b2c4;
	letter-spacing: -0.38px;
	text-align: center;
	line-height: 19px;
`;
