import { Dimensions } from 'react-native';
import {
	getStatusBarHeight,
	getBottomSpace,
} from 'react-native-iphone-x-helper';

const fonts = {
	Inter: {
		Light: 'inter_light',
		Regular: 'inter_regular',
		SemiBold: 'inter_semibold',
		Bold: 'inter_bold',
	},
};

const colors = {
	dark: '#261B42',
	light: '#F8F8FF',
	primary: '#0D00B1',
	secondary: '#857D98',
	highlight: '#75FF25',
	border: '#E8E5EE',
};

const { width, height } = Dimensions.get('window');

const maxWidth = 520;

export const ui = {
	fonts,
	colors,
	maxWidth,
	width,
	height,
	statusBarHeight: getStatusBarHeight(true),
	bottomSpace: getBottomSpace(),
};
