import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { HabitCategoryKey } from '@newstart/engine';
import { GuidesScreen, GuideContentModal, GuideFaqModal } from '../screens';

export type GuidesStackParamList = {
	Guides_Screen: undefined;
	Guides_Content_Modal: { category: HabitCategoryKey; title: string };
	Guides_Faq_Modal: undefined;
};

const Stack = createNativeStackNavigator<GuidesStackParamList>();

export const GuidesNavigator = () => {
	return (
		<Stack.Navigator initialRouteName="Guides_Screen">
			<Stack.Group screenOptions={{ headerShown: false }}>
				<Stack.Screen name="Guides_Screen" component={GuidesScreen} />
			</Stack.Group>
			<Stack.Group
				screenOptions={{
					presentation: 'modal',
					headerShown: false,
				}}
			>
				<Stack.Screen
					name="Guides_Content_Modal"
					component={GuideContentModal}
				/>
				<Stack.Screen name="Guides_Faq_Modal" component={GuideFaqModal} />
			</Stack.Group>
		</Stack.Navigator>
	);
};
