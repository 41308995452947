import { useContext } from 'react';
import { EngineContext } from './EngineContext';

export const useEngine = () => {
	const context = useContext(EngineContext);

	if (!context) {
		throw new Error('useEngine must be used within an EngineProvider.');
	}

	return context;
};
