import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledElevationContainer = styled.View``;

export const StyledElevationButtonsContainer = styled.View`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 6px;
`;

export const StyledElevationLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 14px;
	color: rgba(60, 60, 67, 0.3);
	letter-spacing: 0.31px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 6px;
`;

export const StyledElevationButtonContainer = styled.TouchableOpacity.attrs({
	activeOpacity: 1,
})`
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 6px;
	width: 60px;
`;

export const StyledElevationButton = styled.View<{
	selected?: boolean;
	disabled?: boolean;
}>`
	width: 32px;
	height: 32px;
	border-radius: 16px;
	justify-content: center;
	align-items: center;
	margin: 2px;
	background-color: ${(props) =>
		props.selected
			? ui.colors.light
			: props.disabled
			? 'rgba(0, 0, 0, 0.02)'
			: 'rgba(0, 0, 0, 0.06)'};
`;

export const StyledElevationButtonIcon = styled.Image<{
	selected?: boolean;
	disabled?: boolean;
}>`
	opacity: ${(props) => (props.selected ? 1 : props.disabled ? 0.2 : 0.5)};
`;

export const StyledElevationButtonLabel = styled.Text<{
	selected?: boolean;
	disabled?: boolean;
}>`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 11px;
	text-transform: uppercase;
	margin-top: 2px;
	opacity: ${({ selected }) => (selected ? 1 : 0.6)};
`;

export const StyledConversionInfo = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 14px;
	color: ${ui.colors.dark};
	letter-spacing: -0.38px;
	text-align: center;
	text-transform: uppercase;
`;
