import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import 'moment/locale/pt';
import 'moment/locale/fr';

export const humanDate = (createdAt: Date) => {
	return moment
		.utc(createdAt)
		.locale(Localization.locale)
		.tz(Localization.timezone)
		.fromNow();
};
