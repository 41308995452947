import React from 'react';

import { TitleContainer, StyledLabel, StyledLabelBig } from './styles';

export const Title: React.FunctionComponent<{
	big?: Boolean;
	rightAction?: React.ReactNode;
}> = ({ big, children, rightAction, ...props }) => {
	return (
		<TitleContainer {...props}>
			{big ? (
				<StyledLabelBig>{children}</StyledLabelBig>
			) : (
				<StyledLabel>{children}</StyledLabel>
			)}
		</TitleContainer>
	);
};
