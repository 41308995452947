import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import LottieView from 'lottie-react-native';
import { ui } from '@newstart/ui';

export const StyledAnimation = styled(LottieView)`
	width: ${Dimensions.get('window').width - 30}px;
	height: 300px;
	margin-top: 30px;
`;

export const StyledBody = styled.View`
	margin-top: -60px;
	padding: 0 35px 40px 35px;
	align-items: center;
`;

export const StyledAchievementIntro = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	color: ${ui.colors.light};
	font-size: 18px;
	letter-spacing: -0.5px;
`;

export const StyledAchievementPoints = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	color: #ffe24d;
	font-size: 60px;
	letter-spacing: -1.65px;
`;

export const StyledAchievementEnd = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	color: #ffe24d;
	font-size: 24px;
	letter-spacing: -0.66px;
	margin-bottom: 20px;
`;

export const StyledChallengeLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	color: ${ui.colors.light};
	font-size: 18px;
	letter-spacing: -0.5px;
`;

export const StyledChallengeTitle = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	color: ${ui.colors.light};
	font-size: 20px;
	letter-spacing: -0.5px;
	text-align: center;
`;
