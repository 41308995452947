import React, { useMemo } from 'react';
import i18n from 'i18n-js';
import { ExerciseType } from '@newstart/engine';
import { StyledQuestion, StyledQuestionStrong } from './styles';

type UseFormContentProps = {
	exerciseType: ExerciseType;
	steps: number;
	isEditing?: boolean;
};

export const useFormatting = ({
	exerciseType,
	steps,
	isEditing = false,
}: UseFormContentProps) => {
	const question = useMemo((): React.ReactNode => {
		switch (exerciseType.key) {
			case 'WALKING':
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>
							{i18n.t('steps', { count: 2 })}
						</StyledQuestionStrong>{' '}
						{i18n.t('did-you-take-today')}
					</StyledQuestion>
				);
			case 'RUNNING':
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>
							{i18n.t('steps', { count: 2 })}
						</StyledQuestionStrong>{' '}
						{i18n.t('did-you-run-today')}
					</StyledQuestion>
				);
			case 'CYCLING':
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>{i18n.t('kilometers')}</StyledQuestionStrong>{' '}
						{i18n.t('did-you-ride-today')}
					</StyledQuestion>
				);
			case 'ELETRIC_CYCLING':
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>{i18n.t('kilometers')}</StyledQuestionStrong>{' '}
						{i18n.t('did-you-ride-today')}
					</StyledQuestion>
				);
			case 'SWIMMING':
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>
							{i18n.t('meters', { count: 2 })}
						</StyledQuestionStrong>{' '}
						{i18n.t('did-you-swim-today')}
					</StyledQuestion>
				);
			case 'SNOWSHOE':
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>
							{i18n.t('steps', { count: 2 })}
						</StyledQuestionStrong>{' '}
						{i18n.t('did-you-run-today')}
					</StyledQuestion>
				);
			default:
				return (
					<StyledQuestion>
						{i18n.t('how-many')}{' '}
						<StyledQuestionStrong>{i18n.t('minutes')}</StyledQuestionStrong>{' '}
						{i18n.t('did-you-do-this-exercise')}
					</StyledQuestion>
				);
		}
	}, [exerciseType.key, steps]);

	const buttonLabel = useMemo((): string => {
		return steps > 0
			? `${isEditing ? i18n.t('edit') : i18n.t('btn-register')} ${i18n.toNumber(
					steps,
					{ precision: 0, delimiter: ' ' }
			  )} ${i18n.t('points')}`
			: `${isEditing ? i18n.t('edit') : i18n.t('btn-register')}`;
	}, [steps]);

	const inputPlaceholder = useMemo(() => {
		if (exerciseType.unit === 'steps') {
			return i18n.t('steps', { count: 2 });
		}
		if (exerciseType.unit === 'km') {
			return i18n.t('km');
		}
		if (exerciseType.unit === 'min') {
			return i18n.t('min');
		}
		if (exerciseType.unit === 'meters') {
			return i18n.t('meters', { count: 2 });
		}
	}, [exerciseType.unit]);

	return {
		question,
		buttonLabel,
		inputPlaceholder,
	};
};
