import {
	HttpClient,
	httpErrorHandler,
	AxiosHttpClient,
	API_URL,
} from '@newstart/core';
import { AuthToken } from '@newstart/auth';

class RankingService {
	constructor(private _httpClient: HttpClient) {}

	async getData(token: AuthToken): Promise<RankingData> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.get<RankingData>('/me/ranking', {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}
}

export const getRankingService = () => {
	return new RankingService(new AxiosHttpClient(API_URL));
};

type RankingData = {
	userAgeGroup: string;
	userGender: 'M' | 'F';
	byAge: IndividualRankingEntry[];
	byGender: IndividualRankingEntry[];
	general: GeneralRankingEntry[];
	teams: {
		general: TeamRankingEntry[];
		byMyTeam: GeneralRankingEntry[];
		byTeamSize: TeamRankingEntry[];
		teamSize: number;
	};
};

export type IndividualRankingEntry = {
	_sum: {
		points: number;
	};
	userId: string;
	user?: {
		id: string;
		firstName: string;
		lastName: string;
		photoUrl: string;
		username: string;
		points: number;
	};
};

export type GeneralRankingEntry = {
	id: string;
	points: number;
	firstName: string;
	lastName: string;
	username: string;
	teamId: string;
};

export type TeamRankingEntry = {
	id: string;
	name: string;
	members: number;
	points: number;
};
