import styled from 'styled-components/native';
import { ThemedHeaderProps } from '../types';
import { ui } from '../../styles';

export const StyledHeadingContainer = styled.View`
	padding: 20px;
`;

export const StyledSectionTitle = styled.Text<ThemedHeaderProps>`
	font-family: ${ui.fonts.Inter.SemiBold};
	font-size: 20px;
	color: ${(props) =>
		props.theme === 'dark' ? ui.colors.light : ui.colors.dark};
	letter-spacing: -0.44px;
`;

export const StyledSectionSubtitle = styled.Text<ThemedHeaderProps>`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: ${(props) =>
		props.theme === 'dark' ? 'rgba(255,255,255,0.50)' : ui.colors.dark};
	letter-spacing: -0.44px;
	text-transform: uppercase;
`;
