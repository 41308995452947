import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';
import { ui } from '@newstart/ui';
import { HabitBubbleProps } from './interfaces';
const { fonts, colors } = ui;

export const StyledButton = styled.TouchableOpacity`
	position: absolute;
`;

export const StyledTitle = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 22px;
	letter-spacing: -0.76px;
	text-align: center;
	padding: 10px 3px;
`;

export const StyledStepsLabel = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 14px;
	color: white;
	letter-spacing: -0.38px;
	text-transform: uppercase;
`;

export const StyledStepsValue = styled.Text<{ filled: boolean }>`
	font-family: ${fonts.Inter.Bold};
	font-size: 24px;
	color: ${({ filled }) => (filled ? colors.dark : '#75FF25')};
	letter-spacing: -0.83px;
	text-align: center;
`;

export const StyledContainerTransparent = styled.View<
	Pick<HabitBubbleProps, 'size'>
>`
	width: ${({ size }) => (size ? size : 200)}px;
	height: ${({ size }) => (size ? size : 200)}px;
	min-width: 200px;
	min-height: 200px;
	border-radius: ${({ size }) => (size ? size / 2 : 100)}px;
	background: rgba(255, 255, 255, 0.09);
	border: 1px solid rgba(255, 255, 255, 0.24);
	justify-content: center;
	align-items: center;
	padding: 20px;
`;

export const StyledContainerFilled = styled(LinearGradient).attrs({
	start: { x: 0.9, y: 0.2 },
	end: { x: 0, y: 1.3 },
})<Pick<HabitBubbleProps, 'size'>>`
	width: ${({ size }) => (size ? size : 200)}px;
	height: ${({ size }) => (size ? size : 200)}px;
	min-width: 200px;
	min-height: 200px;
	border-radius: ${({ size }) => (size ? size / 2 : 100)}px;
	justify-content: center;
	align-items: center;
	padding: 20px;
`;

const iconContainerSize = 48;

export const StyledIconContainerTransparent = styled.View`
	width: ${iconContainerSize}px;
	height: ${iconContainerSize}px;
	border-radius: ${iconContainerSize / 2}px;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.4);
`;

export const StyledIconContainerFilled = styled(LinearGradient).attrs({
	start: { x: 0.9, y: 0.2 },
	end: { x: 0, y: 1.3 },
})`
	width: ${iconContainerSize}px;
	height: ${iconContainerSize}px;
	border-radius: ${iconContainerSize / 2}px;
	justify-content: center;
	align-items: center;
`;
