import { MaterialIcons } from '@expo/vector-icons';
import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledEmptyContainer = styled.View`
	flex: 1;
	position: relative;
`;

export const StyledLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 25px;
	color: #ff9467;
	letter-spacing: -0.69px;
	text-align: center;
	margin-bottom: 30px;
`;

export const StyledShareIcon = styled(MaterialIcons).attrs({
	name: 'group-add',
	size: 28,
	color: ui.colors.dark,
})`
	left: 10px;
`;

export const StyledBubbleInner = styled.View`
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	padding: 60px 60px 80px 60px;
	z-index: 1;
`;

export const StyledBubble = styled.View`
	background: rgba(255, 255, 255, 0.09);
	border: 1px solid rgba(255, 255, 255, 0.24);
	width: 620px;
	height: 620px;
	border-radius: 310px;
	position: absolute;
	margin-left: -310px;
	bottom: -80px;
	left: 50%;
	z-index: 0;
`;
