import React from 'react';
import { ThemedHeaderProps } from '../types';

import { StyledHeader, StyledTitle } from './styles';

export const ScreenHeader: React.FunctionComponent<
	ThemedHeaderProps & {
		title: string;
		align?: 'left' | 'center';
	}
> = ({ title, theme = 'dark', align = 'left' }) => {
	return (
		<StyledHeader align={align}>
			<StyledTitle theme={theme} numberOfLines={1}>
				{title}
			</StyledTitle>
		</StyledHeader>
	);
};
