import React from 'react';
import { BaseButton, BaseButtonProps } from './BaseButton';
import { ui } from '../styles';

type BlackButtonProps = Omit<
	BaseButtonProps,
	'backgroundColor' | 'textSize' | 'textColor'
>;

export const BlackButton: React.FunctionComponent<BlackButtonProps> = ({
	...props
}) => {
	return (
		<BaseButton
			backgroundColor={ui.colors.dark}
			textColor={ui.colors.light}
			{...props}
		/>
	);
};
