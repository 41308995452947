import React, { useState, useEffect } from 'react';
import {
	View,
	FlatList,
	Platform,
	ActivityIndicator,
	ListRenderItem,
} from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SearchBar } from 'react-native-elements';

import { Avatar, Loading, ui, useMessage } from '@newstart/ui';
import { useAuth } from '@newstart/auth';

import { getAdminService, UserListItem } from '../../services';
import {
	StyledContainer,
	StyledItemContainer,
	StyledName,
	StyledArrow,
	StyledSearchBarContainer,
} from './styles';
import { AdminStackParamList } from '../../navigation';

const adminService = getAdminService();

type Props = NativeStackScreenProps<
	AdminStackParamList,
	'Admin_Users_List_Screen'
>;

export const UsersListScreen: React.FunctionComponent<Props> = ({
	navigation,
}) => {
	const { token } = useAuth();
	const { showError } = useMessage();
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(true);
	const [filteredDataSource, setFilteredDataSource] = useState<UserListItem[]>(
		[]
	);
	const [masterDataSource, setMasterDataSource] = useState<UserListItem[]>([]);

	useEffect(() => {
		if (!token) return;
		(async function fetchUsers() {
			try {
				const users = await adminService.listUsers(token);
				setFilteredDataSource(users);
				setMasterDataSource(users);
				setLoading(false);
			} catch (error) {
				showError(error);
			}
		})();
	}, [token, showError]);

	function renderName(firstName: string, lastName: string, username?: string) {
		return username || `${firstName} ${lastName}`;
	}

	const searchFilterFunction = (text: string) => {
		if (text) {
			// Filter the masterDataSource
			// Update FilteredDataSource
			const newData = masterDataSource.filter(function (item) {
				let fullName = renderName(item.firstName, item.lastName, item.username);
				const itemData = fullName ? fullName.toUpperCase() : ''.toUpperCase();
				const textData = text.toUpperCase();
				return itemData.indexOf(textData) > -1;
			});
			setFilteredDataSource(newData);
			setSearch(text);
		} else {
			// Inserted text is blank
			// Update FilteredDataSource with masterDataSource
			setFilteredDataSource(masterDataSource);
			setSearch(text);
		}
	};

	const renderUserItem: ListRenderItem<UserListItem> = ({ item }) => {
		return (
			<StyledItemContainer
				onPress={() =>
					navigation.navigate('Admin_User_Detail_Modal', { user: item })
				}
			>
				<Avatar photoUrl={item.photoUrl} size={36} />
				<StyledName>
					{renderName(item.firstName, item.lastName, item.username)}
				</StyledName>
				<StyledArrow />
			</StyledItemContainer>
		);
	};

	const ItemSeparatorView = () => {
		return (
			<View
				style={{
					height: 0.5,
					width: '94%',
					backgroundColor: '#EAE9F4',
					alignSelf: 'flex-end',
				}}
			/>
		);
	};

	return (
		<StyledContainer>
			<StyledSearchBarContainer>
				<SearchBar
					round
					searchIcon={{ size: 24 }}
					onChangeText={searchFilterFunction}
					value={search}
					autoCapitalize="none"
					autoCorrect={false}
					autoFocus={true}
					containerStyle={{
						flex: 1,
						backgroundColor: ui.colors.light,
						paddingTop: 6,
						paddingBottom: 0,
						borderColor: ui.colors.light,
						borderTopWidth: 0,
						borderBottomWidth: 0,
					}}
					inputContainerStyle={{
						backgroundColor: '#E6E5EF',
						height: 40,
					}}
				/>
			</StyledSearchBarContainer>

			{loading ? (
				<Loading />
			) : (
				<FlatList<UserListItem>
					data={filteredDataSource}
					keyExtractor={(_, index) => index.toString()}
					ItemSeparatorComponent={ItemSeparatorView}
					renderItem={renderUserItem}
				/>
			)}
		</StyledContainer>
	);
};
