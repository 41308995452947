import { LocalStorageClient } from '@newstart/core';
import { useCallback, useState } from 'react';

const storageClient = new LocalStorageClient();

export type Flag = 'user_updated' | 'walkthrough_completed';

type UseFlagsProps = {
	flags: Flags | null;
	loadFlags: () => Promise<void>;
	getFlag: (flag: Flag) => boolean;
	setFlag: (flag: Flag) => Promise<void>;
	clearFlags: () => Promise<void>;
};

export type Flags = Record<Flag, boolean>;

export const useFlags = (): UseFlagsProps => {
	const [flags, setFlags] = useState<Flags | null>(null);

	const loadFlags = useCallback(async () => {
		try {
			setFlags(await storageClient.get<Flags>('@newstart:flags'));
		} catch (error) {
			console.warn(error);
		}
	}, [setFlags]);

	const getFlag = useCallback(
		(flag: Flag) => {
			return flags?.[flag] ?? false;
		},
		[flags]
	);

	const setFlag = useCallback(
		async (flag: Flag) => {
			setFlags(
				(prev) =>
					({
						...prev,
						[flag]: true,
					} as Flags)
			);
			await storageClient.set('@newstart:flags', {
				...flags,
				[flag]: true,
			});
		},
		[setFlags, flags]
	);

	const clearFlags = useCallback(async () => {
		setFlags(null);
		await storageClient.delete('@newstart:flags');
	}, []);

	return {
		flags,
		loadFlags,
		getFlag,
		setFlag,
		clearFlags,
	};
};
