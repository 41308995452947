import { useMemo } from 'react';
import { UserHabitAchievement } from '@newstart/engine';
import { ui } from '@newstart/ui';
import {
	HabitBubbleProps,
	UseDailyHabitStateProps,
	UseDailyHabitsStateReturn,
} from './types';
import {
	filterDailyHabits,
	filterDailyPerformance,
	isHabitAchievement,
} from './utils';
const { width, maxWidth } = ui;

export const useDailyHabitState = ({
	selectedIndex,
	habits,
	userPerformance,
	userAchievements,
}: UseDailyHabitStateProps): UseDailyHabitsStateReturn => {
	const { dailyHabits, dailyUserPerformance } = useMemo(() => {
		const dailyHabits = filterDailyHabits(selectedIndex, habits);
		const dailyUserPerformance = filterDailyPerformance(
			selectedIndex,
			dailyHabits,
			userPerformance
		);
		return {
			dailyHabits,
			dailyUserPerformance,
		};
	}, [selectedIndex, userPerformance]);

	const userHabitAchievements: UserHabitAchievement[] = useMemo(() => {
		return userAchievements.filter((achievement) =>
			isHabitAchievement(achievement)
		) as UserHabitAchievement[];
	}, [userAchievements]);

	const habitBubbles: HabitBubbleProps[] = useMemo(
		() =>
			dailyHabits.map((habit, i) => ({
				habit,
				userPoints:
					dailyUserPerformance.find((per) => per.habitId === habit.id)?._sum
						.points ?? 0,
				achievementId:
					userHabitAchievements.find(
						(achievement) =>
							achievement?.habitId === habit.id &&
							achievement.createdAt === selectedIndex
					)?.id ?? undefined,
				styles: bubbleOrderedStyles[i],
			})),
		[dailyHabits, dailyUserPerformance, userHabitAchievements]
	);

	const minHeightBubblesContainer = useMemo(() => {
		if (habitBubbles.length > 0) {
			const { styles } = habitBubbles[habitBubbles.length - 1];
			return styles.top + styles.size + 40;
		}
		return 0;
	}, [habitBubbles]);

	return {
		habitBubbles,
		minHeightBubblesContainer,
	};
};

const realWidth = width > maxWidth ? maxWidth : width;
const diff = (realWidth - 390) / 2;

export const bubbleOrderedStyles = [
	{
		size: 290,
		top: 0,
		left: -25 + diff,
	},
	{
		size: 200,
		top: 216,
		right: -10 + diff,
	},
	{
		size: 220,
		top: 292,
		left: -8 + diff,
	},
	{
		size: 210,
		top: 420,
		right: 0 + diff,
	},
	{
		size: 220,
		top: 516,
		left: -20 + diff,
	},
	{
		size: 226,
		top: 635,
		right: -10 + diff,
	},
	{
		size: 220,
		top: 740,
		left: -26 + diff,
	},
	{
		size: 220,
		top: 863,
		right: -15 + diff,
	},
	{
		size: 200,
		top: 962,
		left: 0 + diff,
	},
	{
		size: 200,
		top: 1086,
		right: 6 + diff,
	},
	{
		size: 220,
		top: 1166,
		left: -20 + diff,
	},
	{
		size: 220,
		top: 1290,
		right: 2 + diff,
	},
];
