import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
const { colors, fonts, maxWidth, statusBarHeight } = ui;

export const StyledHeader = styled.View`
	width: 100%;
	max-width: ${maxWidth}px;
	padding-bottom: 14px;
	padding-top: ${statusBarHeight}px;
	align-self: center;
`;

export const StyledStatsHeader = styled.View`
	padding: 36px 0 5px 0;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const StyledName = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 24px;
	color: ${colors.light};
	letter-spacing: -0.74px;
	text-align: center;
	margin-bottom: 5px;
`;

export const StyledTeam = styled.View`
	/* flex-direction: row; */
	align-items: center;
`;

export const StyledTeamName = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 16px;
	color: ${colors.highlight};
	letter-spacing: -0.44px;
	text-align: center;
`;

export const StyledShareButton = styled.TouchableOpacity`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: ${colors.highlight};
	border-radius: 4px;
	margin-top: 8px;
	height: 22px;
	padding: 0 10px;
`;

export const StyledShareButtonText = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 12px;
	color: ${colors.dark};
	margin-left: 6px;
`;

export const StyledShareWebText = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 12px;
	background-color: ${colors.highlight};
	color: ${colors.dark};
	border-radius: 4px;
	margin-top: 8px;
	padding: 4px 10px;
`;

export const StyledStats = styled.View`
	flex-direction: column;
	align-items: center;
	flex: 1;
`;

export const StyledStatsLabel = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 14px;
	color: rgba(255, 255, 255, 0.5);
	letter-spacing: -0.33px;
	text-align: center;
`;

export const StyledStatsValue = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 18px;
	color: ${colors.light};
	letter-spacing: -0.41px;
	text-align: center;
`;

export const StyledProfilePictureContainer = styled.View`
	border-color: ${colors.highlight};
	border-width: 2px;
	border-radius: 80px;
	align-items: center;
	justify-content: center;
	padding: 4px;
`;

export const StyledProfilePicture = styled.ImageBackground`
	width: 115px;
	height: 115px;
	border-radius: 58px;
	overflow: hidden;
`;
