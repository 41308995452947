import { ui } from "@newstart/ui"
import styled from "styled-components/native"

export const StyledDay = styled.View<{
  isToday: boolean
  isFuture: boolean
  isPast: boolean
  isSelected: boolean
}>`
  width: 60px;
  height: 70px;
  padding: ${({ isSelected }) => (isSelected ? 10 : 12)}px 10px;
  margin-right: 10px;
  border-width: ${({ isToday }) => (isToday ? 4 : 1)}px;
  border-color: ${({ isSelected, isFuture, isToday }) =>
    isSelected
      ? "white"
      : isFuture
      ? "rgba(255,255,255,0.3)"
      : "rgba(255,255,255,1)"};
  border-radius: 17px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ isSelected }) =>
    isSelected ? ui.colors.light : "transparent"};
`

export const StyledEmoji = styled.Text`
  font-size: 32px;
  margin-right: 8px;
`

export const StyledDayWeek = styled.Text<{
  isSelected: boolean
  isToday: boolean
  isFuture: boolean
}>`
  font-family: ${ui.fonts.Inter.Light};
  color: ${({ isSelected }) => (isSelected ? ui.colors.dark : ui.colors.light)};
  opacity: ${({ isFuture }) => (isFuture ? 0.3 : 1)};
  font-size: 15px;
  text-transform: capitalize;
`

export const StyledDayText = styled.Text<{
  isToday: boolean
  isSelected: boolean
  isFuture: boolean
}>`
  font-family: ${ui.fonts.Inter.SemiBold};
  color: ${({ isSelected }) => (isSelected ? ui.colors.dark : ui.colors.light)};
  font-size: 19px;
  opacity: ${({ isFuture }) => (isFuture ? 0.3 : 1)};
`
