import React from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import {
	connectActionSheet,
	useActionSheet,
} from '@expo/react-native-action-sheet';
import i18n from 'i18n-js';
import {
	Avatar,
	BaseModalContainer,
	ScreenSection,
	useMessage,
} from '@newstart/ui';
import { useAuth } from '@newstart/auth';

import { AdminStackParamList } from '../../navigation';
import { AchievementList } from '../../components';
import { getAdminService } from '../../services';
import {
	StyledButton,
	StyledButtonDangerLabel,
	StyledButtonEmailIcon,
	StyledButtonEmailLabel,
	StyledButtonLabel,
	StyledButtonsContainer,
	StyledEmail,
	StyledHeader,
	StyledName,
} from './styles';

const adminService = getAdminService();

type UserDetailModalProps = NativeStackScreenProps<
	AdminStackParamList,
	'Admin_User_Detail_Modal'
>;
export const UserDetailModal: React.FunctionComponent<UserDetailModalProps> =
	connectActionSheet(({ navigation, route }) => {
		const { user } = route.params;
		const { token } = useAuth();
		const { showError, showSuccess } = useMessage();
		const { showActionSheetWithOptions } = useActionSheet();

		const handleBanUser = () => {
			let options = [
				i18n.t('confirm'), // 0
				i18n.t('cancel'), // 1
			];
			let destructiveButtonIndex = 0;
			let cancelButtonIndex = 1;

			showActionSheetWithOptions(
				{
					options,
					cancelButtonIndex,
					destructiveButtonIndex,
					title: i18n.t('are-you-sure'),
					message: i18n.t('this-action-cannot-be-undone'),
				},
				async (buttonIndex) => {
					if (buttonIndex === 0) {
						if (!token) return;
						try {
							await adminService.banUser(user.id, token);
							navigation.goBack();
							showSuccess(i18n.t('user-ban-success'));
						} catch (error) {
							showError(error);
						}
					}
				}
			);
		};

		return (
			<BaseModalContainer onClose={() => navigation.goBack()}>
				<StyledHeader>
					<Avatar
						photoUrl={user.photoUrl}
						size={60}
						style={{ marginRight: 14 }}
					/>
					<View>
						<StyledName>{`${user.firstName} ${user.lastName}`}</StyledName>
						<StyledEmail>{user.email}</StyledEmail>
					</View>
				</StyledHeader>

				<StyledButtonsContainer>
					{user.team && (
						<StyledButton>
							<StyledButtonLabel numberOfLines={1}>
								{user.team.name}
							</StyledButtonLabel>
						</StyledButton>
					)}
					<StyledButton
						onPress={() => {
							Linking.openURL(`mailto:${user.email}`);
						}}
					>
						<StyledButtonEmailIcon />
						<StyledButtonEmailLabel numberOfLines={1}>
							{i18n.t('new-email')}
						</StyledButtonEmailLabel>
					</StyledButton>
				</StyledButtonsContainer>
				<StyledButtonsContainer style={{ marginTop: 0 }}>
					<StyledButton onPress={handleBanUser}>
						<StyledButtonDangerLabel numberOfLines={1}>
							{i18n.t('ban-user')}
						</StyledButtonDangerLabel>
					</StyledButton>
				</StyledButtonsContainer>

				<ScreenSection title={i18n.t('history')} dark>
					<AchievementList userId={user.id} />
				</ScreenSection>
			</BaseModalContainer>
		);
	});
