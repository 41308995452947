import React from 'react';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { Challenge } from '@newstart/engine';
import { ui } from '@newstart/ui';
import i18n from 'i18n-js';
import moment from 'moment-timezone';

import {
	StyledChallengeContainer,
	StyledChallengeBackground,
	StyledChallengeTextBold,
	StyledChallengeTextRegular,
	StyledChallengeMetaContainer,
	StyledChallengeMetaLine,
} from './styles';

type ChallengeBubbleProps = {
	startDate: string;
	challenge: Challenge;
};

export const ChallengeBubble: React.FunctionComponent<ChallengeBubbleProps> = ({
	startDate,
	challenge,
}) => {
	const points = challenge.rewardsForTeam
		? challenge.rewardsForTeam
		: challenge.rewardsForUser;

	const untilDate = moment(startDate).add(challenge.endAt + 1, 'days');

	return (
		<StyledChallengeContainer>
			<StyledChallengeBackground>
				<StyledChallengeTextBold>{challenge.title}</StyledChallengeTextBold>
				<StyledChallengeTextRegular>
					{challenge.description}
				</StyledChallengeTextRegular>
				<StyledChallengeMetaContainer>
					<StyledChallengeMetaLine>
						<MaterialIcons
							name="access-alarms"
							size={20}
							color={ui.colors.dark}
							style={{ marginRight: 8 }}
						/>
						<StyledChallengeTextBold>
							{i18n.t('until-day')} {untilDate.format('Do MMMM')}
						</StyledChallengeTextBold>
					</StyledChallengeMetaLine>
					<StyledChallengeMetaLine>
						<FontAwesome
							name="trophy"
							size={20}
							color={ui.colors.dark}
							style={{ marginRight: 8 }}
						/>
						<StyledChallengeTextBold>
							{i18n.toNumber(points, { precision: 0, delimiter: ' ' })}{' '}
							{i18n.t('steps', { count: 3 })}
						</StyledChallengeTextBold>
					</StyledChallengeMetaLine>
				</StyledChallengeMetaContainer>
			</StyledChallengeBackground>
		</StyledChallengeContainer>
	);
};
