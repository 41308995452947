import styled from 'styled-components/native';
import { Entypo } from '@expo/vector-icons';
import { BaseButtonProps } from './BaseButton';
import { ui } from '../styles';
const { fonts } = ui;

export const StyledButton = styled.TouchableOpacity.attrs({
	activeOpacity: 0.8,
})<Pick<BaseButtonProps, 'backgroundColor' | 'disabled'>>`
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	width: 100%;
	background-color: ${(props) => props.backgroundColor};
	margin: 10px 0;
	height: 52px;
	border-radius: 29px;
	justify-content: center;
	align-items: center;
	position: relative;
`;

export const StyledLabel = styled.Text<
	Pick<BaseButtonProps, 'textColor' | 'textSize'>
>`
	font-family: ${fonts.Inter.SemiBold};
	font-size: ${(props) => props.textSize}px;
	color: ${(props) => props.textColor};
	text-align: center;
`;

export const StyledIconContainer = styled.View`
	position: absolute;
	left: 10px;
`;

export const StyledArrow = styled(Entypo).attrs({
	name: 'chevron-right',
	size: 24,
	color: 'rgba(255,255,255, 0.5)',
})`
	position: absolute;
	right: 5px;
`;
