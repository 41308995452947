import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
import { Entypo, Feather, FontAwesome5 } from '@expo/vector-icons';
const { fonts, colors } = ui;

export const StyledLinkLabel = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 17px;
	color: ${colors.highlight};
	letter-spacing: -0.5px;
`;

export const StyledUserListContainer = styled.View`
	width: 100%;
	border-top-width: 1px;
	border-top-color: rgba(255, 255, 255, 0.09);
	border-bottom-width: 1px;
	border-bottom-color: rgba(255, 255, 255, 0.09);
	padding: 10px 0 10px 20px;
	margin-top: 20px;
`;

export const StyledUserListItem = styled.View`
	flex-direction: row;
	padding: 10px 20px 10px 0;
	border-bottom-width: 1px;
	border-bottom-color: rgba(255, 255, 255, 0.1);
	align-items: center;
`;

export const StyledName = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 18px;
	color: ${colors.light};
	letter-spacing: -0.5px;
`;

export const StyledLeaderTag = styled.View`
	background-color: ${colors.highlight};
	margin-left: 8px;
	height: 14px;
	padding: 1px 4px;
	border-radius: 7px;
`;

export const StyledLeaderTagText = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 9px;
	color: #432d78;
	letter-spacing: -0.25px;
	text-align: center;
	text-transform: uppercase;
`;

export const StyledDeleteIcon = styled(FontAwesome5).attrs({
	name: 'trash',
	size: 18,
	color: 'white',
})`
	opacity: 0.4;
`;

export const StyledShareButton = styled.TouchableOpacity`
	padding: 18px 0;
	justify-content: center;
	align-items: center;
`;

export const StyledShareIcon = styled(Feather).attrs({
	name: 'share',
	size: 20,
	color: colors.highlight,
})`
	margin-right: 4px;
`;

export const StyledRedLinkLabel = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 18px;
	color: #ff2e33;
	letter-spacing: -0.5px;
	text-align: center;
	margin-top: 20px;
`;

export const StyledArrow = styled(Entypo).attrs({
	name: 'chevron-small-right',
	size: 24,
	color: 'rgba(255,255,255,0.5)',
})`
	margin-left: auto;
`;
