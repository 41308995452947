import styled from "styled-components/native"
import { ui } from "@newstart/ui"

const { colors, fonts, maxWidth } = ui

export const CardContainer = styled.TouchableOpacity`
  width: 100%;
  max-width: ${maxWidth}px;
  height: 86px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin: 7.5px 0;
  align-self: center;
`

export const CardBackground = styled.ImageBackground`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 40px;
  width: 100%;
`

export const CardColor = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px 20px 40px;
  width: 100%;
  background-color: ${colors.primary};
`

export const CardText = styled.Text`
  font-family: ${fonts.Inter.Bold};
  font-size: 20px;
  color: ${colors.light};
`
