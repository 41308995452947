import React from "react"
import i18n from "i18n-js"
import AnimatedLottieView from "lottie-react-native"
import { BaseContainer, ScreenHeader, StatusBar } from "@newstart/ui"
import {
  StyledInfo,
  StyledInfoDate,
  StyledEmptyContainer,
  StyledBubbleInner,
  StyledBubble,
} from "./styles"

export const HabitScreenNotStarted = () => {
  return (
    <BaseContainer backgroundColor={["#30A5DA", "#0D00B1"]} safeArea>
      <ScreenHeader title="NewStart 80" />
      <StyledEmptyContainer>
        <StyledBubbleInner>
          <AnimatedLottieView
            source={require("@newstart/assets/lotties/waiting.json")}
            style={{ width: 220, height: 220 }}
            autoPlay
            loop
          />
          <StyledInfo>
            {i18n.t("the_new_start_Warning_has_not_yet_started")}
          </StyledInfo>
          <StyledInfoDate>{i18n.t("start_date")}: 2 avril 2023</StyledInfoDate>
        </StyledBubbleInner>
        <StyledBubble />
      </StyledEmptyContainer>
      <StatusBar style="inverted" />
    </BaseContainer>
  )
}
