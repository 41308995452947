import styled from 'styled-components/native';
import { ui } from '../../../ui/styles';

export const StyledHeader = styled.View<{ align: 'left' | 'center' }>`
	width: 100%;
	height: 60px;
	justify-content: flex-start;
	align-items: ${(props) => (props.align === 'left' ? 'flex-start' : 'center')};
	padding-top: 7px;
	padding-left: 20px;
	padding-right: 20px;
`;

export const StyledTitle = styled.Text<{ theme: 'dark' | 'light' }>`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 26px;
	color: ${(props) =>
		props.theme === 'dark' ? ui.colors.light : ui.colors.primary};
	letter-spacing: -0.9px;
	max-width: 245px;
`;
