import React, { useCallback } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import moment from "moment-timezone"
import * as Localization from "expo-localization"
import {
  useAuth,
  parseDateToDatabase,
  parseDateFromDatabase,
} from "@newstart/auth"
import { BaseContainer, useMessage, Form } from "@newstart/ui"
moment.locale(Localization.locale)

import { UpdateUserForm, UpdateUserFormValue } from "../../components"

import { OnboardingStackParamList } from "../../navigation"
import { StyledInfo } from "./styles"

type UpdateProfileScreenProps = NativeStackScreenProps<
  OnboardingStackParamList,
  "Onboarding_Update_Profile_Screen"
>

export const UpdateProfileScreen: React.FunctionComponent<
  UpdateProfileScreenProps
> = ({ navigation }) => {
  const { showError } = useMessage()
  const { loading, user, updateUser, signOut } = useAuth()

  if (!user) return null

  const handleSubmit = useCallback(
    async (values: UpdateUserFormValue) => {
      try {
        await updateUser({
          ...values,
          birthDate: parseDateToDatabase(values.birthDate),
          timezone: Localization.timezone,
        })
      } catch (error) {
        showError(error)
        await signOut()
      }
    },
    [showError]
  )

  if (loading) return null

  return (
    <BaseContainer>
      <Form>
        <StyledInfo>
          {user.firstName}, before proceeding, please provide updated
          information:
        </StyledInfo>
        <UpdateUserForm
          onSubmit={handleSubmit}
          defaultValue={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            birthDate: parseDateFromDatabase(user.birthDate),
            country: user.country ?? "FR",
            gender: user.gender,
            username: user.username,
            phone: user.phone,
          }}
        />
      </Form>
    </BaseContainer>
  )
}
