import { HttpHeaders, HttpClient } from './http';
import axios from 'axios';

/**
 * Implementação de cliente Http que utiliza a biblioteca axios.
 */
export class AxiosHttpClient implements HttpClient {
	constructor(private baseUrl: string) {}
	async get<ResponseType>(
		url: string,
		headers?: HttpHeaders
	): Promise<ResponseType> {
		const response = await axios({
			method: 'get',
			url: this.baseUrl + url.toString(),
			headers,
		});
		return response.data as ResponseType;
	}

	async post<ResponseType, DataType>(
		url: string,
		data: DataType,
		headers?: HttpHeaders
	): Promise<ResponseType> {
		const response = await axios({
			method: 'post',
			url: this.baseUrl + url.toString(),
			data,
			headers,
		});
		return response.data as ResponseType;
	}

	async put<ResponseType, DataType>(
		url: string,
		data: DataType,
		headers?: HttpHeaders
	): Promise<ResponseType> {
		const response = await axios({
			method: 'put',
			url: this.baseUrl + url.toString(),
			data,
			headers,
		});
		return response.data as ResponseType;
	}

	async delete<ResponseType, DataType = {}>(
		url: string,
		data?: DataType,
		headers?: HttpHeaders
	): Promise<ResponseType> {
		const response = await axios({
			method: 'delete',
			url: this.baseUrl + url.toString(),
			data,
			headers,
		});
		return response.data as ResponseType;
	}
}
