import { AuthToken } from '@newstart/auth';
import {
	AxiosHttpClient,
	HttpClient,
	httpErrorHandler,
	API_URL,
} from '@newstart/core';
import { UserNotification, Notification } from './types';

class NotificationService {
	constructor(private _httpClient: HttpClient) {}

	async findAll(token: AuthToken): Promise<UserNotification[]> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.get<UserNotification[]>(`/me/notifications`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}

	async getById(
		notificationId: string,
		token: AuthToken
	): Promise<Notification> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.get<Notification>(`/me/notifications/${notificationId}`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}

	async markAsRead(
		args: MarkAsReadRequest,
		token: AuthToken
	): Promise<SendNotificationResponse> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.post<SendNotificationResponse, {}>(
					`/me/notifications/${args.notificationId}/read`,
					{},
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}
}

export const getNotificationService = () => {
	return new NotificationService(new AxiosHttpClient(API_URL));
};

type MarkAsReadRequest = {
	notificationId: string;
};

type SendNotificationResponse = Omit<UserNotification, 'notification'>;
