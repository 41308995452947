import { LinearGradientPoint } from 'expo-linear-gradient';
import React, { useMemo } from 'react';
import { ViewProps } from 'react-native';
import {
	StyledBaseContainer,
	StyledCloseContainer,
	StyledCloseIcon,
	StyledGradientContainer,
	StyledImageBackgroundContainer,
	StyledTitleModalContainer,
	StyledTitleModalText,
} from './styles';
import { ui } from '../../styles';

type Props = ViewProps & {
	onClose: () => void;
	backgroundColor?: string[] | string;
	backgroundImage?: number;
	title?: string;
	titleColor?: string;
	start?: LinearGradientPoint | null;
	end?: LinearGradientPoint | null;
	dark?: boolean;
	safeArea?: boolean;
};

export const BaseModalContainer: React.FunctionComponent<Props> = ({
	onClose,
	backgroundColor,
	backgroundImage,
	children,
	title,
	titleColor,
	dark,
	safeArea = true,
	...props
}) => {
	const close = () => (
		<StyledCloseContainer onPress={onClose}>
			<StyledCloseIcon color={dark ? ui.colors.light : ui.colors.dark} />
		</StyledCloseContainer>
	);

	const renderTitle = () => (
		<StyledTitleModalContainer>
			<StyledTitleModalText dark={dark} titleColor={titleColor}>
				{title}
			</StyledTitleModalText>
		</StyledTitleModalContainer>
	);

	if (backgroundImage) {
		return (
			<StyledImageBackgroundContainer
				source={backgroundImage}
				safeArea={safeArea}
				{...props}
			>
				{close()}
				{title && renderTitle()}
				{children}
			</StyledImageBackgroundContainer>
		);
	}

	if (Array.isArray(backgroundColor)) {
		return (
			<StyledGradientContainer
				colors={backgroundColor}
				safeArea={safeArea}
				{...props}
			>
				{close()}
				{title && renderTitle()}
				{children}
			</StyledGradientContainer>
		);
	}

	return (
		<StyledBaseContainer
			backgroundColor={backgroundColor}
			safeArea={safeArea}
			{...props}
		>
			{close()}
			{title && renderTitle()}
			{children}
		</StyledBaseContainer>
	);
};
