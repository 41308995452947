import React, { useMemo } from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import i18n from "i18n-js"
import { ui } from "@newstart/ui"
import { useAuth } from "@newstart/auth"
import {
  JoinTeamScreen,
  OnboardingScreen,
  WalkthroughScreen,
  CreateTeamScreen,
  UpdateProfileScreen,
} from "../screens"

export type OnboardingStackParamList = {
  Onboarding_Update_Profile_Screen: undefined
  Onboarding_Walkthrough_Screen: undefined
  Onboarding_Screen: undefined
  Onboarding_Join_Team_Modal: undefined
  Onboarding_Create_Team_Modal: undefined
}

const Stack = createNativeStackNavigator<OnboardingStackParamList>()

const screenOptions = {
  headerTintColor: ui.colors.primary,
  headerBackTitleVisible: false,
  headerStyle: { backgroundColor: ui.colors.light },
  headerLargeTitle: false,
  headerLargeStyle: { backgroundColor: ui.colors.light },
}

export const OnboardingNavigator = () => {
  const { loading, flags, getFlag } = useAuth()

  if (loading) return null

  return (
    <Stack.Navigator>
      {getFlag("user_updated") ? (
        getFlag("walkthrough_completed") ? (
          <>
            <Stack.Screen
              name="Onboarding_Screen"
              component={OnboardingScreen}
              options={{ headerShown: false }}
            />
            <Stack.Group screenOptions={screenOptions}>
              <Stack.Screen
                name="Onboarding_Join_Team_Modal"
                component={JoinTeamScreen}
                options={{ title: i18n.t("join-the-team") }}
              />
              <Stack.Screen
                name="Onboarding_Create_Team_Modal"
                component={CreateTeamScreen}
                options={{ title: i18n.t("create-your-team") }}
              />
            </Stack.Group>
          </>
        ) : (
          <Stack.Screen
            name="Onboarding_Walkthrough_Screen"
            component={WalkthroughScreen}
            options={{ headerShown: false }}
          />
        )
      ) : (
        <Stack.Screen
          name="Onboarding_Update_Profile_Screen"
          component={UpdateProfileScreen}
          options={{ title: i18n.t("update-profile"), ...screenOptions }}
        />
      )}
    </Stack.Navigator>
  )
}
