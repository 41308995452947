import styled from 'styled-components/native';
import { ui } from '@newstart/ui';

export const StyledIntroDetails = styled.View`
	padding: 20px;
	justify-content: center;
	align-items: center;
`;

export const StyledIntroDetailLabel = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 16px;
	color: ${ui.colors.light};
	opacity: 0.6;
	letter-spacing: -0.44px;
	text-transform: uppercase;
	margin-top: 10px;
`;

export const StyledIntroDetailValue = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 18px;
	color: ${ui.colors.light};
	letter-spacing: -0.35px;
	text-align: center;
	line-height: 24px;
	margin-bottom: 10px;
`;
