import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import {
	StyledButtonContainer,
	StyledButtonBackground,
	StyledLabel,
	StyledIconContainer,
	StyledArrow,
} from './styles';

type GradientBtnProps = TouchableOpacityProps & {
	icon: React.ReactElement;
	title: string;
	iconContainerColor?: string;
	colorOne?: string;
	colorTwo?: string;
	textColor?: string;
};

export const GradientBtn = ({
	icon,
	iconContainerColor,
	title,
	onPress,
	colorOne = '#E2F4EF',
	colorTwo = '#BBB5CA',
	textColor = '#27252F',
	...props
}: GradientBtnProps) => {
	return (
		<StyledButtonContainer onPress={onPress} {...props}>
			<StyledButtonBackground colors={[colorOne, colorTwo]}>
				<StyledIconContainer color={iconContainerColor}>
					{icon}
				</StyledIconContainer>
				<StyledLabel color={textColor}>{title}</StyledLabel>
				<StyledArrow color={textColor} />
			</StyledButtonBackground>
		</StyledButtonContainer>
	);
};
