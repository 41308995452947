import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, FlatList, Animated } from 'react-native';
import { ui } from '@newstart/ui';

import { CarouselItem } from './CarouselItem';

const { width, maxWidth } = ui;
const ITEM_HEIGHT = 221 + 12 + 8;

type CarouselItemType = {
	id: number;
	videoUrl: string;
	background: number;
};

type CarouselProps = {
	data: CarouselItemType[];
};

export const Carousel: React.FunctionComponent<CarouselProps> = ({ data }) => {
	const scrollX = new Animated.Value(0);
	let position = Animated.divide(scrollX, width);

	const [dataList, setDataList] = useState(data);
	const [index, setIndex] = useState(0);
	const [scrollValue, setScrollValue] = useState(0);

	const flatListRef = useRef<FlatList<CarouselItemType>>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			let w = width > maxWidth ? maxWidth : width;

			let nextIndex = index + 1;
			let nextScrollValue = scrollValue + w - 40;

			if (nextIndex >= dataList.length) {
				nextIndex = 0;
				nextScrollValue = 0;
			}

			flatListRef.current?.scrollToOffset({
				animated: true,
				offset: nextScrollValue,
			});

			setScrollValue(nextScrollValue);
			setIndex(nextIndex);
		}, 4500);
		return () => clearInterval(interval);
	}, [flatListRef, dataList, index, scrollValue]);

	useEffect(() => {
		setDataList(data);
	}, []);

	if (data && data.length) {
		return (
			<View
				style={{ alignSelf: 'center', height: ITEM_HEIGHT, maxWidth: maxWidth }}
			>
				<FlatList<CarouselItemType>
					data={data}
					ref={flatListRef}
					keyExtractor={(_, index) => `key.${index}`}
					horizontal
					pagingEnabled
					scrollEnabled
					snapToAlignment="center"
					scrollEventThrottle={16}
					decelerationRate={'fast'}
					showsHorizontalScrollIndicator={false}
					renderItem={({ item }) => {
						return (
							<CarouselItem
								key={item.id}
								videoUrl={item.videoUrl}
								background={item.background}
							/>
						);
					}}
					onScroll={Animated.event(
						[{ nativeEvent: { contentOffset: { x: scrollX } } }],
						{ useNativeDriver: false }
					)}
				/>

				<View style={styles.dotView}>
					{data.map((_, i) => {
						let opacity = position.interpolate({
							inputRange: [i - 1, i, i + 1],
							outputRange: [0.3, 1, 0.3],
							extrapolate: 'clamp',
						});
						return <Animated.View key={i} style={{ opacity, ...styles.dot }} />;
					})}
				</View>
			</View>
		);
	}
	return null;
};

const styles = StyleSheet.create({
	dotView: {
		flexDirection: 'row',
		justifyContent: 'center',
		height: 12,
	},
	dot: {
		width: 6,
		height: 6,
		backgroundColor: '#857D98',
		marginHorizontal: 6,
		marginVertical: 4,
		borderRadius: 3,
	},
});
