import React from "react"
import i18n from "i18n-js"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  ProfileScreen,
  SettingsScreen,
  NotificationsScreen,
  NotificationDetailModal,
  EditProfileScreen,
} from "../screens"
import { ui } from "@newstart/ui"

export type ProfileStackParamList = {
  Profile_Screen: undefined
  Profile_Notifications_Screen: undefined
  Profile_Settings_Screen: undefined
  Profile_Notification_Detail_Modal: { notificationId: string }
  Profile_Editing_Modal: undefined
}

const Stack = createNativeStackNavigator<ProfileStackParamList>()

const screenOptions = {
  headerTintColor: ui.colors.primary,
  headerBackTitleVisible: false,
  headerStyle: { backgroundColor: ui.colors.light },
  headerLargeTitle: false,
  headerLargeStyle: { backgroundColor: ui.colors.light },
}

export const ProfileNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Group screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="Profile_Screen"
          component={ProfileScreen}
          options={{
            title: i18n.t("tab-profile"),
          }}
        />
      </Stack.Group>
      <Stack.Group screenOptions={screenOptions}>
        <Stack.Screen
          name="Profile_Notifications_Screen"
          component={NotificationsScreen}
          options={{
            title: i18n.t("notifications"),
          }}
        />
        <Stack.Screen
          name="Profile_Settings_Screen"
          component={SettingsScreen}
          options={{
            title: i18n.t("settings"),
          }}
        />
      </Stack.Group>
      <Stack.Group
        screenOptions={{ presentation: "modal", headerShown: false }}
      >
        <Stack.Screen
          name="Profile_Notification_Detail_Modal"
          component={NotificationDetailModal}
        />
        <Stack.Screen
          name="Profile_Editing_Modal"
          component={EditProfileScreen}
        />
      </Stack.Group>
    </Stack.Navigator>
  )
}
