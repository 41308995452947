/**
 * Hábito
 */
export type Habit = {
	id: string;
	name: string;
	description: string;
	bubbleMessage: string;
	icon: HabitIconName;
	category: HabitCategory;
	startAt: number;
	endAt: number;
	points: number;
	videoUrl: string;
	order: number;
};
/**
 * Hábito vindo do servidor
 */
export type HabitData = Omit<Habit, 'category'> & {
	category: HabitCategoryKey;
};

/**
 * Ícone de um hábito
 */
export type HabitIconName =
	| 'apple'
	| 'confidence'
	| 'forgiveness'
	| 'fresh-air'
	| 'good-think'
	| 'no-coffee'
	| 'no-screen'
	| 'no-snacks'
	| 'no-sugar'
	| 'sleep-eight-hours'
	| 'exercise'
	| 'water';

/**
 * Categoria de hábitos
 */
export type HabitCategory = {
	/**
	 * Identificador da categoria
	 */
	key: HabitCategoryKey;
	/**
	 * Ícone da categoria
	 */
	icon: HabitIconName;
	/**
	 * Core que representam o gradiente da categoria
	 */
	colors: string[];
};

/**
 * Chave de categoria de hábitos
 */
export enum HabitCategoryKey {
	EXERCISE = 'EXERCISE',
	SLEEP = 'SLEEP',
	NUTRITION = 'NUTRITION',
	WALFARE = 'WALFARE',
}

/**
 * Tipo de exercício
 */
export type ExerciseType = {
	/**
	 * ID do tipo de exercício
	 */
	key: ExerciseTypeKey;
	/**
	 * Ícone do tipo de exercício
	 */
	icon: number;
	/**
	 * Nome do tipo de exercício
	 */
	name: string;
	/**
	 * Pergunta relacionada ao tipo de exerício
	 */
	question: string;
	/**
	 * Unidade de medida que o usuário vai utilizar para medir o exercício
	 */
	unit: 'steps' | 'km' | 'min' | 'meters';
	/**
	 * Valor usado para calcular o número de passos
	 */
	converter?: number;
	/**
	 * Valor usados para calcular o número de passos baseado na dificuldade do exercício
	 */
	multiplier?: {
		LOW: number;
		MEDIUM: number;
		HIGH: number;
		SUPERHIGH?: number;
	};
	/**
	 * Valor usado para descrever a dificuldade do exercício
	 */
	multiplierDescriptions?: {
		LOW: string;
		MEDIUM: string;
		HIGH: string;
		SUPERHIGH?: string;
	};
	/**
	 * Valor usado para dar nome ao tipo de nível
	 */
	multiplierLabel?: string;
};

/**
 * Chave dos tipos de exercícios
 */
export type ExerciseTypeKey =
	| 'WALKING'
	| 'RUNNING'
	| 'SWIMMING'
	| 'CYCLING'
	| 'ELETRIC_CYCLING'
	| 'SNOWSHOE'
	| 'OTHER';

export type Level = 'LOW' | 'MEDIUM' | 'HIGH' | 'SUPERHIGH';
