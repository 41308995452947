import { useCallback } from 'react';
import { showMessage } from 'react-native-flash-message';
import i18n from 'i18n-js';

export function useErrorMessage() {
	const messages: ErrorsMessagesByCode = {
		fields_required: i18n.t('fields_required'),
		invalid_credentials: i18n.t('invalid_credentials'),
		email_field_already_exists: i18n.t('email_field_already_exists'),
		username_field_already_exists: i18n.t('username_field_already_exists'),
		team_not_empty: i18n.t('team_not_empty'),
		team_owner_cannot_leave: i18n.t('team_owner_cannot_leave'),
	};

	const messageByCode = (code: BadRequestErrorCode): string => {
		return messages[code];
	};

	return {
		showError: useCallback(function handle(error: any) {
			if (error.code) {
				showMessage({
					message: messageByCode(error.code as BadRequestErrorCode),
					type: 'danger',
					icon: 'danger',
					autoHide: false,
					floating: true,
				});
				return;
			}

			showMessage({
				message: String(error),
				type: 'danger',
				icon: 'danger',
				autoHide: false,
				floating: true,
			});
			return;
		}, []),
	};
}

interface HandleProps {
	error: any;
}

type BadRequestErrorCode =
	| 'fields_required'
	| 'email_field_already_exists'
	| 'username_field_already_exists'
	| 'invalid_credentials'
	| 'team_not_empty'
	| 'team_owner_cannot_leave';

type ErrorsMessagesByCode = Record<BadRequestErrorCode, string>;
