import React from 'react';
import { ui } from '@newstart/ui';
import { HabitIcon } from '../HabitIcon';
import { HabitBubbleProps } from './interfaces';
import { StyledContainer } from './StyledContainer';
import { StyledIconContainer } from './StyledIconContainer';
import {
	StyledButton,
	StyledStepsLabel,
	StyledStepsValue,
	StyledTitle,
} from './styles';
import { CircularProgress } from './CircularProgress';
import i18n from 'i18n-js';

export const HabitExerciseBubble: React.FunctionComponent<
	HabitExerciseBubbleProps
> = ({
	title,
	colors,
	icon,
	size = 200,
	filled = false,
	progress = 0,
	steps = 0,
	...props
}) => {
	return (
		<StyledButton activeOpacity={0.5} {...props}>
			<StyledContainer size={size} colors={colors} filled={filled}>
				<CircularProgress
					percentage={progress > 100 ? 100 : progress}
					color={filled ? ui.colors.dark : 'white'}
					strokeWidth={30}
					radius={140}
					style={{ position: 'absolute' }}
				/>
				<StyledIconContainer colors={colors} filled={filled}>
					<HabitIcon name={icon} size={26} />
				</StyledIconContainer>
				<StyledTitle
					style={{
						color: filled ? ui.colors.dark : colors[0],
					}}
				>
					{title}
				</StyledTitle>
				<StyledStepsLabel>{i18n.t('steps-today')}</StyledStepsLabel>
				<StyledStepsValue filled={filled}>
					{steps > 0
						? i18n.toNumber(steps, { precision: 0, delimiter: ' ' })
						: '-'}
				</StyledStepsValue>
			</StyledContainer>
		</StyledButton>
	);
};

type HabitExerciseBubbleProps = HabitBubbleProps & {
	steps: number;
	progress: number;
};
