import React from 'react';
import { TextInputProps } from 'react-native';
import {
	StyledFormInputContainer,
	StyledLabel,
	StyledInputText,
} from './styles';

export type InputProps = TextInputProps & {
	label?: string;
	required?: boolean;
	error?: React.ReactNode;
	dark?: boolean;
};

export const Input: React.FunctionComponent<InputProps> = ({
	label,
	required,
	error,
	dark,
	...props
}) => {
	return (
		<StyledFormInputContainer>
			{label && (
				<StyledLabel dark={dark}>
					{label}
					{required && '*'}
				</StyledLabel>
			)}
			<StyledInputText dark={dark} {...props} />
			{error}
		</StyledFormInputContainer>
	);
};
