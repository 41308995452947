import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react"
import { useAuth } from "@newstart/auth"

import {
  getAchievementsService,
  getProgramService,
  getHabitService,
  getNotificationService,
  getBonusNotificationService,
} from "../services"
import {
  EngineContextInterface,
  AddExerciseProps,
  EditExerciseProps,
} from "./types"
import { reducer } from "./reducer"

const programService = getProgramService()
const achievementService = getAchievementsService()
const habitService = getHabitService()
const notificationService = getNotificationService()
const bonusNotificationService = getBonusNotificationService()

const EngineContext = createContext<EngineContextInterface | null>(null)

const EngineProvider = ({ children }: PropsWithChildren<{}>) => {
  const { user, token } = useAuth()

  const [state, dispatch] = useReducer(reducer, {
    kind: "prefetch",
    startDate: "",
    currentIndex: 0,
    selectedIndex: 0,
    habits: [],
    challenges: [],
    userPerformance: [],
    userAchievements: [],
    teamMembersAchievements: [],
    teamMembersPerformance: [],
    notifications: [],
    userTotalPoints: 0,
    teamTotalPoints: 0,
  })

  const {
    kind,
    startDate,
    currentIndex,
    selectedIndex,
    habits,
    challenges,
    userPerformance,
    userAchievements,
    teamMembersAchievements,
    teamMembersPerformance,
    userTotalPoints,
    teamTotalPoints,
    notifications,
    error,
  } = state

  const loading = useMemo(
    () => kind === "prefetch" || kind === "fetching",
    [kind]
  )

  useEffect(() => {
    if (!token) return
    ;(async () => {
      try {
        const {
          startDate,
          performance,
          achievements,
          notifications,
          teamPoints,
          userPoints,
          challenges,
        } = await programService.init(token)
        const habits = await habitService.find(token)

        dispatch({
          type: "FETCH_INITIAL_DATA",
          payload: {
            habits,
            challenges,
            notifications,
            startDate,
          },
        })

        dispatch({
          type: "FETCH_SUCCESS",
          payload: {
            userAchievements: achievements.user,
            userPerformance: performance.user,
            teamMembersAchievements: achievements.team,
            teamMembersPerformance: performance.team,
            userTotalPoints: userPoints,
            teamTotalPoints: teamPoints,
          },
        })
      } catch (error) {
        dispatch({
          type: "FETCH_FAILURE",
          payload: {
            error: error as Error,
          },
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (!token) return
    if (kind === "fetching") {
      ;(async () => {
        try {
          const {
            achievements,
            performance,
            teamAchievements,
            teamPerformance,
            userPoints,
            teamPoints,
          } = await achievementService.findAll(token)

          dispatch({
            type: "FETCH_SUCCESS",
            payload: {
              userAchievements: achievements,
              userPerformance: performance,
              teamMembersAchievements: teamAchievements,
              teamMembersPerformance: teamPerformance,
              userTotalPoints: userPoints,
              teamTotalPoints: teamPoints,
            },
          })
        } catch (error) {
          dispatch({
            type: "FETCH_FAILURE",
            payload: {
              error: error as Error,
            },
          })
        }
      })()
    }
  }, [kind, token])

  const setSelectedIndex = useCallback(
    (index: number) => {
      dispatch({ type: "SET_INDEX", payload: { newIndex: index } })
    },
    [dispatch]
  )

  const recalculateCurrentIndex = useCallback(() => {
    dispatch({ type: "RECALCULATE_CURRENT_INDEX" })
  }, [dispatch])

  const checkHabit = useCallback(
    async (habitId: string) => {
      if (!user || !token) return

      const points = 1200

      await achievementService.createUserHabitAchievement(
        {
          habitId,
          user,
          rawPoints: 1,
          points,
          createdAt: state.selectedIndex,
          isBonus: false,
        },
        token
      )

      dispatch({ type: "USER_ACHIEVEMENT_UPDATE" })
    },
    [state.selectedIndex, user, token]
  )

  const uncheckHabit = useCallback(
    async (achievementId: string) => {
      if (!token) return

      await achievementService.remove(achievementId, token)

      dispatch({ type: "USER_ACHIEVEMENT_UPDATE" })
    },
    [token]
  )

  const addExercise = useCallback(
    async (data: AddExerciseProps) => {
      if (!user || !token) return

      await achievementService.createUserHabitAchievement(
        {
          ...data,
          isBonus: false,
          user,
          createdAt: state.selectedIndex,
        },
        token
      )
      dispatch({ type: "USER_ACHIEVEMENT_UPDATE" })
    },
    [state.selectedIndex, user, token]
  )

  const editExercise = useCallback(
    async (data: EditExerciseProps) => {
      if (!user || !token) return
      await achievementService.updateUserAchievement(
        data.achievementId,
        {
          points: data.points,
          rawPoints: data.rawPoints,
          level: data.level,
          isBonus: false,
        },
        token
      )
      dispatch({ type: "USER_ACHIEVEMENT_UPDATE" })
    },
    [user, token]
  )

  const markNotificationAsRead = useCallback(
    async (notificationId: string) => {
      if (!token) return
      await notificationService.markAsRead({ notificationId }, token)
      const updatedNotificationList = await notificationService.findAll(token)
      dispatch({
        type: "NOTIFICATION_READED",
        payload: {
          notifications: updatedNotificationList,
        },
      })
    },
    [token]
  )

  const markBonusNotificationAsRead = useCallback(
    async (bonusNotificationId: string) => {
      if (!token) return
      await bonusNotificationService.markAsRead(bonusNotificationId, token)
    },
    [token]
  )

  const refetchPerformanceData = useCallback(() => {
    dispatch({ type: "REQUEST_REFETCH_DATA" })
  }, [])

  return (
    <EngineContext.Provider
      value={{
        status: kind,
        loading,
        startDate,
        currentIndex,
        selectedIndex,
        habits,
        userPerformance,
        userAchievements,
        teamMembersAchievements,
        teamMembersPerformance,
        userTotalPoints,
        teamTotalPoints,
        setSelectedIndex,
        recalculateCurrentIndex,
        checkHabit,
        uncheckHabit,
        addExercise,
        editExercise,
        notifications,
        challenges,
        markNotificationAsRead,
        markBonusNotificationAsRead,
        refetchPerformanceData,
        error,
      }}
    >
      {children}
    </EngineContext.Provider>
  )
}

export { EngineProvider, EngineContext }
