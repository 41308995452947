import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { ListRenderItem, Platform, StyleSheet, View } from "react-native"
import { FlatList } from "react-native-gesture-handler"
import Dialog from "react-native-dialog"
import i18n from "i18n-js"
import { MaterialCommunityIcons } from "@expo/vector-icons"

import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet"
import {
  formatDate,
  getHabitService,
  ExerciseTypeKey,
  UserHabitAchievement,
  useEngine,
  UserChallengeAchievement,
} from "@newstart/engine"
import { Loading, ui, useMessage } from "@newstart/ui"
import { useAuth } from "@newstart/auth"

import { getAdminService } from "../../services"
import { Title } from "../Title"
import { HabitAchievementData, AchievementScreenItem } from "./types"
import { isChallengeAchievement, isHabitAchievement } from "./utils"
import { AchievementItem } from "./AchievementItem"

const exerciseTypes = getHabitService().getExerciseTypes()
const adminService = getAdminService()

type AchievementItemProps = {
  userId: string
}

export const AchievementList: React.FunctionComponent<AchievementItemProps> =
  connectActionSheet(({ userId }) => {
    const { token } = useAuth()
    const { startDate, habits } = useEngine()
    const { showActionSheetWithOptions } = useActionSheet()
    const { showError, showSuccess } = useMessage()

    const [isLoading, setIsLoading] = useState(true)
    const [isShowingDialog, setIsShowingDialog] = useState(false)
    const [newPoints, setNewPoints] = useState("")
    const [needsReload, setNeedsReload] = useState(false)

    const [selectedAchievement, setSelectedAchievement] =
      useState<HabitAchievementData["data"]>()
    const [achievements, setAchievements] = useState<
      Array<UserHabitAchievement | UserChallengeAchievement>
    >([])

    useEffect(() => {
      if (!token) return

      const fetchAchievements = async () => {
        const achievements = await adminService.getUserAchievements(
          userId,
          token
        )
        setAchievements(achievements)
        setIsLoading(false)
      }

      fetchAchievements()
    }, [needsReload, token])

    const [indices, setIndices] = useState<number[]>([])

    const handleActionSheet = (achievement: HabitAchievementData["data"]) => {
      if (!achievement.isBonus) {
        let options: string[]
        let destructiveButtonIndex: number
        let cancelButtonIndex: number

        if (achievement.exerciseType) {
          options = [
            i18n.t("edit"), // 0
            i18n.t("delete"), // 1
            i18n.t("cancel"), // 2
          ]
          destructiveButtonIndex = 1
          cancelButtonIndex = 2
        } else {
          options = [
            i18n.t("delete"), // 0
            i18n.t("cancel"), // 1
          ]
          destructiveButtonIndex = 0
          cancelButtonIndex = 1
        }

        showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex,
            destructiveButtonIndex,
          },
          (buttonIndex) => {
            if (buttonIndex === 0) {
              if (achievement.exerciseType) {
                setSelectedAchievement(achievement)
                setIsShowingDialog(true)
              } else {
                handleDeleting(achievement.id)
              }
            } else if (buttonIndex === 1) {
              if (achievement.exerciseType) {
                handleDeleting(achievement.id)
              }
            }
          }
        )
      }
    }

    const handleUpdate = useCallback(async () => {
      if (!selectedAchievement || !token) return
      try {
        await adminService.updateUserAchievement(
          selectedAchievement?.id,
          Number(newPoints),
          token
        )
        setSelectedAchievement(undefined)
        setIsShowingDialog(false)
        setNeedsReload(!needsReload)
        showSuccess(i18n.t("achievement-updated"))
      } catch (error) {
        showError(error)
      }
    }, [selectedAchievement, setNeedsReload, needsReload, newPoints, token])

    const handleDeleting = (achievementId: string) => {
      let options = [
        i18n.t("confirm"), // 0
        i18n.t("cancel"), // 1
      ]
      let destructiveButtonIndex = 0
      let cancelButtonIndex = 1

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
          title: i18n.t("are-you-sure"),
          message: i18n.t("this-action-cannot-be-undone"),
        },
        async (buttonIndex) => {
          if (buttonIndex === 0) {
            if (!token) return
            try {
              await adminService.deleteUserAchievement(achievementId, token)
              setNeedsReload(!needsReload)
              showSuccess(i18n.t("achievement-deleted"))
            } catch (error) {
              showError(error)
            }
          }
        }
      )
    }

    const handleCancel = () => {
      setNewPoints("")
      setIsShowingDialog(false)
    }

    const { data } = useMemo(() => {
      const items: AchievementScreenItem[] = []

      achievements.map((achievement) => {
        if (
          !items.some(
            (item) =>
              item.format === "subtitle" &&
              item.data.createdAt === achievement.createdAt
          )
        ) {
          items.push({
            key: `subtitle${achievement.createdAt}`,
            format: "subtitle",
            data: { createdAt: achievement.createdAt },
          })
        }

        if (isHabitAchievement(achievement)) {
          const habit =
            habits.find((habit) => habit.id === achievement.habitId) ??
            habits[0]

          items.push({
            key: achievement.id,
            format: "habitAchievement",
            data: {
              id: achievement.id,
              rawPoints: achievement.rawPoints,
              points: achievement.points,
              description: parseDescription(
                achievement.rawPoints,
                habit.bubbleMessage,
                achievement.exerciseType
              ),
              habitIconName: habit.icon,
              exerciseType: achievement.exerciseType,
              exerciseTypeIcon: achievement.exerciseType
                ? exerciseTypes[achievement.exerciseType].icon
                : undefined,
              borderColor: habit.category.colors[0],
              isBonus: achievement.isBonus,
              habitId: achievement.habitId,
              level: achievement.level,
            },
          })
        } else if (isChallengeAchievement(achievement)) {
          items.push({
            key: achievement.id,
            format: "challengeAchievement",
            data: {
              id: achievement.id,
              points: achievement.points,
              description: "bonus de défi complet",
              borderColor: ui.colors.light,
              isBonus: achievement.isBonus,
              challengeId: achievement.challengeId,
            },
          })
        }
      })

      let indicesList: number[] = []
      items.forEach(
        (item, index) => item.format === "subtitle" && indicesList.push(index)
      )
      setIndices(indicesList)

      return { data: items }
    }, [achievements])

    const renderItem: ListRenderItem<AchievementScreenItem> = ({ item }) => {
      const { format } = item
      if (format === "habitAchievement")
        return (
          <AchievementItem
            key={item.key}
            onPress={() => handleActionSheet(item.data)}
            label={item.data.description}
            points={item.data.points}
            borderColor={item.data.borderColor}
            habitIconName={item.data.habitIconName}
            exerciseTypeIcon={item.data.exerciseTypeIcon}
          />
        )

      if (format === "challengeAchievement")
        return (
          <AchievementItem
            key={item.key}
            onPress={() => {}}
            label={item.data.description}
            points={item.data.points}
            borderColor={item.data.borderColor}
            iconNode={
              <MaterialCommunityIcons
                name="medal"
                size={24}
                color={ui.colors.dark}
              />
            }
          />
        )

      if (format === "subtitle")
        return (
          <Title>{formatDate(startDate, item.data.createdAt, "DD/MM")}</Title>
        )

      return <Fragment />
    }

    if (isLoading) {
      return <Loading />
    }

    return (
      <>
        <FlatList<AchievementScreenItem>
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.key}
          stickyHeaderIndices={indices}
          contentInsetAdjustmentBehavior="automatic"
          style={{
            width: "100%",
            flexGrow: 0,
          }}
          contentContainerStyle={{
            paddingLeft: 20,
            paddingRight: 20,
          }}
          ListFooterComponent={<View style={{ height: 280 }} />}
        />
        {(Platform.OS === "ios" || Platform.OS === "android") && (
          <Dialog.Container
            visible={isShowingDialog}
            onBackdropPress={handleCancel}
          >
            <Dialog.Title>Edit Activity</Dialog.Title>
            <Dialog.Description>Update the score below</Dialog.Description>
            <Dialog.Input
              label="New points:"
              defaultValue={String(selectedAchievement?.points)}
              autoFocus={true}
              autoCorrect={false}
              autoCompleteType="cc-number"
              keyboardType="number-pad"
              onChangeText={(text: string) => setNewPoints(text)}
              style={styles.dialogInput}
            />
            <Dialog.Button label="Cancel" onPress={handleCancel} />
            <Dialog.Button label="Save" onPress={handleUpdate} bold={true} />
          </Dialog.Container>
        )}
      </>
    )
  })

const parseDescription = (
  rawPoints: number,
  defaultMessage: string,
  exerciseType?: ExerciseTypeKey
): string => {
  const points = i18n.toNumber(rawPoints, { precision: 0, delimiter: " " })

  switch (exerciseType) {
    case "WALKING":
      return i18n.t("walked-x-steps", { points, count: points })
      break

    case "RUNNING":
      return i18n.t("ran-x-steps", { points, count: points })
      break

    case "CYCLING":
      return i18n.t("pedaled-x-kilometers", { points, count: points })
      break

    case "ELETRIC_CYCLING":
      return i18n.t("pedaled-x-kilometers", { points, count: points })
      break

    case "SWIMMING":
      return i18n.t("swam-x-meters", { points, count: points })
      break

    case "OTHER":
      return i18n.t("other-x-minutes", { points, count: points })
      break

    case "SNOWSHOE":
      return i18n.t("walked-x-steps", { points, count: points })
      break

    default:
      return defaultMessage
      break
  }
}

const styles = StyleSheet.create({
  dialogInput: {
    fontFamily: ui.fonts.Inter.Regular,
    fontSize: 20,
    color: ui.colors.dark,
    letterSpacing: -0.63,
  },
})
