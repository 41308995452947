import i18n from 'i18n-js';
import React from 'react';
import {
	StyledContainer,
	StyledInnerContainer,
	StyledPlaceText,
	StyledPointsText,
	StyledText,
} from './styles';

type RankingListItemProps = {
	place: number;
	points: number;
	name: string;
	isMe?: boolean;
};

export const RankingListItem: React.FunctionComponent<RankingListItemProps> = ({
	place,
	points,
	name,
	isMe,
}) => {
	return (
		<StyledContainer>
			<StyledPlaceText>{place + 1}º</StyledPlaceText>
			<StyledInnerContainer isMe={isMe}>
				<StyledText numberOfLines={1}>{name}</StyledText>
				<StyledPointsText>
					{i18n.toNumber(points, { precision: 0, delimiter: ' ' })}
				</StyledPointsText>
			</StyledInnerContainer>
		</StyledContainer>
	);
};
