import React from 'react';
import { ui } from '@newstart/ui';

import { HabitIcon } from '../HabitIcon';
import { HabitIconName } from '@newstart/engine';
import {
	StyledContainer,
	StyledIconContainer,
	StyledTitle,
	StyledSubtitle,
} from './styles';

type ModalHeadingProps = {
	icon: HabitIconName;
	title: string;
	subtitle: string;
};

export const ModalHeading: React.FunctionComponent<ModalHeadingProps> = ({
	icon,
	title,
	subtitle,
}) => {
	return (
		<StyledContainer>
			<StyledIconContainer>
				<HabitIcon name={icon} size={30} color={ui.colors.dark} />
			</StyledIconContainer>
			<StyledTitle>{title}</StyledTitle>
			<StyledSubtitle>{subtitle}</StyledSubtitle>
		</StyledContainer>
	);
};
