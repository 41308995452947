import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import i18n from "i18n-js"
import { WelcomeScreen } from "../screens/WelcomeScreen"
import { ForgotPasswordScreen } from "../screens/ForgotPasswordScreen"
import { LoginScreen } from "../screens/LoginScreen"
import { RegisterScreen } from "../screens/RegisterScreen"

import { ui } from "@newstart/ui"

export type AuthStackParamList = {
  Auth_Welcome_Screen: undefined
  Auth_Register_Screen: undefined
  Auth_Login_Screen: undefined
  Auth_Forgot_Password_Screen: undefined
}

const Stack = createNativeStackNavigator<AuthStackParamList>()

export const AuthNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Auth_Welcome_Screen">
      <Stack.Screen
        name="Auth_Welcome_Screen"
        component={WelcomeScreen}
        options={{ headerShown: false }}
      />
      <Stack.Group
        screenOptions={{
          headerTintColor: ui.colors.primary,
          headerBackTitleVisible: false,
          headerStyle: { backgroundColor: ui.colors.light },
          headerLargeTitle: false,
          headerLargeStyle: { backgroundColor: ui.colors.light },
        }}
      >
        <Stack.Screen
          name="Auth_Register_Screen"
          component={RegisterScreen}
          options={{ title: i18n.t("register") }}
        />
        <Stack.Screen
          name="Auth_Login_Screen"
          component={LoginScreen}
          options={{ title: i18n.t("login") }}
        />
        <Stack.Screen
          name="Auth_Forgot_Password_Screen"
          component={ForgotPasswordScreen}
          options={{ title: i18n.t("recover-password") }}
        />
      </Stack.Group>
    </Stack.Navigator>
  )
}
