import React, { Fragment, useMemo, useState } from 'react';
import {
	ActivityIndicator,
	FlatList,
	ListRenderItem,
	Text,
} from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BaseContainer, StatusBar } from '@newstart/ui';
import { useEngine } from '@newstart/engine';

import { ProfileStackParamList } from '../../navigation';
import { Title } from '../../components';
import {
	StyledItemContainer,
	StyledItem,
	StyledTitle,
	StyledMessage,
	StyledDateText,
	StyledEmpty,
	StyledEmptyText,
} from './styles';
import { NotificationScreenItem } from './types';
import { humanDate } from '../../helpers';
import i18n from 'i18n-js';

type NotiticationsModalProps = NativeStackScreenProps<
	ProfileStackParamList,
	'Profile_Notifications_Screen'
>;

export const NotificationsScreen: React.FunctionComponent<
	NotiticationsModalProps
> = ({ navigation }) => {
	const { notifications, loading } = useEngine();
	const [indices, setIndices] = useState<number[]>([]);

	const { data } = useMemo(() => {
		const items: NotificationScreenItem[] = [];

		items.push({
			format: 'title',
			data: { label: i18n.t('new-for-you') },
			key: 'unread_section_title',
		});

		const unreadeds = notifications.filter((un) => un.readedAt === null);

		if (unreadeds.length > 0) {
			unreadeds.map((un, i) =>
				items.push({
					format: 'notification',
					key: un.id,
					data: {
						...un.notification,
						isFirst: i === 0,
						isRead: false,
					},
				})
			);
		} else {
			items.push({
				format: 'empty',
				key: 'empty_unreadeds_section',
				data: {},
			});
		}

		items.push({
			format: 'title',
			data: { label: i18n.t('past-notifications') },
			key: 'read_section_title',
		});

		const readeds = notifications.filter((un) => un.readedAt !== null);
		if (readeds.length > 0) {
			readeds.map((un, i) =>
				items.push({
					format: 'notification',
					key: un.id,
					data: {
						...un.notification,
						isFirst: i === 0,
						isRead: true,
					},
				})
			);
		} else {
			items.push({
				format: 'empty',
				key: 'empty_readeds_section',
				data: {},
			});
		}

		let indicesList: number[] = [];
		items.forEach(
			(item, index) => item.format === 'title' && indicesList.push(index)
		);
		setIndices(indicesList);

		return { data: items };
	}, [notifications]);

	const renderItem: ListRenderItem<NotificationScreenItem> = ({ item }) => {
		const { format } = item;
		if (format === 'notification')
			return (
				<NotificationItem
					id={item.data.id}
					isFirst={item.data.isFirst}
					isRead={item.data.isRead}
					createAt={item.data.createdAt}
					title={item.data.title}
					message={item.data.message}
					onOpenNotification={(notificationId) =>
						navigation.navigate('Profile_Notification_Detail_Modal', {
							notificationId,
						})
					}
				/>
			);

		if (format === 'title') return <Title>{item.data.label}</Title>;

		if (format === 'empty')
			return (
				<StyledEmpty>
					<StyledEmptyText>
						{i18n.t('no-unread-notification ')} 😉
					</StyledEmptyText>
				</StyledEmpty>
			);

		return <Fragment />;
	};

	if (loading) {
		return <ActivityIndicator />;
	}

	return (
		<BaseContainer>
			<StatusBar style="auto" />
			<FlatList<NotificationScreenItem>
				data={data}
				renderItem={renderItem}
				keyExtractor={(item) => item.key}
				stickyHeaderIndices={indices}
				style={{
					width: '100%',
					flex: 1,
				}}
				contentContainerStyle={{
					paddingBottom: 20,
				}}
			/>
		</BaseContainer>
	);
};

type NotificationItemProps = {
	id: string;
	title: string;
	message: string;
	createAt: Date;
	isRead: boolean;
	isFirst?: boolean;
	onOpenNotification: (notificationId: string) => void;
};
const NotificationItem = ({
	isFirst,
	isRead,
	id,
	title,
	message,
	createAt,
	onOpenNotification,
}: NotificationItemProps) => (
	<StyledItemContainer isFirst={isFirst} onPress={() => onOpenNotification(id)}>
		<StyledItem>
			<StyledTitle isRead={isRead} numberOfLines={1}>
				{title}
			</StyledTitle>
			<StyledDateText>{humanDate(createAt)}</StyledDateText>
		</StyledItem>
		<StyledMessage isRead={isRead} numberOfLines={1}>
			{message}
		</StyledMessage>
	</StyledItemContainer>
);
