import React from 'react';
import { StatusBar as ExpoStatusBar, StatusBarProps } from 'expo-status-bar';
import { useIsFocused } from '@react-navigation/native';

export const StatusBar: React.FunctionComponent<StatusBarProps> = ({
	...props
}) => {
	const isFocused = useIsFocused();
	return isFocused ? <ExpoStatusBar {...props} /> : null;
};
