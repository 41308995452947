import styled from 'styled-components/native';

export const StyledAvatarContainer = styled.ImageBackground<{ size: number }>`
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	border-radius: ${(props) => props.size / 2}px;
	overflow: hidden;
`;

export const StyledAvatarImage = styled.Image<{ size: number }>`
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	border-radius: ${(props) => props.size / 2}px;
`;
