import React from 'react';
import { BaseButton, BaseButtonProps } from './BaseButton';
import { ui } from '../styles';

type PrimaryButtonProps = Omit<
	BaseButtonProps,
	'backgroundColor' | 'textSize' | 'textColor'
>;

export const PrimaryButton: React.FunctionComponent<PrimaryButtonProps> = ({
	...props
}) => {
	return (
		<BaseButton
			backgroundColor={ui.colors.primary}
			textColor={ui.colors.light}
			{...props}
		/>
	);
};
