import React, { useCallback, useEffect, useRef, useState } from "react"
import { ActivityIndicator } from "react-native"
import { useFocusEffect } from "@react-navigation/native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { CompositeScreenProps } from "@react-navigation/native"
import { BottomTabScreenProps } from "@react-navigation/bottom-tabs"
import moment from "moment-timezone"

import { AppTabsParamList } from "@newstart/navigation"
import { ui, BaseContainer } from "@newstart/ui"
import { useAuth } from "@newstart/auth"
import { getBonusNotificationService, useEngine } from "@newstart/engine"

import { HabitsStackParamList } from "../../navigation"
import { HabitScreenNotStarted } from "./HabitsScreenNotStarted"
import { HabitsScreenFinished } from "./HabitsScreenFinished"
import { HabitsScreenReady } from "./HabitsScreenReady"
import { HabitScreenSabbathWarning } from "./HabitScreenSabbathWarning"

type HabitsScreenProps = CompositeScreenProps<
  BottomTabScreenProps<AppTabsParamList, "Tab_Habits">,
  NativeStackScreenProps<HabitsStackParamList, "Habits_Screen">
>

export const HabitsScreen: React.FunctionComponent<HabitsScreenProps> = (
  props
) => {
  const { token } = useAuth()
  const { status, loading, currentIndex, recalculateCurrentIndex } = useEngine()
  const [weekDay, setWeekDay] = useState(moment().day())
  const [lastActivityTime, setLastActivityTime] = useState(moment())

  useEffect(() => {
    async function fetchingBonusChallengeNotifications() {
      if (!token) return
      const bonusNotifications = await getBonusNotificationService().findAll(
        token
      )
      const notReadeds = bonusNotifications.filter(
        (bonusNotification) => bonusNotification.readedAt === null
      )
      if (notReadeds.length > 0) {
        props.navigation.navigate("Habits_Bonus_Modal", {
          bonusNotificationId: notReadeds[0].id,
        })
      }
    }
    fetchingBonusChallengeNotifications()
  }, [token])

  // const appState = useRef(AppState.currentState);

  /**
   * Não permite que a tela congele em um dia ao navegar entre screens
   */
  useFocusEffect(
    useCallback(() => {
      if (!lastActivityTime.isSame(moment(), "hours")) {
        setLastActivityTime(moment())
        recalculateCurrentIndex()
      }
      setWeekDay(moment().day())
    }, [setWeekDay])
  )

  /**
   * Não permite que a tela congele em um dia ao deixar o app em background
   */
  // const handleAppStateChange = useCallback(
  // 	(nextAppState: AppStateStatus) => {
  // 		if (
  // 			appState.current.match(/inactive|background/) &&
  // 			nextAppState === 'active'
  // 		) {
  // 			recalculateCurrentIndex();
  // 		}
  // 		appState.current = nextAppState;
  // 	},
  // 	[appState]
  // );
  // useEffect(() => {
  // 	AppState.addEventListener('change', handleAppStateChange);
  // 	return () => {
  // 		AppState.removeEventListener('change', handleAppStateChange);
  // 	};
  // }, []);

  if (status === "prefetch") {
    return (
      <BaseContainer
        backgroundColor={["#30A5DA", "#0D00B1"]}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <ActivityIndicator size="large" color={ui.colors.light} />
      </BaseContainer>
    )
  }

  // if (!loading && weekDay === 6) {
  // 	return <HabitScreenSabbathWarning />;
  // }

  if (currentIndex < 0) {
    return <HabitScreenNotStarted />
  }

  if (currentIndex > 79) {
    return <HabitsScreenFinished />
  }

  return <HabitsScreenReady {...props} />
}
