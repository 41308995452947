import React, { useCallback, useState } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Team, useAuth } from '@newstart/auth';

import { OnboardingStackParamList } from '../../navigation';
import { Creating } from './CreateTeamScreen.Creating';
import { Created } from './CreateTeamScreen.Created';
import { useMessage } from '@newstart/ui';

type CreateTeamScreenProps = NativeStackScreenProps<
	OnboardingStackParamList,
	'Onboarding_Create_Team_Modal'
>;

export type CreateTeamNavigation = CreateTeamScreenProps['navigation'];

export const CreateTeamScreen: React.FunctionComponent<
	CreateTeamScreenProps
> = ({ navigation }) => {
	const { createTeam, joinTeam } = useAuth();
	const { showError } = useMessage();

	const [team, setTeam] = useState<Team>();
	const [loading, setLoading] = useState(false);

	const onCreateTeam = useCallback(
		async (teamName: string) => {
			setLoading(true);
			try {
				const team = await createTeam(teamName);
				setTeam(team);
			} catch (error) {
				showError(error);
			} finally {
				setLoading(false);
			}
		},
		[createTeam, setLoading, setTeam, showError]
	);

	const onJoinTeam = useCallback(async () => {
		if (!team) return;
		setLoading(true);
		try {
			await joinTeam(team.inviteCode);
		} catch (error) {
			showError(error);
		}
	}, [team, showError, joinTeam]);

	return !team ? (
		<Creating
			navigation={navigation}
			loading={loading}
			createTeam={onCreateTeam}
		/>
	) : (
		<Created
			navigation={navigation}
			team={team}
			loading={loading}
			onDone={onJoinTeam}
		/>
	);
};
