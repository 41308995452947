import React, { useCallback, useState } from "react"
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  View,
} from "react-native"
import SegmentedControl from "@react-native-community/segmented-control"
import { ui } from "@newstart/ui"
import { useAuth } from "@newstart/auth"
import { StyledScrollContent } from "@newstart/ui/layouts/container/styles"
import { useEngine } from "@newstart/engine"

import { TeamActivityScreen } from "../TeamActivityScreen"
import { TeamChatScreen } from "../TeamChatScreen"
import i18n from "i18n-js"

export const TeamWithMembersScreen: React.FunctionComponent = () => {
  const { refetchPerformanceData } = useEngine()
  const { reloadUser } = useAuth()

  const [refreshing, setRefreshing] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const onRefresh = useCallback(async () => {
    setRefreshing(true)
    refetchPerformanceData()
    await reloadUser()
    setRefreshing(false)
  }, [setRefreshing, reloadUser])

  const renderRefreshControl = () => {
    return (
      <RefreshControl
        refreshing={refreshing}
        onRefresh={onRefresh}
        colors={["#2A1165"]}
        tintColor={"rgba(255,255,255,0.8)"}
      />
    )
  }

  return (
    <>
      <SegmentedControl
        values={[i18n.t("activity")]}
        selectedIndex={selectedIndex}
        appearance="dark"
        onChange={(event) =>
          setSelectedIndex(event.nativeEvent.selectedSegmentIndex)
        }
        style={{
          height: 40,
          marginHorizontal: 20,
        }}
        activeFontStyle={{
          fontFamily: ui.fonts.Inter.Bold,
          fontSize: 16,
        }}
        fontStyle={{
          fontFamily: ui.fonts.Inter.Bold,
          fontSize: 16,
        }}
      />

      {selectedIndex == 0 && (
        <ScrollView style={{ flex: 1 }} refreshControl={renderRefreshControl()}>
          {Platform.OS === "web" && refreshing && <Loading />}
          <StyledScrollContent>
            <TeamActivityScreen />
          </StyledScrollContent>
        </ScrollView>
      )}

      {/* {selectedIndex == 1 && (
				<View style={{ flex: 1 }}>
					{Platform.OS === 'web' && refreshing && <Loading />}
					<TeamChatScreen />
				</View>
			)} */}
    </>
  )
}

const Loading: React.FC = () => {
  return (
    <View
      style={{
        flex: 0.21,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 40,
      }}
    >
      <ActivityIndicator size="large" color={ui.colors.primary} />
    </View>
  )
}
