import { AuthToken } from '@newstart/auth';
import {
	AxiosHttpClient,
	HttpClient,
	httpErrorHandler,
	API_URL,
} from '@newstart/core';
import { getAchievementsService } from '@newstart/engine';

const achievementService = getAchievementsService();

class AdminService {
	constructor(private _httpClient: HttpClient) {}

	async listUsers(token: AuthToken): Promise<ListUsersResponse> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.get<ListUsersResponse>(`/admin/users`, {
					Authorization: `Bearer ${token}`,
				})
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}

	async getUserAchievements(userId: string, token: AuthToken) {
		return achievementService.findByUserId(userId, token);
	}

	async updateUserAchievement(
		achievementId: string,
		newPoints: number,
		token: AuthToken
	) {
		return new Promise((resolve, reject) => {
			this._httpClient
				.put(
					`/achievements/${achievementId}`,
					{
						points: newPoints,
					},
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async deleteUserAchievement(achievementId: string, token: AuthToken) {
		return new Promise((resolve, reject) => {
			this._httpClient
				.delete<void>(
					`/achievements/${achievementId}`,
					{},
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async banUser(
		userId: string,
		token: AuthToken
	): Promise<{ success: boolean }> {
		return new Promise((resolve, reject) => {
			this._httpClient
				.delete<{ success: boolean }>(
					`/admin/users/${userId}`,
					{},
					{
						Authorization: `Bearer ${token}`,
					}
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject));
		});
	}

	async sendNotification(
		args: SendNotificationRequest,
		token: AuthToken
	): Promise<SendNotificationResponse> {
		return new Promise((resolve, reject) =>
			this._httpClient
				.post<SendNotificationResponse, SendNotificationRequest>(
					'/admin/push',
					args,
					{ Authorization: `Bearer ${token}` }
				)
				.then(resolve)
				.catch((error) => httpErrorHandler(error, reject))
		);
	}
}

export const getAdminService = () => {
	return new AdminService(new AxiosHttpClient(API_URL));
};

type SendNotificationRequest = {
	title: string;
	message: string;
	recipients?: string[];
	metadata?: string;
};

type SendNotificationResponse = {
	notification: Notification;
	tickets: {
		success: any[];
		errors: any[];
	};
};

type Notification = {
	id: string;
	title: string;
	message: string;
	metadata: string | null;
	createdAt: Date;
};

export type UserListItem = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	photoUrl: string | null;
	phone?: string;
	username?: string;
	country: string;
	gender: string;
	birthDate: string;
	team: {
		id: string;
		name: string;
		ownerId: string;
	};
};

type ListUsersResponse = UserListItem[];
