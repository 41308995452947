import React, { Fragment, useMemo } from 'react';
import {
	ActivityIndicator,
	FlatList,
	ListRenderItem,
	TouchableOpacity,
	View,
} from 'react-native';
import { ui } from '@newstart/ui';
import { GeneralRankingEntry, TeamRankingEntry } from '@newstart/engine';

import { StatsScreenItem } from '../types';
import { RankingListItem, Title } from '../../../components';
import { useAuth } from '@newstart/auth';
import i18n from 'i18n-js';

type OnTeamSelectParams = {
	teamId: string;
	position: number;
	name: string;
	points: number;
};

type StatsScreenTeamProps = {
	isFetching: boolean;
	teamSize: number;
	general: TeamRankingEntry[];
	byMyTeam: GeneralRankingEntry[];
	byTeamSize: TeamRankingEntry[];
	onTeamSelect: (params: OnTeamSelectParams) => void;
};

export const StatsScreenTeam: React.FunctionComponent<StatsScreenTeamProps> = ({
	isFetching,
	teamSize,
	general,
	byMyTeam,
	byTeamSize,
	onTeamSelect,
}) => {
	const { user, team } = useAuth();
	if (!user || !team) return null;

	const { data } = useMemo(() => {
		const items: StatsScreenItem[] = [];
		if (!isFetching) {
			items.push({
				format: 'title',
				key: 'my_team_ranking',
				data: { label: `${i18n.t('my-team-ranking')}` },
			});

			byMyTeam.map((entry, index) => {
				items.push({
					format: 'userRankingEntry',
					key: `byMyTeam.${entry.id}`,
					data: {
						id: entry.id,
						place: index,
						points: entry.points,
						isMe: entry.id === user.id,
						name: entry.username ?? `${entry.firstName} ${entry.lastName}`,
					},
				});
			});

			items.push({
				format: 'title',
				key: 'team_size_ranking',
				data: {
					label: i18n.t('size-ranking', { number: teamSize }),
				},
			});
			const teamPlaceBySize = byTeamSize.findIndex(
				(entry) => entry.id === team.id
			);
			byTeamSize.map((entry, index) => {
				if (index < 5) {
					items.push({
						format: 'rankingEntry',
						key: `byTeamSize.${entry.id}`,
						data: {
							id: entry.id,
							place: index,
							points: entry.points,
							isMe: teamPlaceBySize === index,
							name: entry.name,
						},
					});
				}
			});
			if (teamPlaceBySize > 4) {
				const teamEntry = byTeamSize[teamPlaceBySize];
				items.push({
					format: 'rankingEntry',
					key: `byTeamSize.${teamEntry.id}`,
					data: {
						id: teamEntry.id,
						place: teamPlaceBySize,
						points: teamEntry.points,
						isMe: true,
						name: teamEntry.name,
					},
				});
			}

			items.push({
				format: 'title',
				key: 'general_ranking',
				data: { label: i18n.t('general-ranking') },
			});

			const teamGeneralPlace = general.findIndex(
				(entry) => entry.id === team.id
			);
			general.map((entry, index) => {
				items.push({
					format: 'rankingEntry',
					key: `general.${entry.id}`,
					data: {
						id: entry.id,
						place: index,
						points: entry.points,
						isMe: teamGeneralPlace === index,
						name: entry.name,
					},
				});
			});
		}
		return {
			data: items,
		};
	}, [isFetching]);

	const handleTeamDetails = (item: StatsScreenItem) => {
		onTeamSelect({
			teamId: item.data.id,
			position: item.data.place,
			name: item.data.name,
			points: item.data.points,
		});
	};

	const renderItem: ListRenderItem<StatsScreenItem> = ({ item }) => {
		const { format } = item;

		if (format === 'title') return <Title>{item.data.label}</Title>;

		if (format === 'rankingEntry')
			return (
				<TouchableOpacity onPress={() => handleTeamDetails(item)}>
					<RankingListItem
						key={item.data.id}
						place={item.data.place}
						name={item.data.name}
						points={item.data.points}
						isMe={item.data.isMe}
					/>
				</TouchableOpacity>
			);

		if (format === 'userRankingEntry')
			return (
				<RankingListItem
					key={item.data.id}
					place={item.data.place}
					name={item.data.name}
					points={item.data.points}
					isMe={item.data.isMe}
				/>
			);

		return <Fragment />;
	};

	if (isFetching) {
		return (
			<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
				<ActivityIndicator size="large" color={ui.colors.light} />
			</View>
		);
	}

	return (
		<FlatList<StatsScreenItem>
			renderItem={renderItem}
			keyExtractor={(item) => item.key}
			style={{
				width: '100%',
				flex: 1,
			}}
			contentContainerStyle={{
				paddingBottom: 20,
			}}
			data={data}
		/>
	);
};
