import React from 'react';
import { ButtonProps, TouchableOpacity } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import i18n from 'i18n-js';
import { BaseContainer, StatusBar, ui } from '@newstart/ui';

import { OnboardingStackParamList } from '../../navigation';
import {
	StyledButton,
	StyledDescription,
	StyledTitle,
	StyledWhiteButton,
} from './styles';
import { useAuth } from '@newstart/auth';

type OnboardingScreenProps = NativeStackScreenProps<
	OnboardingStackParamList,
	'Onboarding_Walkthrough_Screen'
>;

export const OnboardingScreen: React.FunctionComponent<
	OnboardingScreenProps
> = ({ navigation }) => {
	const { signOut } = useAuth();

	return (
		<BaseContainer
			backgroundColor={['#30A5DA', '#0D00B1']}
			style={{
				paddingBottom: ui.bottomSpace,
			}}
			safeArea
		>
			<StatusBar style="inverted" />
			<BigBtn
				title={i18n.t('join-to-existing-team')}
				description={i18n.t(
					'if-you-were-invited-by-a-friend-just-enter-your-team-code-to-join'
				)}
				onPress={() => {
					navigation.navigate('Onboarding_Join_Team_Modal');
				}}
			/>
			<BigBtn
				title={i18n.t('create-my-own-team')}
				description={i18n.t(
					'create-your-team-and-invite-6-more-friends-to-join'
				)}
				onPress={() => {
					navigation.navigate('Onboarding_Create_Team_Modal');
				}}
			/>
			<TouchableOpacity onPress={signOut}>
				<StyledWhiteButton>{i18n.t('sign-out')}</StyledWhiteButton>
			</TouchableOpacity>
		</BaseContainer>
	);
};

type BigBtnProps = ButtonProps & {
	title: string;
	description: string;
};
const BigBtn: React.FunctionComponent<BigBtnProps> = ({
	title,
	description,
	...props
}) => {
	return (
		<StyledButton {...props}>
			<StyledTitle>{title}</StyledTitle>
			<StyledDescription>{description}</StyledDescription>
		</StyledButton>
	);
};
