import { ui } from '@newstart/ui';
import styled from 'styled-components/native';

export const StyledButton = styled.TouchableOpacity`
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.1);
	margin: 5px 10px;
	border-radius: 16px;
`;

export const StyledTitle = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 28px;
	color: #ffffff;
	letter-spacing: -0.77px;
	text-align: center;
`;

export const StyledDescription = styled.Text`
	font-family: ${ui.fonts.Inter.Regular};
	font-size: 16px;
	color: #ffffff;
	letter-spacing: -0.31px;
	text-align: center;
	line-height: 24px;
`;

export const StyledWhiteButton = styled.Text`
	color: #ffffff;
	text-align: center;
	font-size: 16px;
	padding: 5px 0;
`;
