import React, { useCallback, useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import { Ionicons, AntDesign } from '@expo/vector-icons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';

import { BaseModalContainer, ui } from '@newstart/ui';
import { ModalHeading } from '../../components';

import { HabitsStackParamList } from '../../navigation';
import {
	StyledIntroDetails,
	StyledIntroDetailLabel,
	StyledIntroDetailValue,
} from './styles';
import { useEngine } from '@newstart/engine';
import i18n from 'i18n-js';

type HabitIntroModalProps = NativeStackScreenProps<
	HabitsStackParamList,
	'Habits_Intro_Modal'
>;
export const HabitIntroModal: React.FunctionComponent<HabitIntroModalProps> = ({
	navigation,
	route,
}) => {
	const { params } = route;
	const { habitId } = params;
	const { habits, checkHabit } = useEngine();

	const habit = useMemo(
		() => habits.find((hab) => hab.id === habitId),
		[habitId]
	);
	const isExerciseHabit = useMemo(
		() => habits.findIndex((hab) => hab.id === habitId) === 0,
		[habitId]
	);

	if (!habit) return null;

	const handleClose = useCallback(async () => {
		if (isExerciseHabit) {
			navigation.replace('Habits_Exercise_Input_Modal', {
				habitId,
			});
		} else {
			await checkHabit(habitId);
			navigation.goBack();
		}
	}, [habitId]);

	return (
		<BaseModalContainer
			backgroundColor={[habit.category.colors[0], '#0D00B1']}
			start={{ x: 0.6, y: 0.3 }}
			end={{ x: 0.4, y: 1 }}
			style={{
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'space-between',
				paddingBottom: ui.bottomSpace + 30,
			}}
			onClose={handleClose}
		>
			<ModalHeading
				icon={habit.icon}
				title={habit.name}
				subtitle={habit.description}
			/>

			<TouchableOpacity
				style={{ opacity: 0.6 }}
				onPress={() => {
					Linking.openURL(habit.videoUrl);
				}}
			>
				<Ionicons
					name="play-circle-outline"
					size={140}
					color={ui.colors.dark}
				/>
			</TouchableOpacity>

			<StyledIntroDetails>
				<StyledIntroDetailLabel>{i18n.t('duration')}</StyledIntroDetailLabel>
				<StyledIntroDetailValue>
					{habit.endAt - habit.startAt} {i18n.t('days', { count: 2 })}
				</StyledIntroDetailValue>
			</StyledIntroDetails>

			<TouchableOpacity onPress={handleClose}>
				<AntDesign name="checkcircle" size={74} color={ui.colors.light} />
			</TouchableOpacity>
		</BaseModalContainer>
	);
};
