import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Text } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAuth } from '@newstart/auth';
import {
	useEngine,
	getNotificationService,
	Notification,
} from '@newstart/engine';
import { BaseModalContainer, StatusBar, ui, useMessage } from '@newstart/ui';

import { ProfileStackParamList } from '../../navigation';
import { humanDate } from '../../helpers';
import {
	StyledBody,
	StyledHeader,
	StyledIconContainer,
	StyledTitle,
	StyledBodyText,
	StyledFooter,
	StyledFooterText,
	StyledFooterBoldText,
} from './styles';
import { Ionicons } from '@expo/vector-icons';

type NotificationDetailModalProps = NativeStackScreenProps<
	ProfileStackParamList,
	'Profile_Notification_Detail_Modal'
>;

const notificationService = getNotificationService();

export const NotificationDetailModal: React.FunctionComponent<
	NotificationDetailModalProps
> = ({ navigation, route }) => {
	const { params } = route;
	const [loading, setLoading] = useState(true);
	const [notificationData, setNotificationData] = useState<Notification>();
	const { token } = useAuth();
	const { showError } = useMessage();
	const { markNotificationAsRead } = useEngine();

	useEffect(() => {
		if (!token) return;

		(async function getNotificationData() {
			try {
				setNotificationData(
					await notificationService.getById(params.notificationId, token)
				);
			} catch (error) {
				showError(error);
				navigation.goBack();
			} finally {
				setLoading(false);
			}
		})();
	}, [params.notificationId, token, showError]);

	useEffect(() => {
		(async function markThisNotificationAsReaded() {
			try {
				await markNotificationAsRead(params.notificationId);
			} catch (error) {
				showError(error);
			}
		})();
	}, [showError]);

	if (loading && !notificationData)
		return (
			<BaseModalContainer
				onClose={() => navigation.goBack()}
				style={{ justifyContent: 'space-between' }}
			>
				<StyledHeader>
					<StyledIconContainer>
						<Ionicons
							name="md-notifications"
							size={24}
							color={ui.colors.dark}
						/>
					</StyledIconContainer>
				</StyledHeader>
				<StyledBody>
					<ActivityIndicator color={ui.colors.dark} />
				</StyledBody>
				<StyledFooter>
					<StyledFooterText>...</StyledFooterText>
				</StyledFooter>
			</BaseModalContainer>
		);

	return (
		<BaseModalContainer
			onClose={() => navigation.goBack()}
			style={{ justifyContent: 'space-between' }}
		>
			<StatusBar style="auto" />
			<StyledHeader>
				<StyledIconContainer>
					<Ionicons name="md-notifications" size={24} color={ui.colors.dark} />
				</StyledIconContainer>
				<StyledTitle>{notificationData?.title}</StyledTitle>
			</StyledHeader>
			<StyledBody>
				<StyledBodyText>{notificationData?.message}</StyledBodyText>
			</StyledBody>
			<StyledFooter>
				<StyledFooterBoldText>NewStart 80 staff</StyledFooterBoldText>
				<StyledFooterText>
					{notificationData?.createdAt &&
						humanDate(notificationData?.createdAt)}
				</StyledFooterText>
			</StyledFooter>
		</BaseModalContainer>
	);
};
