import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import styled from 'styled-components/native';
import { ui } from '../styles';

type LoadingProps = {
	dark?: boolean;
};

export const Loading: React.FunctionComponent<LoadingProps> = ({ dark }) => {
	return (
		<StyledLoading>
			<ActivityIndicator
				size="small"
				color={dark ? ui.colors.light : ui.colors.dark}
			/>
		</StyledLoading>
	);
};

const StyledLoading = styled.View`
	flex: 1;
	padding: 20px;
	justify-content: center;
	align-items: center;
`;
