import React from "react"
import { StyleSheet, TouchableOpacity, Animated, View } from "react-native"
import { ui } from "@newstart/ui"
import { specs, SPACING } from "./styles"
import { LinearGradient } from "expo-linear-gradient"
import { Ionicons } from "@expo/vector-icons"
import { A } from "@expo/html-elements"

const { ITEM_WIDTH, ITEM_HEIGHT, RADIUS } = specs

type VideosCarouselItemProps = {
  index: number
  label: string
  videoUrl: string
  thumb: number
  color: string
  scale: Animated.AnimatedInterpolation
  translateX: Animated.AnimatedInterpolation
}

export const VideosCarouselItem: React.FunctionComponent<
  VideosCarouselItemProps
> = ({ index, label, videoUrl, thumb, color, scale, translateX }) => (
  <TouchableOpacity
    key={`videos.item.${index}`}
    style={styles.itemContainer}
    activeOpacity={0.8}
  >
    <A href={videoUrl} target="_blank">
      <Animated.View style={styles.card}>
        <LinearGradient
          colors={["transparent", color]}
          start={{ x: 0.2, y: -0.2 }}
          end={{ x: 0, y: 0.6 }}
          style={styles.cardShadow}
        />

        <Ionicons
          style={styles.play}
          name="play-circle-outline"
          size={68}
          color={ui.colors.dark}
        />
        <Animated.Text
          style={[
            styles.title,
            {
              transform: [{ translateX }],
            },
          ]}
        >
          {label}
        </Animated.Text>

        <Animated.Image
          source={thumb}
          style={[styles.image, { transform: [{ scale }] }]}
        />
      </Animated.View>
    </A>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  itemContainer: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    margin: SPACING,
  },
  play: {
    position: "absolute",
    top: "15%",
    left: "50%",
    marginLeft: -34,
    zIndex: 2,
  },
  title: {
    fontFamily: ui.fonts.Inter.Bold,
    fontSize: 18,
    color: ui.colors.dark,
    width: ITEM_WIDTH * 0.8,
    // textTransform: 'uppercase',
    position: "absolute",
    bottom: SPACING + 10,
    left: SPACING,
    zIndex: 2,
  },
  numberContainer: {
    position: "absolute",
    top: SPACING,
    right: SPACING,
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: "rgba(255,255,255,0.4)",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    overflow: "hidden",
    borderRadius: RADIUS,
  },
  cardShadow: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  image: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
  },
})
