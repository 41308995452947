import React, { useCallback } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import i18n from "i18n-js"
import {
  BaseModalContainer,
  useMessage,
  Form,
  Input,
  StyledError,
  BlackButton,
  ui,
} from "@newstart/ui"

import { AdminStackParamList } from "../../navigation"
import { getAdminService } from "../../services"
import { useAuth } from "@newstart/auth"

const adminService = getAdminService()

type Props = NativeStackScreenProps<
  AdminStackParamList,
  "Admin_Send_Notification_Modal"
>

export const SendNotificationModal: React.FunctionComponent<Props> = ({
  navigation,
}) => {
  const { token } = useAuth()

  if (!token) return null

  const { showError, showSuccess } = useMessage()

  const onSubmit = useCallback(
    async (values) => {
      try {
        const users = await adminService.listUsers(token)
        await adminService.sendNotification(
          {
            title: values.title,
            message: values.message,
            recipients: users.map((user) => user.id),
          },
          token
        )
        showSuccess("The notification was sent")
        navigation.goBack()
      } catch (error) {
        showError(error)
      }
    },
    [token]
  )

  return (
    <BaseModalContainer
      onClose={() => navigation.goBack()}
      title="Send Push Notifications"
      titleColor={ui.colors.dark}
    >
      <Form>
        <Formik
          initialValues={{
            title: "",
            message: "",
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
            message: Yup.string().max(256, "Must be less than 256 characters"),
          })}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isSubmitting,
          }) => (
            <>
              <Input
                label="Title"
                value={values.title}
                onChangeText={handleChange("title")}
                onBlur={handleBlur("title")}
                autoCapitalize="none"
                required
                error={<ErrorMessage component={StyledError} name="title" />}
              />

              <Input
                label="Message"
                value={values.message}
                onChangeText={handleChange("message")}
                onBlur={handleBlur("message")}
                autoCapitalize="none"
                multiline={true}
                numberOfLines={4}
                required
                error={<ErrorMessage component={StyledError} name="message" />}
              />

              <BlackButton
                onPress={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Send for all users
              </BlackButton>
            </>
          )}
        </Formik>
      </Form>
    </BaseModalContainer>
  )
}
