import React from 'react';
import { BaseButton, BaseButtonProps } from './BaseButton';
import { ui } from '../styles';

type WhiteButtonProps = Omit<
	BaseButtonProps,
	'backgroundColor' | 'textSize' | 'textColor'
>;
export const WhiteButton: React.FunctionComponent<WhiteButtonProps> = ({
	...props
}) => {
	return (
		<BaseButton
			backgroundColor={ui.colors.light}
			textColor={ui.colors.dark}
			{...props}
		/>
	);
};
