import { Avatar } from '@newstart/ui';
import React from 'react';
import {
	StyledMemberBubbleItem,
	StyledMemberBubbleName,
	StyledMemberBubblePoints,
} from './styles';

type MemberBubbleProps = {
	name: string;
	points: string;
	photoUrl: string | null;
};
export const MemberBubble: React.FunctionComponent<MemberBubbleProps> = ({
	name,
	points,
	photoUrl,
}) => (
	<StyledMemberBubbleItem>
		<Avatar photoUrl={photoUrl} size={70} />
		<StyledMemberBubbleName>{name}</StyledMemberBubbleName>
		<StyledMemberBubblePoints>{points}</StyledMemberBubblePoints>
	</StyledMemberBubbleItem>
);
