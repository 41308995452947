import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
const { colors, fonts, maxWidth } = ui;

export const StyledEmptyContainer = styled.View`
	width: 100%;
	max-width: ${maxWidth}px;
	flex: 1;
	position: relative;
`;

export const StyledBubbleInner = styled.View`
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	padding: 60px 60px 80px 60px;
	z-index: 1;
`;

export const StyledBubble = styled.View`
	background: rgba(255, 255, 255, 0.09);
	border: 1px solid rgba(255, 255, 255, 0.24);
	width: 620px;
	height: 620px;
	border-radius: 310px;
	position: absolute;
	margin-left: -310px;
	bottom: -80px;
	left: 50%;
	z-index: 0;
`;

export const StyledInfo = styled.Text`
	font-family: ${ui.fonts.Inter.Bold};
	font-size: 25px;
	color: ${colors.light};
	letter-spacing: -0.69px;
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
`;

export const StyledInfoDate = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 20px;
	color: rgba(255, 255, 255, 0.5);
	letter-spacing: -0.38px;
	text-align: left;
	line-height: 19px;
	margin-top: 5px;
`;
