import React, { useState, useCallback } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useAuth, User } from '@newstart/auth';

import { StyledAvatarImage } from './styles';

type AvatarUploadProps = {
	user: User;
};

export const AvatarUpload: React.FunctionComponent<AvatarUploadProps> = ({
	user,
}) => {
	const { updateUser } = useAuth();
	const [image, setImage] = useState(user.photoUrl);

	const pickImage = useCallback(async () => {
		if (Platform.OS !== 'web') {
			const { status } =
				await ImagePicker.requestMediaLibraryPermissionsAsync();
			if (status !== 'granted') {
				alert(
					'Désolé, nous avons besoin de l’autorisation pour accéder à l`appareil photo pour que ça marche !'
				);
			}
		}

		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [1, 1],
			quality: 0.2,
			base64: true,
		});

		if (result.cancelled) {
			return;
		}
		setImage(result.uri);

		let base64Img = `data:image/jpg;base64,${result.base64}`;
		let data = {
			file: base64Img,
			upload_preset: 'newstart_upload',
		};

		fetch('https://api.cloudinary.com/v1_1/da3oc3f3m/upload', {
			body: JSON.stringify(data),
			headers: {
				'content-type': 'application/json',
			},
			method: 'POST',
		})
			.then(async (r) => {
				let data = await r.json();

				setImage(data.url);

				await updateUser({
					photoUrl: data.url,
				});
			})
			.catch((err) => console.log(err));
	}, [setImage, updateUser]);

	return (
		<TouchableOpacity
			onPress={pickImage}
			style={{ width: 114, height: 114 }}
			activeOpacity={0.8}
		>
			{image && <StyledAvatarImage source={{ uri: image }} />}
		</TouchableOpacity>
	);
};
