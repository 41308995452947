import styled from 'styled-components/native';
import { ui } from '@newstart/ui';
const { colors, fonts, maxWidth } = ui;

export const Title = styled.Text`
	font-family: ${fonts.Inter.Bold};
	font-size: 28px;
	color: #857d98;
	letter-spacing: -0.77px;
	padding: 8px 0;
	text-align: center;
`;

export const Subtitle = styled.Text`
	font-family: ${fonts.Inter.Regular};
	font-size: 22px;
	color: #857d98;
	letter-spacing: -0.61px;
	text-align: center;
`;

export const ButtonsContainer = styled.View`
	width: 100%;
	max-width: ${maxWidth}px;
	padding: 0 20px;
	margin-top: auto;
	margin-bottom: 15px;
`;
