import React, { useState } from 'react';
import { TextInputProps, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import {
	StyledFormInputContainer,
	StyledLabel,
	StyledInputText,
} from './styles';
import { ui } from '../styles';

export type InputProps = Omit<
	TextInputProps,
	'autoCapitalize' | 'autoCompleteType' | 'secureTextEntry' | 'textContentType'
> & {
	label?: string;
	required?: boolean;
	error?: React.ReactNode;
	dark?: boolean;
};

export const PasswordInput: React.FunctionComponent<InputProps> = ({
	label,
	required,
	error,
	dark,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(true);
	return (
		<StyledFormInputContainer>
			{label && (
				<StyledLabel dark={dark}>
					{label}
					{required && '*'}
				</StyledLabel>
			)}
			<TouchableOpacity
				onPress={() => setShowPassword(!showPassword)}
				style={{
					position: 'absolute',
					top: 38,
					right: 16,
					zIndex: 8,
				}}
			>
				<Ionicons
					name={showPassword ? 'eye' : 'eye-off'}
					size={24}
					color={dark ? 'rgba(255,255,255,0.5' : ui.colors.secondary}
				/>
			</TouchableOpacity>
			<StyledInputText
				dark={dark}
				autoCapitalize="none"
				autoCompleteType="password"
				secureTextEntry={showPassword}
				textContentType="newPassword"
				{...props}
			/>
			{error}
		</StyledFormInputContainer>
	);
};
