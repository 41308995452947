import React, { useEffect, useMemo, useRef } from "react"
import { FlatList, TouchableOpacity, View } from "react-native"
import { useEngine } from "@newstart/engine"
import moment from "moment-timezone"
import * as Localization from "expo-localization"
import { StyledDay, StyledDayText, StyledDayWeek } from "./styles"
moment.locale(Localization.locale)

type DaySliderSelectorProps = {}

export const DaySliderSelector: React.FunctionComponent<
  DaySliderSelectorProps
> = () => {
  const { startDate, selectedIndex, setSelectedIndex, currentIndex } =
    useEngine()

  const ref = useRef<FlatList>(null)

  const data = useMemo(
    () =>
      [...Array(80).keys()].map((_, i) => ({
        key: `index.${i}`,
        date: moment(startDate).add(i, "days"),
        isToday: i === currentIndex,
        isFuture: i > currentIndex,
        isPast: i < currentIndex,
      })),
    [startDate]
  )

  useEffect(() => {
    ref.current?.scrollToIndex({
      index: selectedIndex,
      animated: true,
      viewPosition: 0,
    })
  }, [selectedIndex])

  return (
    <FlatList
      ref={ref}
      style={{ flexGrow: 0 }}
      initialScrollIndex={selectedIndex}
      data={data}
      keyExtractor={(item) => item.key}
      contentContainerStyle={{ paddingLeft: 20 }}
      showsHorizontalScrollIndicator={false}
      horizontal
      getItemLayout={(_, index) => ({
        length: 60,
        offset: (60 + 10) * index,
        index,
      })}
      renderItem={({ item, index: findex }) => {
        return (
          <TouchableOpacity
            onPress={() => {
              setSelectedIndex(findex)
            }}
            disabled={item.isFuture}
          >
            <DayButton
              isSelected={findex === selectedIndex}
              weekname={item.date.format("ddd")}
              day={item.date.format("DD")}
              isToday={item.isToday}
              isFuture={item.isFuture}
              isPast={item.isPast}
            />
          </TouchableOpacity>
        )
      }}
    />
  )
}

type DayButtonProps = {
  isSelected: boolean
  weekname: string
  day: string
  isToday: boolean
  isFuture: boolean
  isPast: boolean
}

const DayButton = ({
  isSelected,
  weekname,
  day,
  isToday,
  isFuture,
  isPast,
}: DayButtonProps) => {
  return (
    <StyledDay
      isPast={isPast}
      isToday={isToday}
      isFuture={isFuture}
      isSelected={isSelected}
    >
      <StyledDayWeek
        isSelected={isSelected}
        isFuture={isFuture}
        isToday={isToday}
        numberOfLines={1}
      >
        {weekname}
      </StyledDayWeek>
      <StyledDayText
        isSelected={isSelected}
        isToday={isToday}
        isFuture={isFuture}
      >
        {day}
      </StyledDayText>
    </StyledDay>
  )
}
