import React from 'react';
import { ViewProps } from 'react-native';
import { SectionHeader } from '../../heading';

import { StyledSection } from './styles';

type Props = ViewProps & {
	title?: string;
	subtitle?: string;
	dark?: boolean;
};

export const ScreenSection: React.FunctionComponent<Props> = ({
	children,
	title,
	subtitle,
	dark,
	...props
}) => {
	return (
		<StyledSection {...props}>
			{title && (
				<SectionHeader
					title={title}
					subtitle={subtitle}
					theme={dark ? 'light' : 'dark'}
				/>
			)}
			{children}
		</StyledSection>
	);
};
