import { useEffect, useRef, useState } from "react"
import * as Device from "expo-device"
import * as Notifications from "expo-notifications"
import { Subscription } from "expo-modules-core"
import {
  CompositeNavigationProp,
  useNavigation,
} from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs"
import { useAuth } from "@newstart/auth"
import { AppTabsParamList } from "@newstart/navigation"
import { ProfileStackParamList } from "@newstart/profile"
import { Platform } from "react-native"

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

type ProfileScreenNavigationProp = CompositeNavigationProp<
  BottomTabNavigationProp<AppTabsParamList, "Tab_Profile">,
  NativeStackNavigationProp<ProfileStackParamList>
>

export const usePushNotifications = () => {
  const { user, updateUser } = useAuth()
  const navigation = useNavigation<ProfileScreenNavigationProp>()

  const [expoPushToken, setExpoPushToken] = useState<string>()
  const [notification, setNotification] = useState<Notifications.Notification>()
  const notificationListener = useRef<Subscription>()
  const responseListener = useRef<Subscription>()

  useEffect(() => {
    getPushToken().then((pushToken) => {
      setExpoPushToken(pushToken)
    })

    notificationListener.current =
      Notifications.addNotificationReceivedListener(setNotification)

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        setNotification(response.notification)
      })

    return () => {
      notificationListener.current &&
        Notifications.removeNotificationSubscription(
          notificationListener.current
        )
      responseListener.current &&
        Notifications.removeNotificationSubscription(responseListener.current)
    }
  }, [])

  useEffect(() => {
    if (notification?.request.content.data?.notificationId) {
      navigation.navigate("Tab_Profile", {
        screen: "Profile_Notification_Detail_Modal",
        params: {
          notificationId: String(
            notification.request.content.data.notificationId
          ),
        },
        initial: false,
      })
    }
  }, [notification])

  useEffect(() => {
    if (!user?.expoPushToken && expoPushToken) {
      ;(async () => {
        await updateUser({ expoPushToken })
      })()
    }
  }, [user, expoPushToken])
}

const getPushToken = async () => {
  let token

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "Newstart Notification",
      importance: Notifications.AndroidImportance.DEFAULT,
    })
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!")
      return
    }
    token = (await Notifications.getExpoPushTokenAsync()).data
  } else {
    alert("Must use physical device for Push Notifications")
  }

  return token
}
