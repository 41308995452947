import { PropsWithChildren } from "react"
import { HabitBubbleProps } from "./interfaces"
import { StyledContainerFilled, StyledContainerTransparent } from "./styles"

export const StyledContainer: React.FunctionComponent<
  PropsWithChildren<Pick<HabitBubbleProps, "filled" | "size" | "colors">>
> = ({ filled, size, colors, children }) => {
  if (filled) {
    return (
      <StyledContainerFilled size={size} colors={colors}>
        {children}
      </StyledContainerFilled>
    )
  }
  return (
    <StyledContainerTransparent size={size}>
      {children}
    </StyledContainerTransparent>
  )
}
