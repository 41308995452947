import { getCurrentIndex } from '../helpers';
import {
	Habit,
	UserChallengeAchievement,
	UserHabitAchievement,
	UserHabitDailyPerformance,
	UserNotification,
	TeamMemberDailyPerformance,
	Challenge,
} from '../services';

type State = {
	kind: 'prefetch' | 'fetching' | 'ready' | 'error' | 'empty';
	startDate: string;
	currentIndex: number;
	selectedIndex: number;
	habits: Habit[];
	challenges: Challenge[];
	userPerformance: UserHabitDailyPerformance[];
	userAchievements: Array<UserHabitAchievement | UserChallengeAchievement>;
	teamMembersAchievements: Array<UserHabitAchievement>;
	teamMembersPerformance: Array<TeamMemberDailyPerformance>;
	userTotalPoints: number;
	teamTotalPoints: number;
	notifications: UserNotification[];
	error?: Error;
};

type FetchInitalDataAction = {
	type: 'FETCH_INITIAL_DATA';
	payload: {
		startDate: string;
		habits: Habit[];
		challenges: Challenge[];
		notifications: UserNotification[];
	};
};

type FetchDataAction = {
	type: 'FETCH_DATA';
};

type FetchSucessAction = {
	type: 'FETCH_SUCCESS';
	payload: {
		userPerformance: UserHabitDailyPerformance[];
		userAchievements: Array<UserHabitAchievement | UserChallengeAchievement>;
		teamMembersAchievements: Array<UserHabitAchievement>;
		teamMembersPerformance: Array<TeamMemberDailyPerformance>;
		userTotalPoints: number;
		teamTotalPoints: number;
	};
};
type FetchFailureAction = {
	type: 'FETCH_FAILURE';
	payload: {
		error: Error;
	};
};
type RecalculateCurrentIndexAction = {
	type: 'RECALCULATE_CURRENT_INDEX';
};
type SetIndexAction = {
	type: 'SET_INDEX';
	payload: {
		newIndex: number;
	};
};
type UserAchievementUpdateAction = {
	type: 'USER_ACHIEVEMENT_UPDATE';
};
type RequestRefetchDataAction = {
	type: 'REQUEST_REFETCH_DATA';
};
type NotificationReadedAction = {
	type: 'NOTIFICATION_READED';
	payload: {
		notifications: UserNotification[];
	};
};

type Action =
	| FetchInitalDataAction
	| FetchDataAction
	| FetchSucessAction
	| FetchFailureAction
	| RecalculateCurrentIndexAction
	| SetIndexAction
	| UserAchievementUpdateAction
	| NotificationReadedAction
	| RequestRefetchDataAction;

export const reducer = (state: State, action: Action): State => {
	if (action.type === 'RECALCULATE_CURRENT_INDEX') {
		const currentIndex = getCurrentIndex(state.startDate);
		return {
			...state,
			currentIndex,
			selectedIndex: currentIndex,
		};
	}

	if (action.type === 'FETCH_INITIAL_DATA') {
		const currentIndex = getCurrentIndex(action.payload.startDate);

		return {
			...state,
			startDate: action.payload.startDate,
			habits: action.payload.habits,
			notifications: action.payload.notifications,
			challenges: action.payload.challenges,
			currentIndex: currentIndex,
			selectedIndex: currentIndex,
		};
	}

	if (action.type === 'FETCH_DATA') {
		return {
			...state,
			kind: 'fetching',
		};
	}

	if (action.type === 'FETCH_SUCCESS') {
		return {
			...state,
			kind: 'ready',
			userPerformance: action.payload.userPerformance,
			userAchievements: action.payload.userAchievements,
			teamMembersAchievements: action.payload.teamMembersAchievements,
			teamMembersPerformance: action.payload.teamMembersPerformance,
			userTotalPoints: action.payload.userTotalPoints,
			teamTotalPoints: action.payload.teamTotalPoints,
		};
	}

	if (action.type === 'FETCH_FAILURE') {
		return {
			...state,
			kind: 'error',
			error: action.payload.error,
		};
	}

	if (action.type === 'SET_INDEX') {
		return {
			...state,
			selectedIndex: action.payload.newIndex,
		};
	}

	if (action.type === 'USER_ACHIEVEMENT_UPDATE') {
		return {
			...state,
			kind: 'fetching',
		};
	}

	if (action.type === 'NOTIFICATION_READED') {
		return {
			...state,
			notifications: action.payload.notifications,
		};
	}

	if (action.type === 'REQUEST_REFETCH_DATA') {
		return {
			...state,
			kind: 'fetching',
		};
	}

	return state;
};
